import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import yaml from "js-yaml";
import { add } from "date-fns";

import { requestSignature } from "../../../api/chain/utils";
import {
  cancelListing,
  createListing,
  executeSell,
  findAll,
  findListedToken,
  findUserTokens,
} from "../../../api/restapi";
import { useAccount } from "../../../contexts/AccountContext";
import { useElements } from "../../../hooks/useElements";
import { usePets } from "../../../hooks/usePets";
import { CARD_LEVELS, ELEMENTS } from "../../../constants/constants";
import {
  approveElementMarket,
  approveStarchiMarket,
} from "../../../api/chain/erc721";
import {
  elementSellExecuted,
  starchiSellExecuted,
} from "../../../api/chain/market";
import { getPetElementsWithDetails } from "../../../api/pets";
import { blockchainAddresses } from "../../../config";

export enum ContractTypes {
  Pets = 0,
  Elements = 1,
}

type StageProps = "Egg" | "Child" | "Teen" | "Adult";

interface ElementTokenProps {
  _id?: string;
  name: string;
  energy: number;
  level: number;
  damageType: number;
  effect: number;
  powerRatio: number;
  tokenId: number;
  typeId: number;
  updatedAt?: string;
  createdAt?: string;
}

export interface TokenProps {
  _id?: string;
  tokenId: number;
  name: string;
  image: string | null;
  sellerAddress: string | null | undefined;
  typeId: number;
  contractType: ContractTypes;
  contractAddress: string;
  energy: number;
  price?: number;
  level?: string;
  damageType?: number;
  effect?: number;
  powerRatio?: number;
  breedId?: number;
  stage?: StageProps;
  hp?: number;
  luckiness?: number;
  speed?: number;
  elements?: ElementTokenProps[];
}

type StarchiTypeProps = "HP" | "Energy" | "Luckiness" | "Speed";

export type StarchiStatsProps = {
  [type in StarchiTypeProps]: {
    color: string;
    icon: JSX.Element;
    min: number;
    max: number;
  };
};

type ElementTypeProps = "Energy" | "PowerRatio";

export type ElementStatsProps = {
  [type in ElementTypeProps]: {
    color: string;
    icon: JSX.Element;
    min: number;
    max: number;
  };
};

export interface StarchiFilterProps {
  stages: string[];
  petTypes: number[];
  breeds: number[];
  starchiName: string;
  stats: StarchiStatsProps;
}

export interface ElementFilterProps {
  elementNames: string[];
  cardLevels: number[];
  damageTypes: number[];
  effects: number[];
  stats: ElementStatsProps;
}

export interface SortProps {
  sortBy: number;
  sortingDirection: 0 | 1;
}

interface StoreContextData {
  myListedTokens: TokenProps[];
  myAvailableTokensToSell: TokenProps[];
  listedTokens: TokenProps[];
  isLoadingListedTokens: boolean;
  cancelTokenSell(token: TokenProps): Promise<void>;
  buyToken(token: TokenProps): Promise<void>;
  findToken(
    tokenId: number,
    contractType: ContractTypes,
    isMyToken?: boolean,
  ): TokenProps | undefined;
  listToken(token: TokenProps, price: number): Promise<void>;
  starchiFilters: StarchiFilterProps;
  setStarchiFilters(filters: StarchiFilterProps): void;
  elementFilters: ElementFilterProps;
  setElementFilters(filters: ElementFilterProps): void;
  setElementsSort(sort: SortProps): void;
  elementsSort: SortProps;
  setStarchiesSort(sort: SortProps): void;
  starchiesSort: SortProps;
  selectedTab: 0 | 1;
  setSelectedTab(tabIndex: 0 | 1): void;
  starchiesCurrentPage: number;
  elementsCurrentPage: number;
  setStarchiesCurrentPage(page: number): void;
  setElementsCurrentPage(page: number): void;
  starchiesTotalCount: number;
  elementsTotalCount: number;
  PAGE_LIMIT: number;
}

const StoreContext = createContext<StoreContextData>({} as StoreContextData);

const StoreProvider: React.FC = ({ children }) => {
  const PAGE_LIMIT = 12;

  const [myListedTokens, setMyListedTokens] = useState<TokenProps[]>([]);
  const [myAvailableTokensToSell, setMyAvailableTokensToSell] = useState<
    TokenProps[]
  >([]);
  const [listedTokens, setListedTokens] = useState<TokenProps[]>([]);
  const [selectedTab, setSelectedTab] = useState<0 | 1>(0);

  const [starchiFilters, setStarchiFilters] = useState<StarchiFilterProps>({
    stages: [],
    petTypes: [],
    breeds: [],
    starchiName: "",
    stats: {} as StarchiStatsProps,
  });

  const [elementFilters, setElementFilters] = useState<ElementFilterProps>({
    elementNames: [],
    cardLevels: [],
    damageTypes: [],
    effects: [],
    stats: {} as ElementStatsProps,
  });
  const [elementsSort, setElementsSort] = useState<SortProps>({
    sortBy: 0,
    sortingDirection: 0,
  });
  const [starchiesSort, setStarchiesSort] = useState<SortProps>({
    sortBy: 0,
    sortingDirection: 0,
  });
  const [starchiesTotalCount, setStarchiesTotalCount] = useState(0);
  const [elementsTotalCount, setElementsTotalCount] = useState(0);
  const [starchiesCurrentPage, setStarchiesCurrentPage] = useState(1);
  const [elementsCurrentPage, setElementsCurrentPage] = useState(1);

  const [isLoadingListedTokens, setIsLoadingListedTokens] =
    useState<boolean>(true);

  const { account, provider } = useAccount();
  const { fetchMyElements } = useElements();
  const { fetchMyPets } = usePets();

  const formatTokens = useCallback((tokens: TokenProps[]) => {
    return tokens.map((token) => {
      let image = null;

      if (token.contractType === ContractTypes.Pets) {
        if (token.stage === "Egg") {
          image = `/img/eggs/${token.typeId}.png`;
        } else if (token.stage) {
          const PET_PHASES = {
            Egg: 0,
            Child: 1,
            Teen: 2,
            Adult: 3,
          };

          image = `/img/pets/${token.typeId}-${PET_PHASES[token?.stage]}.png`;
        }
      } else if (token.contractType === ContractTypes.Elements) {
        image = ELEMENTS[token.typeId]?.image || null;
      }

      return { ...token, image };
    });
  }, []);

  const fetchMyTokensToSell = useCallback(async () => {
    if (!account?.address || !provider) {
      return;
    }

    const [myPets, myElements] = await Promise.all([
      fetchMyPets(),
      fetchMyElements(),
    ]);

    let tokenList: TokenProps[] = [];

    for (let i = 0; i < myElements.length; i++) {
      const element = myElements[i];

      const token = await findListedToken({
        tokenId: element.elementId,
        contractAddress: blockchainAddresses.pets,
        contractType: ContractTypes.Elements,
      });

      const alreadyListed = typeof token === "object";

      if (!alreadyListed && element.typeId !== 0) {
        tokenList = [
          ...tokenList,
          {
            tokenId: element.elementId,
            sellerAddress: account.address,
            price: undefined,
            name: ELEMENTS[element.typeId]?.name || "",
            image: null,
            typeId: element.typeId,
            contractAddress: blockchainAddresses.pets,
            contractType: ContractTypes.Elements,
            level: CARD_LEVELS[element.level - 1],
            energy: element.energy,
            effect: element.effect,
            damageType: element.damageType,
            powerRatio: element.powerRatio.toNumber(),
          },
        ];
      }
    }
    for (let i = 0; i < myPets.length; i++) {
      const pet = myPets[i];

      const token = await findListedToken({
        tokenId: pet.petId,
        contractAddress: blockchainAddresses.pets,
        contractType: ContractTypes.Pets,
      });

      const alreadyListed = typeof token === "object";

      if (!alreadyListed && pet.typeId !== 0) {
        const elements = await getPetElementsWithDetails({
          petId: pet.petId,
          provider,
        });

        const formattedElementTokens = [];

        for (let j = 0; j < elements.length; j++) {
          const element = elements[j];

          formattedElementTokens.push({
            energy: element.energy,
            level: element.level,
            name: ELEMENTS[element.typeId]?.name ?? "",
            powerRatio: element.powerRatio.toNumber(),
            damageType: element.damageType,
            effect: element.effect,
            tokenId: element.elementId,
            typeId: element.typeId,
          });
        }

        tokenList = [
          ...tokenList,
          {
            tokenId: pet.petId,
            sellerAddress: account?.address,
            price: undefined,
            name: pet.name,
            image: null,
            typeId: pet.typeId,
            contractAddress: blockchainAddresses.pets,
            contractType: ContractTypes.Pets,
            breedId: pet.breedId,
            stage: pet.stage,
            hp: pet.attributes.hungerPoints,
            energy: pet.attributes.energyPoints,
            luckiness: pet.attributes.hygienePoints,
            speed: pet.attributes.exercisePoints,
            elements: formattedElementTokens,
          },
        ];
      }
    }

    setMyAvailableTokensToSell(formatTokens(tokenList));
  }, [fetchMyPets, fetchMyElements, account.address, provider, formatTokens]);

  const fetchMyListedTokens = useCallback(async () => {
    if (!account.address) {
      return;
    }

    let tokens: TokenProps[] = [];

    const myListedStarchies = await findUserTokens(
      account.address,
      ContractTypes.Pets,
    );
    const myListedElements = await findUserTokens(
      account.address,
      ContractTypes.Elements,
    );

    if (myListedStarchies.length) {
      tokens = [...myListedStarchies];
    }
    if (myListedElements.length) {
      tokens = [...tokens, ...myListedElements];
    }

    setMyListedTokens(formatTokens(tokens));
  }, [account.address, formatTokens]);

  const loadMarketData = useCallback(async () => {
    setIsLoadingListedTokens(true);

    // Reset filters
    setStarchiFilters({
      stages: [],
      petTypes: [],
      breeds: [],
      starchiName: "",
      stats: {} as StarchiStatsProps,
    });

    setElementFilters({
      elementNames: [],
      cardLevels: [],
      damageTypes: [],
      effects: [],
      stats: {} as ElementStatsProps,
    });
    setElementsSort({
      sortBy: 0,
      sortingDirection: 0,
    });
    setStarchiesSort({
      sortBy: 0,
      sortingDirection: 0,
    });

    const [startchiesPayload, elementsPayload] = await Promise.all([
      findAll({
        limit: PAGE_LIMIT,
        contractType: ContractTypes.Pets,
        contractAddress: blockchainAddresses.pets,
      }),
      findAll({
        limit: PAGE_LIMIT,
        contractType: ContractTypes.Elements,
        contractAddress: blockchainAddresses.pets,
      }),
      fetchMyTokensToSell(),
      fetchMyListedTokens(),
    ]);

    const listedStarchies = startchiesPayload.data;
    const listedElements = elementsPayload.data;

    setStarchiesTotalCount(startchiesPayload.totalCount);
    setElementsTotalCount(elementsPayload.totalCount);

    const listedItens = [...listedStarchies, ...listedElements];

    setListedTokens(formatTokens(listedItens));

    setIsLoadingListedTokens(false);
  }, [fetchMyTokensToSell, fetchMyListedTokens, formatTokens]);

  useEffect(() => {
    loadMarketData();
  }, [loadMarketData]);

  useEffect(() => {
    const sortAndFilterTokens = async () => {
      setIsLoadingListedTokens(true);
      let payload;
      // Starchies
      if (selectedTab === ContractTypes.Pets) {
        setStarchiesCurrentPage(1);

        payload = await findAll({
          limit: PAGE_LIMIT,
          page: 1,
          contractAddress: blockchainAddresses.pets,
          contractType: ContractTypes.Pets,
          name: starchiFilters.starchiName,
          typeIds: starchiFilters.petTypes,
          breeds: starchiFilters.breeds,
          stages: starchiFilters.stages,
          minHp: starchiFilters.stats?.HP?.min,
          maxHp: starchiFilters.stats?.HP?.max,
          minEnergy: starchiFilters.stats?.Energy?.min,
          maxEnergy: starchiFilters.stats?.Energy?.max,
          minLuckiness: starchiFilters.stats?.Luckiness?.min,
          maxLuckiness: starchiFilters.stats?.Luckiness?.max,
          minSpeed: starchiFilters.stats?.Speed?.min,
          maxSpeed: starchiFilters.stats?.Speed?.max,
          sortBy: starchiesSort.sortBy,
          sortingDirection: starchiesSort.sortingDirection,
        });

        setStarchiesTotalCount(payload.totalCount);
      } else if (selectedTab === ContractTypes.Elements) {
        setElementsCurrentPage(1);

        payload = await findAll({
          limit: PAGE_LIMIT,
          page: 1,
          contractAddress: blockchainAddresses.pets,
          contractType: ContractTypes.Elements,
          elementNames: elementFilters.elementNames,
          cardLevels: elementFilters.cardLevels,
          damageTypes: elementFilters.damageTypes,
          effects: elementFilters.effects,
          minEnergy: elementFilters.stats?.Energy?.min,
          maxEnergy: elementFilters.stats?.Energy?.max,
          minPowerRatio: elementFilters.stats?.PowerRatio?.min,
          maxPowerRatio: elementFilters.stats?.PowerRatio?.max,
          sortBy: elementsSort.sortBy,
          sortingDirection: elementsSort.sortingDirection,
        });
        setElementsTotalCount(payload.totalCount);
      }

      const tokens = formatTokens(payload.data);

      setListedTokens(tokens);
      setIsLoadingListedTokens(false);
    };

    sortAndFilterTokens();
  }, [
    starchiFilters,
    starchiesSort,
    elementFilters,
    elementsSort,
    formatTokens,
    selectedTab,
  ]);

  useEffect(() => {
    const paginate = async () => {
      setIsLoadingListedTokens(true);
      let payload;
      // Starchies
      if (selectedTab === ContractTypes.Pets) {
        payload = await findAll({
          limit: PAGE_LIMIT,
          page: starchiesCurrentPage,
          contractAddress: blockchainAddresses.pets,
          contractType: ContractTypes.Pets,
          name: starchiFilters.starchiName,
          typeIds: starchiFilters.petTypes,
          breeds: starchiFilters.breeds,
          stages: starchiFilters.stages,
          minHp: starchiFilters.stats?.HP?.min,
          maxHp: starchiFilters.stats?.HP?.max,
          minEnergy: starchiFilters.stats?.Energy?.min,
          maxEnergy: starchiFilters.stats?.Energy?.max,
          minLuckiness: starchiFilters.stats?.Luckiness?.min,
          maxLuckiness: starchiFilters.stats?.Luckiness?.max,
          minSpeed: starchiFilters.stats?.Speed?.min,
          maxSpeed: starchiFilters.stats?.Speed?.max,
          sortBy: starchiesSort.sortBy,
          sortingDirection: starchiesSort.sortingDirection,
        });

        setStarchiesTotalCount(payload.totalCount);
      } else if (selectedTab === ContractTypes.Elements) {
        payload = await findAll({
          limit: PAGE_LIMIT,
          page: elementsCurrentPage,
          contractAddress: blockchainAddresses.pets,
          contractType: ContractTypes.Elements,
          elementNames: elementFilters.elementNames,
          cardLevels: elementFilters.cardLevels,
          damageTypes: elementFilters.damageTypes,
          effects: elementFilters.effects,
          minEnergy: elementFilters.stats?.Energy?.min,
          maxEnergy: elementFilters.stats?.Energy?.max,
          minPowerRatio: elementFilters.stats?.PowerRatio?.min,
          maxPowerRatio: elementFilters.stats?.PowerRatio?.max,
          sortBy: elementsSort.sortBy,
          sortingDirection: elementsSort.sortingDirection,
        });
        setElementsTotalCount(payload.totalCount);
      }

      const tokens = formatTokens(payload.data);

      setListedTokens(tokens);
      setIsLoadingListedTokens(false);
    };

    paginate();
  }, [
    starchiFilters,
    starchiesSort,
    elementFilters,
    elementsSort,
    formatTokens,
    selectedTab,
    starchiesCurrentPage,
    elementsCurrentPage,
  ]);

  const listToken = useCallback(
    async (token: TokenProps, price: number) => {
      const { tokenId, contractType } = token;

      if (!provider || !account.address) {
        throw new Error("Unable to list NFT");
      }

      if (!tokenId) {
        throw new Error("Invalid NFT");
      }

      if (price === 0) {
        throw new Error("Invalid selling price");
      }

      try {
        if (token.contractType === ContractTypes.Pets) {
          await approveStarchiMarket(token.tokenId, provider);
        } else if (token.contractType === ContractTypes.Elements) {
          await approveElementMarket(token.tokenId, provider);
        }
      } catch (err: any) {
        throw new Error(err);
      }

      // Fixed expiration date to 3 months
      const expirationDate = add(new Date(), {
        months: 3,
      });

      // Sign yaml message
      const signature = await requestSignature(
        yaml.dump(
          {
            sellerAddress: account.address,
            tokenId,
            price,
            expirationDate,
          },
          {
            flowLevel: 3,
            styles: {
              "!!null": "lowercase",
            },
          },
        ),
        provider,
      );

      await createListing({
        tokenId,
        price,
        signature,
        contractAddress: blockchainAddresses.pets,
        contractType,
        expirationTimestamp: expirationDate.getTime(),
        address: account.address,
      });

      loadMarketData();
    },
    [account.address, provider, loadMarketData],
  );

  const cancelTokenSell = useCallback(
    async (token: TokenProps) => {
      const { _id: listingId, tokenId, contractAddress, contractType } = token;

      if (!provider || !account.address) {
        throw new Error("Unable to remove NFT");
      }

      const signature = await requestSignature(
        yaml.dump(
          {
            listingId,
            sellerAddress: account.address,
            tokenId,
          },
          {
            flowLevel: 3,
            styles: {
              "!!null": "lowercase",
            },
          },
        ),
        provider,
      );

      console.log("Canceling listed NFT...", token);
      await cancelListing({
        signature,
        listingId,
        tokenId,
        contractAddress,
        contractType,
      });

      loadMarketData();
    },
    [account.address, provider, loadMarketData],
  );

  const watchStarchiSellExecuted = useCallback(
    (_provider: any, tokenId: number) =>
      starchiSellExecuted(_provider, tokenId),
    [],
  );

  const watchElementSellExecuted = useCallback(
    (_provider: any, tokenId: number) =>
      elementSellExecuted(_provider, tokenId),
    [],
  );

  const buyToken = useCallback(
    async (token: TokenProps) => {
      const {
        price,
        tokenId,
        _id: listingId,
        contractAddress,
        contractType,
      } = token;

      if (!provider || !account.address) {
        throw new Error("Unable to buy NFT");
      }

      let signature;

      try {
        signature = await requestSignature(
          yaml.dump(
            {
              buyerAddress: account.address,
              tokenId,
              price,
            },
            {
              flowLevel: 3,
              styles: {
                "!!null": "lowercase",
              },
            },
          ),
          provider,
        );
      } catch (err: any) {
        throw new Error(err);
      }

      console.log("Buying listed NFT...", token);
      await executeSell({
        listingId,
        tokenId,
        signature,
        buyerAddress: account.address,
        contractAddress,
        contractType,
      });

      if (token.contractType === ContractTypes.Pets) {
        await watchStarchiSellExecuted(provider, tokenId);
      } else if (token.contractType === ContractTypes.Elements) {
        await watchElementSellExecuted(provider, tokenId);
      }

      loadMarketData();
    },
    [
      account.address,
      watchElementSellExecuted,
      watchStarchiSellExecuted,
      provider,
      loadMarketData,
    ],
  );

  const findToken = useCallback(
    (tokenId: number, contractType: ContractTypes, isMyToken: boolean) => {
      let foundToken: TokenProps | undefined;

      if (isMyToken && !isLoadingListedTokens) {
        foundToken = myAvailableTokensToSell.find(
          (token) =>
            token.tokenId === tokenId && token.contractType === contractType,
        );
      } else {
        foundToken = listedTokens.find(
          (token) =>
            token.tokenId === tokenId && token.contractType === contractType,
        );
      }

      return foundToken;
    },
    [isLoadingListedTokens, listedTokens, myAvailableTokensToSell],
  );

  const contextValue = useMemo(
    () => ({
      myListedTokens,
      myAvailableTokensToSell,
      listedTokens,
      isLoadingListedTokens,
      cancelTokenSell,
      findToken,
      buyToken,
      listToken,
      starchiFilters,
      setStarchiFilters,
      elementFilters,
      setElementFilters,
      setElementsSort,
      elementsSort,
      setStarchiesSort,
      starchiesSort,

      selectedTab,
      setSelectedTab,
      starchiesTotalCount,
      elementsTotalCount,
      starchiesCurrentPage,
      setStarchiesCurrentPage,
      elementsCurrentPage,
      setElementsCurrentPage,
      PAGE_LIMIT,
    }),
    [
      myListedTokens,
      myAvailableTokensToSell,
      listedTokens,
      isLoadingListedTokens,
      cancelTokenSell,
      buyToken,
      findToken,
      listToken,
      starchiFilters,
      setStarchiFilters,
      elementFilters,
      setElementFilters,
      setElementsSort,
      elementsSort,
      setStarchiesSort,
      starchiesSort,
      selectedTab,
      setSelectedTab,
      setStarchiesCurrentPage,
      starchiesCurrentPage,
      setElementsCurrentPage,
      elementsCurrentPage,
      starchiesTotalCount,
      elementsTotalCount,
      PAGE_LIMIT,
    ],
  );

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = (): StoreContextData => {
  const context = useContext(StoreContext);

  return context;
};

export default StoreProvider;
