import { useCallback, useState } from "react";

import LinearProgress from "@material-ui/core/LinearProgress";

import Button from "../../../../components/Button";
import WalletModal from "../../../../components/WalletModal";
import { useAccount } from "../../../../contexts/AccountContext";
import { usePets } from "../../../../hooks/usePets";
import { useElements } from "../../../../hooks/useElements";
import MintEggModal from "../MintEggModal";
import MintElementModal from "../MintElementModal";
import SellNFTModal from "../SellNFTModal";
import CancelListingModal from "../CancelListingModal";
import IconButton from "../../../../components/IconButton";
import FilterIcon from "../../../../img/filter-icon.svg";
import FilterBox from "../FilterBox";
import CardList from "../Cardlist";

import { SHORT_ADDRESS } from "../../../../constants/constants";
import { toastError, toastSuccess } from "../../../../utils/errorHandlers";
import { useContractsParams } from "../../../../contexts/ContractsParamsContext";
import { blockchainAddresses, whitelistAccounts } from "../../../../config";
import NotInWhitelist from "../../../../components/NotInWhitelist";

import "../styles.scss";
import "./styles.scss";
import "../../../Staking/components/StakingIncome.scss";
import { useStore } from "../../contexts/StoreContext";

const ItemList: React.FC = () => {
  const { account } = useAccount();
  const [walletModal, showWalletModal] = useState(false);

  const [isMintEggModal, showMintEggModal] = useState(false);
  const [isMintElementModal, showMintElementModal] = useState(false);
  const [sellNFTModal, showSellNFTModal] = useState(false);
  const [cancelListingModal, showCancelListingModal] = useState(false);

  const [isApprovingToken, setIsApprovingToken] = useState(false);
  const [isMintingEgg, setIsMintingEgg] = useState(false);
  const [isMintingElement, setIsMintingElement] = useState(false);

  const [isFilterShown, showFilter] = useState(true);

  const {
    starchiesTotalCount,
    elementsTotalCount,
    selectedTab,
    isLoadingListedTokens,
  } = useStore();

  const { mintEggPrice, mintElementPrice, approvedTokens, approveToken } =
    useContractsParams();

  const { mintEgg } = usePets();
  const { mintElement } = useElements();

  const onFilter = useCallback(() => {
    showFilter((prevState) => !prevState);
  }, []);
  const onConnect = useCallback(() => {
    showWalletModal(true);
  }, []);

  const onMintEgg = useCallback(
    async (petName: any) => {
      setIsMintingEgg(true);
      try {
        await mintEgg(petName);
        toastSuccess(
          `${petName}'s Egg was minted successfully. View it in your Crate page!`,
        );
        showMintEggModal(false);
      } catch (error) {
        toastError(error);
      }
      setIsMintingEgg(false);
    },
    [mintEgg],
  );

  const onMintElement = useCallback(
    async (_elementType: any) => {
      setIsMintingElement(true);
      try {
        await mintElement(_elementType);
        toastSuccess(
          `Element card was minted successfully. View it in your Crate page!`,
        );
        showMintElementModal(false);
      } catch (error) {
        console.log(error);
        toastError(error);
      }
      setIsMintingElement(false);
    },
    [mintElement],
  );

  const handleApproveClick = useCallback(async () => {
    setIsApprovingToken(true);
    try {
      await approveToken(
        blockchainAddresses.buidlToken,
        blockchainAddresses.pets,
      );
    } catch (error) {
      console.error(error);
      toastError(error);
    }
    setIsApprovingToken(false);
  }, [approveToken]);

  if (account.address && !whitelistAccounts.includes(account.address)) {
    return <NotInWhitelist address={account.address} />;
  }

  return (
    <div className="item-list-container">
      {!account || !account.address ? (
        <div className="staking-income-connect">
          <div className="staking-income-connect-text">
            Connect your wallet to see available starchis
          </div>
          <div className="staking-income-connect-button">
            <Button
              text="CONNECT WALLET"
              size="md"
              onClick={() => {
                onConnect();
              }}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="item-list-header">
            <div className="item-list-header-wrapped">
              <span className="item-list-heading">
                {selectedTab === 0
                  ? `${starchiesTotalCount} Starchies`
                  : `${elementsTotalCount} Elements`}{" "}
                found.
              </span>
              <div className="item-list-filter">
                <IconButton
                  containerStyles={{
                    marginLeft: "1rem",
                  }}
                  icon={FilterIcon}
                  onClick={onFilter}
                />
              </div>
            </div>
            {account && account.address && (
              <button
                type="button"
                className="staking-income-address"
                onClick={() => {
                  showWalletModal(true);
                }}
              >
                {SHORT_ADDRESS(account.address)}
              </button>
            )}
          </div>
          {account && account.address && (
            <div className="item-list-header market-buttons">
              {approvedTokens?.petIsApprovedToBuidlTokens ? (
                <>
                  <div className="item-list-header-buttons-left">
                    <Button
                      text={
                        mintEggPrice
                          ? `Mint Egg (${mintEggPrice} BUIDL)`
                          : "loading..."
                      }
                      size="md"
                      onClick={() => {
                        showMintEggModal(true);
                      }}
                    />
                    <br />
                    <div className="ml-2">
                      <Button
                        text={
                          mintElementPrice
                            ? `Mint Element (${mintElementPrice} BUIDL)`
                            : "loading..."
                        }
                        size="md"
                        onClick={() => {
                          showMintElementModal(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="item-list-header-buttons-right">
                    <Button
                      text="My Listings"
                      size="md"
                      onClick={() => {
                        showCancelListingModal(true);
                      }}
                    />
                    <br />
                    <div className="ml-2">
                      <Button
                        text="Sell"
                        size="md"
                        onClick={() => {
                          showSellNFTModal(true);
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Button
                    text={
                      isApprovingToken
                        ? "Approving..."
                        : "Approve BUIDL for Minting"
                    }
                    size="md"
                    disabled={isApprovingToken}
                    onClick={handleApproveClick}
                  />
                  <div className="d-flex">
                    <Button
                      text="My Listings"
                      size="md"
                      onClick={() => {
                        showCancelListingModal(true);
                      }}
                    />
                    <Button
                      style={{ marginLeft: "0.6rem" }}
                      text="Sell"
                      size="md"
                      onClick={() => {
                        showSellNFTModal(true);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {isFilterShown && (
            <div className="item-list-filter-box">
              <FilterBox
                onClose={() => {
                  showFilter(false);
                }}
              />
            </div>
          )}
          <div className="item-list-box">
            {isLoadingListedTokens && <LinearProgress />}
            {account && account.address && <CardList />}
          </div>
        </>
      )}

      <MintEggModal
        modalOpen={isMintEggModal}
        isMintingEgg={isMintingEgg}
        onConfirm={(_petName: any) => {
          onMintEgg(_petName);
        }}
        onClose={() => {
          showMintEggModal(false);
        }}
      />
      <MintElementModal
        modalOpen={isMintElementModal}
        isMintingElement={isMintingElement}
        onConfirm={(_elementType: any) => {
          onMintElement(_elementType);
        }}
        onClose={() => {
          showMintElementModal(false);
        }}
      />
      <CancelListingModal
        modalOpen={cancelListingModal}
        onClose={() => {
          showCancelListingModal(false);
        }}
      />
      <SellNFTModal
        modalOpen={sellNFTModal}
        onClose={() => {
          showSellNFTModal(false);
        }}
      />
      <WalletModal
        modalOpen={walletModal}
        onClose={() => {
          showWalletModal(false);
        }}
      />
    </div>
  );
};

export default ItemList;
