import { useCallback, useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

import { Unity, useUnityContext } from "react-unity-webgl";

import "./styles.scss";

import "../../Staking/components/StakingIncome.scss";
import CrateCardV2 from "./CrateCardV2";
import SettingsModalBox from "./SettingsModal";
import NameChangeModal from "./NameChangeModal";
import NameChangeSuccessModal from "./NameChangeSuccessModal";
import ElementsListModal from "./ElementsListModal";
import Button from "../../../components/Button";
import WalletModal from "../../../components/WalletModal";
import NotInWhitelist from "../../../components/NotInWhitelist";

import { useAccount } from "../../../contexts/AccountContext";

import SellModal from "./SellModal";
import SellSuccessModal from "./SellSuccessModal";

import { useCrate } from "../contexts/CrateContext";
import { usePets } from "../../../hooks/usePets";
import { useHistory } from "react-router";
import { whitelistAccounts, unityTamagochiContextPath } from "../../../config";
import { toastError } from "../../../utils/errorHandlers";

const MyCrateBox = () => {
  const { account, disconnectWallet } = useAccount();
  const [walletModal, showWalletModal] = useState(false);

  const {
    unityProvider,
    isLoaded,
    addEventListener,
    sendMessage,
    removeEventListener,
    unload,
    loadingProgression,
  } = useUnityContext({
    productName: "Starchi Tamagotchi",
    companyName: "Starchi",
    loaderUrl: `${unityTamagochiContextPath}/tamagotchi.loader.js`,
    dataUrl: `${unityTamagochiContextPath}/tamagotchi.data`,
    frameworkUrl: `${unityTamagochiContextPath}/tamagotchi.framework.js`,
    codeUrl: `${unityTamagochiContextPath}/tamagotchi.wasm`,
    streamingAssetsUrl: `${unityTamagochiContextPath}/StreamingAssets`,
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  const [showMyCardsModal, setShowMyCardsModal] = useState(false);
  const [isShownModal, showSettingsModal] = useState(false);
  const [isNameChangeModal, showNameChangeModal] = useState(false);
  const [isNameChangeSuccessModal, showNameChangeSuccessModal] =
    useState(false);
  const [isSellModal, showSellModal] = useState(false);
  const [isSellSuccessModal, showSellSuccessModal] = useState(false);

  const { myPets, isLoadingMyPets } = useCrate();
  const { fetchMyPets } = usePets();

  const [selectedPet, setSelectedPet] = useState(myPets && myPets[0]);
  const [sellPrice, setSellPrice] = useState(0);
  const [currentPetAction, setCurrentPetAction] = useState({
    actionId: "",
    petId: undefined,
  });

  const history = useHistory();

  const accountChanged = useCallback(() => {
    toastError("Account changed, disconnecting...");
    disconnectWallet();
  }, [disconnectWallet, toastError]);

  useEffect(() => {
    if (!account || !account.address) {
      return;
    }

    window?.ethereum?.on("accountsChanged", accountChanged);

    return () =>
      window?.ethereum?.removeListener("accountsChanged", accountChanged);
  }, [accountChanged, account]);

  useEffect(() => {
    let unblock = history.block(() => false);

    if (isLoaded) {
      setTimeout(() => {
        unblock = history.block(() => true);
      }, 3000);
    }

    if (!myPets.length) {
      unblock = history.block(() => true);
    }

    return () => {
      unblock();
    };
  }, [history, isLoaded, myPets]);

  // Manages Unity Loading
  useEffect(() => {
    addEventListener("canvas", (canvas) => {
      canvas.setAttribute("role", "unityCanvas");
    });

    return () => {
      removeEventListener("canvas");
    };
  }, [addEventListener, removeEventListener]);

  const handleUpdateActionState = useCallback(
    (actionId, pet, error = false, message = "success") => {
      const data = {
        actioId: actionId, // type action id
        error,
        message,
        pet,
      };

      sendMessage("TamagotchiInterface", "ActionStage", JSON.stringify(data));
    },
    [sendMessage],
  );

  const handleOnActionStart = useCallback((actionId, petId) => {
    console.log("handleOnActionStart", { actionId, petId: Number(petId) });

    setCurrentPetAction({ actionId, petId: Number(petId) });
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    return () => {
      unload();
    };
  }, [unload, isLoaded]);

  // Manages tamagotchi connection
  useEffect(() => {
    if (!isLoaded && myPets.length <= 0) return () => undefined;
    const loadPets = async () => {
      console.info("connecting to tamagotchi interface...");

      addEventListener("OnActionStart", handleOnActionStart);

      const playerPets = await fetchMyPets();

      console.log("playerPets", playerPets);

      if (playerPets) {
        sendMessage(
          "TamagotchiInterface",
          "TamagotchiStatus",
          JSON.stringify(playerPets),
        );
      }
    };

    if (isLoaded) {
      loadPets();
    }

    return () => {
      removeEventListener("OnActionStart");
    };
  }, [
    addEventListener,
    removeEventListener,
    sendMessage,
    isLoaded,
    fetchMyPets,
    handleOnActionStart,
  ]);

  const onConnect = useCallback(() => {
    showWalletModal(true);
  }, []);

  const onChangeName = useCallback(() => {
    console.log("Change Name", selectedPet);
    showSettingsModal(false);
    showNameChangeModal(true);
  }, [selectedPet]);

  const onChangeNameConfirm = useCallback((newName) => {
    console.log("Change Name Confirm", newName);
    showNameChangeModal(false);
    showNameChangeSuccessModal(true);
  }, []);

  const onSettings = useCallback((pet) => {
    setSelectedPet(pet);
    showSettingsModal(true);
  }, []);

  const onSell = useCallback(
    (_price) => {
      console.log("Sell", selectedPet);
      setSellPrice(_price);
      showSettingsModal(false);
      showSellModal(true);
    },
    [selectedPet],
  );

  const onSellConfirm = useCallback(() => {
    console.log("On Sell Confirm", sellPrice);
    showSellModal(false);
    showSellSuccessModal(true);
  }, [sellPrice]);

  if (account.address && !whitelistAccounts.includes(account.address)) {
    return <NotInWhitelist address={account.address} />;
  }

  return (
    <>
      <div className="my-crate-container">
        <div className="my-crate-header">
          <div className="d-flex">
            <h1 className="my-crate-heading">My Crate</h1>
            {account?.address && (
              <Button
                text="Elements Card List"
                size="sm"
                onClick={() => setShowMyCardsModal(true)}
              />
            )}
          </div>
          <Button
            text="Classic crate"
            size="md"
            onClick={() => history.push("/crate-classic")}
          />
        </div>

        {!account || !account.address ? (
          <div className="staking-income-connect">
            <div className="staking-income-connect-text">
              Connect your wallet to see your pets
            </div>
            <div className="staking-income-connect-button">
              <Button
                text="CONNECT WALLET"
                size="md"
                onClick={() => {
                  onConnect();
                }}
              />
            </div>
          </div>
        ) : isLoadingMyPets ? (
          <div className="my-crate-loader">
            <LinearProgress />
          </div>
        ) : !myPets.length ? (
          <div className="crate-box-call-action">
            <div className="crate-box-call-action-text">
              You don't have any Starchis, mint your first egg here:
            </div>
            <div className="crate-box-call-action-button">
              <Button
                text="GO TO STORE"
                size="md"
                onClick={() => history.push("store")}
              />
            </div>
          </div>
        ) : (
          account &&
          account.address && (
            <div className="wrapper">
              <div className="unity-container">
                {!isLoaded && (
                  <div className="loading-overlay">
                    <div className="progress-bar">
                      <div
                        className="progress-bar-fill"
                        style={{ width: `${loadingProgression * 100}%` }}
                      />
                    </div>
                  </div>
                )}

                <Unity className="unity-canvas" unityProvider={unityProvider} />
              </div>
            </div>
          )
        )}
      </div>

      <div className="my-crate-list">
        {myPets.map((pet) => (
          <CrateCardV2
            key={pet.petId}
            currentPetAction={currentPetAction}
            handleUpdateActionState={handleUpdateActionState}
            pet={pet}
            onSettings={() => onSettings(pet)}
          />
        ))}
      </div>

      <SettingsModalBox
        modalOpen={isShownModal}
        item={selectedPet}
        onClose={() => {
          showSettingsModal(false);
        }}
        onChangeName={onChangeName}
        onSell={onSell}
      />

      <NameChangeModal
        modalOpen={isNameChangeModal}
        item={selectedPet}
        onConfirm={(newName) => {
          onChangeNameConfirm(newName);
        }}
        onClose={() => {
          showNameChangeModal(false);
        }}
      />
      <NameChangeSuccessModal
        modalOpen={isNameChangeSuccessModal}
        item={selectedPet}
        onConfirm={() => {
          showNameChangeSuccessModal(false);
        }}
        onClose={() => {
          showNameChangeSuccessModal(false);
        }}
      />
      <SellModal
        modalOpen={isSellModal}
        price={sellPrice}
        onConfirm={() => {
          onSellConfirm();
        }}
        onClose={() => {
          showSellModal(false);
        }}
      />
      <SellSuccessModal
        modalOpen={isSellSuccessModal}
        onConfirm={() => {
          window.open("/store", "_self");
        }}
        onClose={() => {
          showSellSuccessModal(false);
        }}
      />
      <WalletModal
        modalOpen={walletModal}
        onClose={() => {
          showWalletModal(false);
        }}
      />
      <ElementsListModal
        option="AVAILABLE_ELEMENTS"
        myPets={myPets}
        modalOpen={showMyCardsModal}
        onClose={() => {
          setShowMyCardsModal(false);
        }}
      />
    </>
  );
};

export default MyCrateBox;
