import {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
  useMemo,
} from "react";
import { ethers } from "ethers";

import { approve, getApproval } from "../api/chain/erc20";
import {
  approvePetsToElementNFTs,
  getPetsToElementNFTsApproval,
} from "../api/chain/erc721";

import {
  getMintEggPrice,
  getMintElementPrice,
  getDetachElementPrice,
  getChangeNamePrice,
} from "../api/chain/pets";

import {
  percentageFactor,
  energyMinPercentage,
  hungerMinPercentage,
  hygieneMinPercentage,
  exerciseMinPercentage,
  hostingArenaPrice,
} from "../api/chain/battle";
import { blockchainAddresses } from "../config";
import { useAccount } from "./AccountContext";

import {
  hungerTimeoutSeconds,
  hygieneTimeoutSeconds,
  exerciseTimeoutSeconds,
  energySleepTimeoutSeconds,
  energySleepLockedSeconds,
} from "../api/chain/nurturing";

interface ApprovedTokensProps {
  petIsApprovedToBuidlTokens?: boolean;
  nurturingIsApprovedToElxrTokens?: boolean;
  battleIsApprovedToBuidlTokens?: boolean;
  petIsApprovedToElementNFTs?: boolean;
  elementMarketIsApprovedToBuidlTokens?: boolean;
  starchiMarketIsApprovedToBuidlTokens?: boolean;
}

type StringOrUndefined = string | undefined;
type NumberOrUndefined = number | undefined;

interface ContractsParamsContextData {
  mintEggPrice: StringOrUndefined;
  mintElementPrice: StringOrUndefined;
  hostingArenaCost: StringOrUndefined;
  detachElementPrice: NumberOrUndefined;
  changeNamePrice: NumberOrUndefined;
  approveToken(token: string, spender: string): Promise<void>;
  approvePetsToNFTs(): Promise<void>;
  approvedTokens: ApprovedTokensProps;
  percentageDenominator: NumberOrUndefined;
  minEnergyPercentage: NumberOrUndefined;
  minHungerPercentage: NumberOrUndefined;
  minHygienePercentage: NumberOrUndefined;
  minExercisePercentage: NumberOrUndefined;
  hungerSecondsTimeout: NumberOrUndefined;
  showerSecondsTimeout: NumberOrUndefined;
  exerciseSecondsTimeout: NumberOrUndefined;
  sleepSecondsTimeout: NumberOrUndefined;
  wakeSecondsTimeout: NumberOrUndefined;
}

const ContractsParamsContext = createContext<ContractsParamsContextData>(
  {} as ContractsParamsContextData,
);

const ContractsParamsProvider: React.FC = ({ children }) => {
  const { account, provider } = useAccount();

  const [approvedTokens, setApprovedTokens] = useState<ApprovedTokensProps>(
    {} as ApprovedTokensProps,
  );
  const [mintEggPrice, setMintEggPrice] =
    useState<StringOrUndefined>(undefined);
  const [mintElementPrice, setMintElementPrice] =
    useState<StringOrUndefined>(undefined);
  const [detachElementPrice, setDetachElementPrice] =
    useState<NumberOrUndefined>(undefined);
  const [changeNamePrice, setChangeNamePrice] =
    useState<NumberOrUndefined>(undefined);

  const [hostingArenaCost, setHostingArenaCost] =
    useState<StringOrUndefined>(undefined);

  const [percentageDenominator, setPercentageDenominator] =
    useState<NumberOrUndefined>(10000);
  const [minEnergyPercentage, setMinEnergyPercentage] =
    useState<NumberOrUndefined>(3000);
  const [minHungerPercentage, setMinHungerPercentage] =
    useState<NumberOrUndefined>(3000);
  const [minHygienePercentage, setMinHygienePercentage] =
    useState<NumberOrUndefined>(3000);
  const [minExercisePercentage, setMinExercisePercentage] =
    useState<NumberOrUndefined>(3000);

  const [hungerSecondsTimeout, setHungerSecondsTimeout] =
    useState<NumberOrUndefined>();
  const [showerSecondsTimeout, setShowerSecondsTimeout] =
    useState<NumberOrUndefined>();
  const [exerciseSecondsTimeout, setExerciseSecondsTimeout] =
    useState<NumberOrUndefined>();
  const [sleepSecondsTimeout, setSleepSecondsTimeout] =
    useState<NumberOrUndefined>();
  const [wakeSecondsTimeout, setWakeSecondsTimeout] =
    useState<NumberOrUndefined>();

  const loadMinAttRequired = useCallback(async () => {
    const [
      percentageDenominatorData,
      minEnergyPercentageData,
      minHungerPercentageData,
      minHygienePercentageData,
      minExercisePercentageData,
    ] = await Promise.all([
      percentageFactor(provider),
      energyMinPercentage(provider),
      hungerMinPercentage(provider),
      hygieneMinPercentage(provider),
      exerciseMinPercentage(provider),
    ]);

    setPercentageDenominator(percentageDenominatorData.toNumber());
    setMinEnergyPercentage(minEnergyPercentageData.toNumber());
    setMinHungerPercentage(minHungerPercentageData.toNumber());
    setMinHygienePercentage(minHygienePercentageData.toNumber());
    setMinExercisePercentage(minExercisePercentageData.toNumber());
  }, [provider]);

  const loadNurturingInfo = useCallback(async () => {
    const [
      hungerTimeoutInSeconds,
      showerTimeoutInSeconds,
      exerciseTimeoutInSeconds,
      sleepTimeoutInSeconds,
      wakeTimeoutInSeconds,
    ] = await Promise.all([
      hungerTimeoutSeconds(provider),
      hygieneTimeoutSeconds(provider),
      exerciseTimeoutSeconds(provider),
      energySleepTimeoutSeconds(provider),
      energySleepLockedSeconds(provider),
    ]);

    setHungerSecondsTimeout(hungerTimeoutInSeconds);
    setShowerSecondsTimeout(showerTimeoutInSeconds);
    setExerciseSecondsTimeout(exerciseTimeoutInSeconds);
    setSleepSecondsTimeout(sleepTimeoutInSeconds);
    setWakeSecondsTimeout(wakeTimeoutInSeconds);
  }, [provider]);

  const loadHostingArenaPrice = useCallback(async () => {
    const rawHostingArenaCost = await hostingArenaPrice(provider);

    const formattedHostingArenaCost =
      ethers.utils.formatEther(rawHostingArenaCost);

    setHostingArenaCost(formattedHostingArenaCost);
  }, [provider]);

  const loadMintPrices = useCallback(async () => {
    const [rawMintEggPrice, rawMintElementPrice] = await Promise.all([
      getMintEggPrice(provider),
      getMintElementPrice(provider),
    ]);

    const formattedMintEggPrice = ethers.utils.formatEther(rawMintEggPrice);
    const formattedMintElementPrice =
      ethers.utils.formatEther(rawMintElementPrice);

    setMintEggPrice(formattedMintEggPrice);
    setMintElementPrice(formattedMintElementPrice);
  }, [provider]);

  const loadDetachElementPrice = useCallback(async () => {
    let _detachElementPrice: ethers.BigNumber | string =
      await getDetachElementPrice(provider);

    _detachElementPrice = ethers.utils.formatEther(_detachElementPrice);

    setDetachElementPrice(Number(_detachElementPrice));
  }, [provider]);

  const loadChangeNamePrice = useCallback(async () => {
    let _changeNamePrice: ethers.BigNumber | string = await getChangeNamePrice(
      provider,
    );

    _changeNamePrice = ethers.utils.formatEther(_changeNamePrice);

    setChangeNamePrice(Number(_changeNamePrice));
  }, [provider]);

  const fetchApprovals = useCallback(async () => {
    if (!provider || !account.address) {
      setApprovedTokens({} as ApprovedTokensProps);
      return;
    }
    const {
      buidlToken,
      elxrToken,
      pets,
      nurturing,
      battle,
      elementMarket,
      starchiMarket,
    } = blockchainAddresses;
    const [
      petIsApprovedToBuidlTokens,
      nurturingIsApprovedToElxrTokens,
      battleIsApprovedToBuidlTokens,
      elementMarketIsApprovedToBuidlTokens,
      starchiMarketIsApprovedToBuidlTokens,
      petIsApprovedToElementNFTs,
    ] = await Promise.all([
      getApproval(provider, buidlToken, pets, account.address),
      getApproval(provider, elxrToken, nurturing, account.address),
      getApproval(provider, buidlToken, battle, account.address),
      getApproval(provider, buidlToken, elementMarket, account.address),
      getApproval(provider, buidlToken, starchiMarket, account.address),
      getPetsToElementNFTsApproval(provider, account.address),
    ]);

    setApprovedTokens({
      petIsApprovedToBuidlTokens,
      nurturingIsApprovedToElxrTokens,
      battleIsApprovedToBuidlTokens,
      elementMarketIsApprovedToBuidlTokens,
      starchiMarketIsApprovedToBuidlTokens,
      petIsApprovedToElementNFTs,
    });
  }, [provider, account.address]);

  const approveToken = useCallback(
    async (token: string, spender: string) => {
      if (!provider) {
        throw new Error("Account not recognized to approve");
      }
      await approve(token, spender, provider);
      await fetchApprovals();
    },
    [fetchApprovals, provider],
  );

  const approvePetsToNFTs = useCallback(async () => {
    if (!provider || !account?.address) {
      throw new Error("Account not recognized to approve");
    }
    await approvePetsToElementNFTs(provider, account.address);
    await fetchApprovals();
  }, [provider, account, fetchApprovals]);

  const loadParams = useCallback(async () => {
    await Promise.all([
      loadMinAttRequired(),
      loadNurturingInfo(),
      loadHostingArenaPrice(),
      loadMintPrices(),
      loadDetachElementPrice(),
      loadChangeNamePrice(),
      fetchApprovals(),
    ]);
  }, [
    loadMinAttRequired,
    loadNurturingInfo,
    loadHostingArenaPrice,
    loadMintPrices,
    loadDetachElementPrice,
    loadChangeNamePrice,
    fetchApprovals,
  ]);

  useEffect(() => {
    if (provider) {
      loadParams();
    }
  }, [provider, loadParams]);

  const memorizedValue = useMemo(
    () => ({
      mintEggPrice,
      mintElementPrice,
      detachElementPrice,
      changeNamePrice,
      hostingArenaCost,
      approveToken,
      approvePetsToNFTs,
      approvedTokens,
      percentageDenominator,
      minEnergyPercentage,
      minHungerPercentage,
      minHygienePercentage,
      minExercisePercentage,
      hungerSecondsTimeout,
      showerSecondsTimeout,
      exerciseSecondsTimeout,
      sleepSecondsTimeout,
      wakeSecondsTimeout,
    }),
    [
      mintEggPrice,
      mintElementPrice,
      detachElementPrice,
      changeNamePrice,
      hostingArenaCost,
      approveToken,
      approvePetsToNFTs,
      approvedTokens,
      percentageDenominator,
      minEnergyPercentage,
      minHungerPercentage,
      minHygienePercentage,
      minExercisePercentage,
      hungerSecondsTimeout,
      showerSecondsTimeout,
      exerciseSecondsTimeout,
      sleepSecondsTimeout,
      wakeSecondsTimeout,
    ],
  );

  return (
    <ContractsParamsContext.Provider value={memorizedValue}>
      {children}
    </ContractsParamsContext.Provider>
  );
};

export const useContractsParams = (): ContractsParamsContextData => {
  const context = useContext(ContractsParamsContext);

  return context;
};

export default ContractsParamsProvider;
