import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { useHistory } from "react-router";
import images from "../../../../images";
import Button from "../../../../components/Button";

import "../../styles.scss";

const ArenaHome: React.FC = () => {
  const history = useHistory();

  return (
    <div className="section-row">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow className="col-center">
          <div className="title-group">
            <div className="section-title text-center">
              Become a Starchi Collector
            </div>
            <div className="section-title text-center">
              Join Friendly Battles and Earn
            </div>
          </div>
        </MDBRow>
        <MDBRow className="col-center">
          <div className="btn-parent">
            <Button
              style={{ marginTop: "1rem" }}
              size="lg"
              text="GO TO ARENA"
              onClick={() => {
                history.push("/arena");
              }}
            />
          </div>
        </MDBRow>
        <MDBRow>
          <MDBCol md="5">
            <img alt="" src={images.BatLord} className="hero1" />
          </MDBCol>
          <MDBCol md="2" className="col-center">
            <img alt="" src={images.VS} className="vs" />
          </MDBCol>
          <MDBCol md="5">
            <img alt="" src={images.Sting} className="hero2" />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default ArenaHome;
