import { useState, useEffect } from "react";
import { Container, ChartLabel, StyledSvg } from "./styles";

const CHART_CIRCUMFERENCE = 658;

const DoughnutChart = ({ type, label, value, scale, percentageComplete }) => {
  const [strokeOffset, setStrokeOffset] = useState(CHART_CIRCUMFERENCE);

  useEffect(() => {
    const getStrokeOffset = () => {
      const offset = Math.round(
        (CHART_CIRCUMFERENCE / 100) * percentageComplete,
      );
      return CHART_CIRCUMFERENCE - offset;
    };
    setStrokeOffset(getStrokeOffset());
  }, [percentageComplete]);

  return (
    <Container>
      <ChartLabel>
        <div className={`chart-title ${type === "global" ? "big-label" : ""}`}>
          <span className="chart-label">{label}</span>
          <span className="chart-value">{value}</span>
        </div>
        {type !== "global" && (
          <img
            className="chart-logo"
            src={`/images/${type}-badge.png`}
            alt={type}
          />
        )}
      </ChartLabel>
      <StyledSvg
        strokeOffset={strokeOffset}
        width="260"
        height="260"
        xmlns="http://www.w3.org/2000/svg"
        scale={scale}
      >
        <g>
          <title>Layer 1</title>
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#08CE01" />
              <stop offset="18%" stopColor="#CEEF00" />
              <stop offset="35%" stopColor="#51F980" />
              <stop offset="50%" stopColor="#2D51ED" />
              <stop offset="67%" stopColor="#F100D9" />
              <stop offset="83%" stopColor="#9A24EC" />
              <stop offset="100%" stopColor="#9A24EC" />
            </linearGradient>
          </defs>

          <circle
            r="104.785485"
            cy="130"
            cx="130"
            strokeWidth="20"
            stroke="#5b5d8f"
            fill="none"
          />
          <circle
            className="circle"
            r="104.785485"
            cy="130"
            cx="130"
            strokeWidth="20"
            stroke="url('#gradient')"
            fill="none"
          />
        </g>
      </StyledSvg>
    </Container>
  );
};

export default DoughnutChart;
