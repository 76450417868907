import { TeamStatus } from "./team-stats.dto";

export enum BattleStatus {
  WaitingStart = 0,
  Initial = 1,
  Strategy = 2,
  PreBattle = 3,
  Battle = 4,
  PostBattle = 5,
  Finished = 6,
  Canceled = 7,
}

interface EffectStatus {
  effectId: number;
  power: number;
  targetTeamId?: number;
  to?: number;
}

interface AttackStatus {
  teamId: number; // 0 or 1
  from: number;
  element: number;
  to: number;
  toTeamId: number;
  damage?: number;
  damageImpact: number;
  rechargeAmount: number;
  effect?: EffectStatus;
}
export interface ArenaStatus {
  arenaId: number;
  teams: TeamStatus[];
  attacks: AttackStatus[];
  battleStatus: BattleStatus;
  winner: number; // 0 or 1, -1: progress
  lastEventTime: number;
}
