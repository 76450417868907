import {
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { requestSignature, recoverAddress } from "../api/chain/utils";
import { isStaging } from "../config";
import { useAccount } from "./AccountContext";

export interface PetsInitialElementTypeProps {
  petId: number;
  elementTypeId: number;
}
interface JoinConfig {
  arenaId: string;
  pets: number[];
  petsInitialElementType: PetsInitialElementTypeProps[];
}

interface ArenaAuthSignatureProps {
  message: string;
  signature: string;
}

interface BattleContextData {
  authSignature?: string;
  joinConfig?: JoinConfig;
  isOffChain: boolean;
  setJoinConfig(joinConfig: JoinConfig): void;
  updateAuthSignature(arenaId: string): Promise<string | undefined>;
  setIsOffChain(offChain: boolean): void;
}

const BattleContext = createContext<BattleContextData>({} as BattleContextData);

const BattleProvider: React.FC = ({ children }) => {
  const { account, provider } = useAccount();
  const [authSignature, setAuthSignature] = useState<string | undefined>();
  const [joinConfig, setJoinConfig] = useState<JoinConfig | undefined>();
  const [isOffChain, setIsOffChain] = useState<boolean>(isStaging);

  useEffect(() => {
    if (isStaging) {
      const isOffChainSelected = localStorage.getItem("isOffChain");

      setIsOffChain(isOffChainSelected === "true");
    }
  }, []);

  useEffect(() => {
    const loadSignature = async () => {
      let arenaAuthSignature: ArenaAuthSignatureProps | string | null =
        localStorage.getItem("arenaAuthSignature");

      // TODO: Remove this signature from localStorage when the this battle is finished
      if (arenaAuthSignature && account.address) {
        arenaAuthSignature = JSON.parse(
          arenaAuthSignature,
        ) as ArenaAuthSignatureProps;

        const { message, signature } = arenaAuthSignature;
        const loggedAddress = account.address;

        const recoveredAddress = await recoverAddress(signature, message);

        if (loggedAddress?.toLowerCase() === recoveredAddress) {
          setAuthSignature(signature);
        } else {
          localStorage.removeItem("arenaAuthSignature");
        }
      }
    };
    loadSignature();
  }, [account.address]);

  const updateAuthSignature = useCallback(
    async (arenaId): Promise<string | undefined> => {
      if (!provider || !account.address) {
        return undefined;
      }

      const message = `Join arena ${arenaId}`;

      const signature = await requestSignature(message, provider);

      const arenaAuthSignature: ArenaAuthSignatureProps = {
        signature,
        message,
      };

      localStorage.setItem(
        "arenaAuthSignature",
        JSON.stringify(arenaAuthSignature),
      );

      setAuthSignature(signature);

      return signature;
    },
    [account, provider],
  );

  const contextValue = useMemo(
    () => ({
      authSignature,
      joinConfig,
      updateAuthSignature,
      setJoinConfig,
      isOffChain,
      setIsOffChain,
    }),
    [
      authSignature,
      joinConfig,
      updateAuthSignature,
      setJoinConfig,
      isOffChain,
      setIsOffChain,
    ],
  );

  return (
    <BattleContext.Provider value={contextValue}>
      {children}
    </BattleContext.Provider>
  );
};

export const useBattle = (): BattleContextData => {
  const context = useContext(BattleContext);

  return context;
};

export default BattleProvider;
