import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./StakingIncome.scss";
import LinearProgress from "@material-ui/core/LinearProgress";
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined";
import { usePools } from "../../../hooks/usePools";
import { useAccount } from "../../../contexts/AccountContext";
import StarchiBox from "../../../components/Starchibox";

import WalletModal from "../../../components/WalletModal";
import Button, { OutlineButton } from "../../../components/Button";
import PoolDetailsModal from "./PoolDetailsModal";
import StakingModal from "./StakingModal";
import {
  SHORT_ADDRESS,
  BUY_LINK,
  FORMAT_APY,
} from "../../../constants/constants";
import { toastError } from "../../../utils/errorHandlers";

const StakingIncome = () => {
  const history = useHistory();
  const { address } = useParams();
  const { account } = useAccount();
  const { items: pools, approve, stake, isLoading } = usePools();
  const [detailsModal, showDetailsModal] = useState(false);
  const [stakeModal, showStakeModal] = useState(false);
  const [selectedPool, setSelectedPool] = useState(null);
  const [stakingPool, setStakingPool] = useState(null);
  const [walletModal, showWalletModal] = useState(false);
  const [isWaitingTrx, setIsWaitingTrx] = useState(false);

  useEffect(() => {
    if (address) {
      if (pools[address]) {
        setStakingPool(pools[address]);
        showStakeModal(true);
      } else {
        setStakingPool(null);
        showStakeModal(false);
        history.push("/staking/pools");
      }
    }
  }, [address, history, pools]);

  const onStake = (pool) => {
    history.push(`/staking/${pool.pool}/stake`);
  };

  const onStakeConfirm = async (pool, amount, lockUntil) => {
    console.log("OnStakeConfirm", pool, amount, lockUntil);
    setIsWaitingTrx(true);
    try {
      await stake(pool, amount, lockUntil);
      showStakeModal(false);
      history.push("/staking/pools");
    } catch (error) {
      console.error("STAKE ERROR", error);
      toastError(error);
    }
    setIsWaitingTrx(false);
  };

  const onApprove = async (pool) => {
    setIsWaitingTrx(true);
    try {
      await approve(pool);
    } catch (error) {
      console.error("APPROVE ERROR", error);
      toastError(error);
    }
    setIsWaitingTrx(false);
  };

  const onDetail = (pool) => {
    console.log("onDetail", pool);
    setSelectedPool(pool);
    showDetailsModal(true);
  };

  const onConnect = () => {
    showWalletModal(true);
  };

  return (
    <div className="staking-income-container">
      <div className="staking-income-title-container">
        <div className="staking-income-title-left">
          <h1 className="staking-income-heading">STAKING</h1>
          <span className="staking-income-sub-heading">
            There are four core pools in Starchi's Staking portal. They offer
            variable locking for up to{" "}
            <span className="staking-income-sub-bold">12 months</span>.
          </span>
        </div>
        <div className="staking-income-title-right">
          {account && account.address && (
            <div
              className="staking-income-address"
              onClick={() => {
                showWalletModal(true);
              }}
              aria-hidden="true"
            >
              {SHORT_ADDRESS(account.address)}
            </div>
          )}
        </div>
      </div>
      {isLoading && Object.values(pools).length === 0 ? (
        <div className="staking-income-loader">
          <LinearProgress />
        </div>
      ) : (
        <div className="staking-income-list">
          <StarchiBox horizontal={false} styles={{ padding: "30px 40px" }}>
            {account && account.address && (
              <table className="staking-income-table">
                <tr className="staking-income-table-header">
                  <th
                    className="staking-income-table-th"
                    style={{ width: "30%", textAlign: "left" }}
                  >
                    Pools
                  </th>
                  <th
                    className="staking-income-table-th"
                    style={{ width: "20%", textAlign: "center" }}
                  >
                    TVL
                  </th>
                  <th
                    className="staking-income-table-th"
                    style={{ width: "20%", textAlign: "center" }}
                  >
                    APY
                  </th>
                  <th
                    className="staking-income-table-th"
                    style={{ width: "30%" }}
                  />
                </tr>
                {Object.values(pools).map((pool) => (
                  <tr
                    key={`pools-${pool.token.address}`}
                    className="staking-income-table-row"
                  >
                    <td
                      className="staking-income-table-td"
                      style={{ textAlign: "left" }}
                    >
                      <div
                        className="staking-income-table-img-symbol"
                        onClick={() => {
                          window.open(BUY_LINK[pool.token.address]);
                        }}
                        aria-hidden="true"
                      >
                        <img
                          src={`/tokens/${pool.token.address}.png`}
                          alt="img"
                          className="staking-income-table-td-img"
                        />
                        <span className="staking-income-table-td-text">
                          {pool?.token?.symbol}
                        </span>
                        <div className="staking-income-arrow">
                          <LaunchOutlinedIcon
                            style={{ width: "15px", height: "15px" }}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="staking-income-table-td">
                      $
                      {parseFloat(
                        pool?.tvl?.toFixed(2) || "0",
                      ).toLocaleString()}
                    </td>
                    <td className="staking-income-table-td">
                      {FORMAT_APY(pool?.apy || 0) || 0}%
                    </td>
                    <td className="staking-income-table-td">
                      <div className="staking-income-buttons-container">
                        <OutlineButton
                          text="DETAILS"
                          size="md"
                          onClick={() => {
                            onDetail(pool);
                          }}
                          aria-hidden="true"
                        />
                        {isWaitingTrx ? (
                          <Button
                            disabled
                            text="PLEASE WAIT..."
                            size="md"
                            onClick={() => {
                              // TODO: Add wait function
                            }}
                            aria-hidden="true"
                          />
                        ) : pool?.approved ? (
                          <Button
                            text="STAKE"
                            size="md"
                            onClick={() => {
                              onStake(pool);
                            }}
                            aria-hidden="true"
                          />
                        ) : (
                          <Button
                            text="APPROVE"
                            size="md"
                            onClick={() => {
                              onApprove(pool);
                            }}
                            aria-hidden="true"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            )}
            {account && account.address && (
              <div className="staking-income-table-mobile">
                <div className="staking-income-table-header-mobile">
                  <th className="staking-income-table-th">Pools</th>
                </div>
                {Object.values(pools).map((pool) => (
                  <div
                    key={`pools-mobile-${pool.token.address}`}
                    className="staking-income-table-row-mobile"
                  >
                    <div className="staking-income-table-row-mobile-up">
                      <div className="staking-income-table-row-mobile-item">
                        <div
                          className="staking-income-table-img-symbol"
                          onClick={() => {
                            window.open(BUY_LINK[pool.token.address]);
                          }}
                          aria-hidden="true"
                        >
                          <img
                            src={`/tokens/${pool.token.address}.png`}
                            alt="img"
                            className="staking-income-table-td-img"
                          />
                          <span className="staking-income-table-td-text">
                            {pool?.token?.symbol}
                          </span>
                          <div className="staking-income-arrow">
                            <LaunchOutlinedIcon
                              style={{ width: "15px", height: "15px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="staking-income-table-row-mobile-item">
                        <div className="staking-income-table-row-mobile-heading-value">
                          <span>TVL: </span>
                          <span>
                            $
                            {parseFloat(
                              pool?.tvl?.toFixed(2) || "0",
                            ).toLocaleString()}
                          </span>
                        </div>
                      </div>
                      <div className="staking-income-table-row-mobile-item">
                        <div className="staking-income-table-row-mobile-heading-value">
                          <span>APY: </span>
                          <span>{FORMAT_APY(pool?.apy || 0) || 0}%</span>
                        </div>
                      </div>
                    </div>
                    <td
                      className="staking-income-table-td"
                      style={{ width: "300px" }}
                    >
                      <div className="staking-income-buttons-container">
                        <OutlineButton
                          text="DETAILS"
                          size="md"
                          onClick={() => {
                            onDetail(pool);
                          }}
                          aria-hidden="true"
                        />
                        {pool?.approved ? (
                          <Button
                            text="STAKE"
                            size="md"
                            onClick={() => {
                              onStake(pool);
                            }}
                            aria-hidden="true"
                          />
                        ) : (
                          <Button
                            text="APPROVE"
                            size="md"
                            onClick={() => {
                              onApprove(pool);
                            }}
                            aria-hidden="true"
                          />
                        )}
                      </div>
                    </td>
                  </div>
                ))}
              </div>
            )}
            {(!account || !account.address) && (
              <div className="staking-income-connect">
                <div className="staking-income-connect-text">
                  Connect your wallet to see available pools
                </div>
                <div className="staking-income-connect-button">
                  <Button
                    text="CONNECT WALLET"
                    size="md"
                    onClick={() => {
                      onConnect();
                    }}
                  />
                </div>
              </div>
            )}
          </StarchiBox>
        </div>
      )}
      <PoolDetailsModal
        modalOpen={detailsModal}
        onClose={() => {
          showDetailsModal(false);
        }}
        pool={selectedPool}
        onConfirm={() => {
          showDetailsModal(false);
          history.push(`/staking/${selectedPool.pool}/stake`);
        }}
        onApprove={() => {
          onApprove(selectedPool);
        }}
      />
      <StakingModal
        isLoading={isWaitingTrx}
        modalOpen={stakeModal}
        onClose={() => {
          history.push("/staking/pools");
          showStakeModal(false);
        }}
        pool={stakingPool}
        onStake={(amount, lockUntil) => {
          onStakeConfirm(stakingPool, amount, lockUntil);
        }}
        onApprove={() => {
          onApprove(stakingPool);
        }}
      />
      <WalletModal
        modalOpen={walletModal}
        onClose={() => {
          showWalletModal(false);
        }}
      />
    </div>
  );
};

export default StakingIncome;
