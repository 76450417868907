import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import StarchiLogo from "../../img/starchi_smaller_I.png";
import TelegramLogo from "../../img/Telegram Button.svg";
import TwitterLogo from "../../img/Twitter Button.svg";
import PolygonLogo from "../../img/Polygon Scan Button.png";
import DiscordLogo from "../../img/Discord Button.png";
import MediumLogo from "../../img/medium-svg.png";
import EmailLogo from "../../img/email@2x.png";
import "./styles.scss";

const Footer: React.FC = () => {
  const onClickTelegram = () => {
    window.open("https://t.me/OfficialStarchi", "_blank");
  };
  const onClickTwitter = () => {
    window.open("https://twitter.com/OfficialStarchi", "_blank");
  };

  const onClickDiscord = () => {
    window.open("https://discord.gg/JBEYKs7dD9", "_blank");
  };

  const onClickPolygon = () => {
    window.open(
      "https://polygonscan.com/token/0xed0bb69f9f96e8c5fffdf16c468ecec385134ea5",
      "_blank",
    );
  };

  const onClickMedium = () => {
    window.open("https://starchi.medium.com", "_blank");
  };

  const onClickEmail = () => {
    window.open("mailto:info@starter.xyz", "_blank");
  };
  return (
    <MDBFooter color="#100C40" className="font-small pt-4 FooterContainer">
      <MDBContainer
        fluid
        className="footer-main-content text-center text-md-left"
      >
        <MDBRow>
          <MDBCol md="4">
            <img alt="" className="Logo" src={StarchiLogo} />
          </MDBCol>
          <MDBCol md="2">
            <ul>
              <li className="list-unstyled">
                <a href="#!">Home</a>
              </li>
              <li className="list-unstyled">
                <a href="#!">Pet Store</a>
              </li>
              <li className="list-unstyled">
                <a href="#!">Crate</a>
              </li>
              <li className="list-unstyled">
                <a href="#!">Arena</a>
              </li>
              <li className="list-unstyled">
                <a href="#!">FAQ</a>
              </li>
            </ul>
          </MDBCol>
          <MDBCol md="3">
            <ul>
              <li className="list-unstyled">
                <a href="#!">News</a>
              </li>
              <li className="list-unstyled">
                <a
                  href="https://docs.starchi.gg/full-guide-beta"
                  target="_blank"
                  rel="noreferrer"
                >
                  Getting Started
                </a>
              </li>
              <li className="list-unstyled">
                <a href="#!">Terms of Use</a>
              </li>
              <li className="list-unstyled">
                <a href="#!">Privacy Policy</a>
              </li>
              <li className="list-unstyled">
                <a href="https://starchi.gg/Story.pdf">White Paper</a>
              </li>
            </ul>
          </MDBCol>
          <MDBCol md="3">
            <div className="Title_Community">Join our community</div>
            <div className="row-center social-group">
              <img
                alt=""
                className="SocialButtons"
                src={TelegramLogo}
                onClick={() => onClickTelegram()}
                aria-hidden="true"
              />
              <img
                alt=""
                className="SocialButtons"
                src={TwitterLogo}
                onClick={() => onClickTwitter()}
                aria-hidden="true"
              />
              <img
                alt=""
                className="SocialButtons"
                src={PolygonLogo}
                onClick={() => onClickPolygon()}
                aria-hidden="true"
              />
              <img
                alt=""
                className="SocialButtons"
                src={DiscordLogo}
                onClick={() => onClickDiscord()}
                aria-hidden="true"
              />
              <img
                alt=""
                className="SocialButtons"
                src={MediumLogo}
                onClick={() => onClickMedium()}
                aria-hidden="true"
              />
              <img
                alt=""
                className="SocialButtons"
                src={EmailLogo}
                onClick={() => onClickEmail()}
                aria-hidden="true"
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright:{" "}
          <a href="https://starchi.gg"> Starter Labs </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default Footer;
