import toast from "react-hot-toast";

const toastStyle = {
  border: "3px solid #1f2063",
  background: "#24256d",
  color: "#fff",
  fontFamily: "Josefin Sans",
  fontWeight: "bold",
};

/**
 * Parse and formats an error message if it's known. Otherwise just throw the raw error.
 */
export const parseError = (error: any): string => {
  const message =
    typeof error === "string" || error instanceof String
      ? error
      : error?.data?.message !== undefined
      ? error?.data?.message
      : error?.message || JSON.stringify(error);

  if (message.indexOf("INSUFFICIENT_VRF_FUNDS") >= 0) {
    return "Please contact the Game Admin. INSUFFICIENT_VRF_FUNDS";
  }

  if (message.indexOf("transfer amount exceeds balance") >= 0) {
    return "It seems like your wallet has Insufficient balance.";
  }

  if (message.indexOf("transfer amount exceeds allowance") >= 0) {
    return "It seems like you did not approve the game contract to use required tokens.";
  }

  if (message.indexOf("Failed to fetch") >= 0) {
    return "Ooops! A Network connection issue happened.";
  }

  if (message.indexOf("INSUFFICIENT_PETS") >= 0) {
    return "It seems that you don't have enough Qualified Starchis...";
  }

  if (message.indexOf("PLAYER_ALREADY_IN_BATTLE") >= 0) {
    return "Looks like you are in a Battle already! Please reconnect to it!";
  }

  // extract error message from solidity
  const rawErrorMessage = message?.match(
    'reverted with reason string(.*)", method=',
  );

  if (rawErrorMessage && rawErrorMessage.length >= 2) {
    return `Error: ${rawErrorMessage[1]}`;
  }

  return message.substring(0, 512);
};

/** Helper function to toast a Successful message */
export const toastSuccess = (message: string): string =>
  toast.success(message, {
    position: "top-right",
    style: toastStyle,
  });

/** Helper function to toast an Error */
export const toastError = (errorMessage: any): string =>
  toast.error(parseError(errorMessage), {
    position: "top-right",
    style: toastStyle,
  });
