import PoolAbi from "./abis/pool.json";

import bigInt from "big-integer";
import Erc20Abi from "./abis/erc20.json";

export const approvePool = async (web3, token, spender, account) => {
  try {
    const erc20Contract = new web3.eth.Contract(Erc20Abi, token);
    if (!erc20Contract.methods.allowance) {
      return true; // Doesn't need approval
    }

    const approveAmount = web3.utils.toWei("9999999999", "ether");
    const gasPrice = await web3.eth.getGasPrice();
    const gas = await erc20Contract.methods
      .approve(spender, approveAmount)
      .estimateGas({
        from: account,
        gasPrice,
      });
    const receipt = await erc20Contract.methods
      .approve(spender, approveAmount)
      .send({
        from: account,
        gas,
        gasPrice,
      });
    console.info("approve receipt", receipt);
  } catch (error) {
    console.error(error);
    if (!error.toString().includes("-32601")) {
      throw error;
    }
  }
};

export const getTokenAmountPool = async (web3, token, account) => {
  const erc20Contract = new web3.eth.Contract(Erc20Abi, token);
  if (account) {
    const tokenAmount = await erc20Contract.methods.balanceOf(account).call();
    return tokenAmount;
  }
  return "0";
};

export const stake = async (web3, pool, amount, lockUntil, account) => {
  try {
    const poolContract = new web3.eth.Contract(PoolAbi, pool);
    const gasPrice = await web3.eth.getGasPrice();
    const gas = await poolContract.methods
      .stake(amount, lockUntil)
      .estimateGas({
        from: account,
        gasPrice,
      });
    const receipt = await poolContract.methods.stake(amount, lockUntil).send({
      from: account,
      gas,
      gasPrice,
    });
    console.info("stake receipt", receipt);
  } catch (error) {
    console.error(error);
    if (!error.toString().includes("-32601")) {
      throw error;
    }
  }
};

export const unstake = async (web3, pool, depositId, amount, account) => {
  try {
    const poolContract = new web3.eth.Contract(PoolAbi, pool);
    const gasPrice = await web3.eth.getGasPrice();
    const gas = await poolContract.methods
      .unstake(depositId, amount)
      .estimateGas({
        from: account,
        gasPrice,
      });
    const receipt = await poolContract.methods.unstake(depositId, amount).send({
      from: account,
      gas,
      gasPrice,
    });
    console.info("unstake receipt", receipt);
  } catch (error) {
    console.error(error);
    if (!error.toString().includes("-32601")) {
      throw error;
    }
  }
};

export const claim = async (web3, pool, account) => {
  try {
    const poolContract = new web3.eth.Contract(PoolAbi, pool);
    const gasPrice = await web3.eth.getGasPrice();
    const gas = await poolContract.methods.processRewards().estimateGas({
      from: account,
      gasPrice,
    });
    const receipt = await poolContract.methods.processRewards().send({
      from: account,
      gas,
      gasPrice,
    });
    console.info("claim receipt", receipt);
  } catch (error) {
    console.error(error);
    if (!error.toString().includes("-32601")) {
      throw error;
    }
  }
};

const getApprovalPool = async (web3, token, target, account) => {
  const erc20Contract = new web3.eth.Contract(Erc20Abi, token);
  if (!erc20Contract.methods.allowance) {
    return true; // Doesn't need approval
  }
  const allowance = await erc20Contract.methods
    .allowance(account, target)
    .call({ from: account });
  if (bigInt(allowance).greater("0")) {
    return true;
  }
  return false;
};

export const getStakedInfo = async (web3, pool, token, account) => {
  const PoolContract = new web3.eth.Contract(PoolAbi, pool);
  const userInfo = await PoolContract.methods.users(account).call();
  const pendingRewards = await PoolContract.methods
    .pendingYieldRewards(account)
    .call();
  const avgRewardLockPeriod = await PoolContract.methods
    .getRewardLockPeriod(account)
    .call();
  const depositLength = await PoolContract.methods
    .getDepositsLength(account)
    .call();
  const approved = await getApprovalPool(web3, token, pool, account);
  const balance = await getTokenAmountPool(web3, token, account);
  return {
    poolAddress: pool,
    userInfo: userInfo,
    pendingRewards,
    avgRewardLockPeriod,
    approved,
    balance,
    depositLength,
  };
};

export const getVestingInfo = async (web3, pool, account) => {
  const PoolContract = new web3.eth.Contract(PoolAbi, pool);
  const length = await PoolContract.methods.getDepositsLength(account).call();
  const deposits = [];
  for (let i = 0; i < length; i++) {
    const deposit = await PoolContract.methods.getDeposit(account, i).call();
    deposits.push({
      isYield: deposit.isYield,
      liquidPercentage: deposit.liquidPercentage,
      lockedFrom: deposit.lockedFrom,
      lockedUntil: deposit.lockedUntil,
      tokenAmount: deposit.tokenAmount,
      weight: deposit.weight,
      depositId: i,
    });
  }
  return deposits;
};
