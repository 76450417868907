import { useState, useEffect } from "react";
import { ranksSampleData, RanksDataProps } from "../mocks/ranksSampleData";

interface UseRankProps {
  items: RanksDataProps;
  isLoading: boolean;
}

export const useRank = (): UseRankProps => {
  const [items, setItems] = useState<RanksDataProps>({} as RanksDataProps);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const ranksListReturned = () => {
      setItems(ranksSampleData);
      setLoading(false);
    };

    ranksListReturned();
  }, []);

  return { items, isLoading };
};
