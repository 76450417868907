import { useState } from "react";
import WalletModal from "../WalletModal";
import { SHORT_ADDRESS } from "../../constants/constants";

import "./styles.scss";

interface NotInWhitelistProps {
  address?: string;
}

const NotInWhitelist: React.FC<NotInWhitelistProps> = ({ address }) => {
  const [walletModal, showWalletModal] = useState(false);

  return (
    <div className="item-list-container">
      <div
        className="staking-income-address"
        style={{ maxWidth: 150, margin: "0 0 30px auto" }}
        onClick={() => {
          showWalletModal(true);
        }}
        aria-hidden="true"
      >
        {address && SHORT_ADDRESS(address)}
      </div>
      <div className="not-in-whitelist-container">
        This wallet isn't part of the Starchi Beta Program. If you think this is
        an error, please contact us on our official discord or telegram
        channels.
      </div>
      <WalletModal
        modalOpen={walletModal}
        onClose={() => {
          showWalletModal(false);
        }}
      />
    </div>
  );
};

export default NotInWhitelist;
