import { useHistory } from "react-router-dom";
import StarchiBox from "../../../components/Starchibox";
import Button from "../../../components/Button";
import DoughnutChart from "../../../components/DoughnutChart";

import StakingIncomeLogo from "../../../img/staking/staking-income.png";
import StarchiLogo from "../../../img/staking/starchi-logo.png";
import Vesting from "../../../img/staking/vesting.png";
// import ElxrLogo from "../../../img/staking/starchi-logo.png"; // TODO: Verify if this image is correct
import StakedLine from "../../../img/staking/staked-line.png";

import RiseArrow from "../../../img/staking/arrow-rise.svg";
import FallenArrow from "../../../img/staking/arrow-fallen.svg";

import { useGenerals } from "../../../hooks/useGenerals";
import { TOTAL_REWARD_AMOUNT } from "../../../constants/constants";
import "./StakingBoard.scss";

const StakingBoard: React.FC = () => {
  const history = useHistory();
  const { generals } = useGenerals();

  const onStakingIncome = () => {
    history.push("/staking/pools");
  };

  const onRewards = () => {
    history.push("/staking/rewards");
  };

  const onVest = () => {
    history.push("/staking/vesting");
  };
  return (
    <div className="staking-board-container">
      <div className="staking-board-action-container">
        <StarchiBox
          styles={{ width: "338px", height: "338px", padding: "30px" }}
        >
          <div className="staking-card-container">
            <div className="staking-card-heading">STAKE</div>
            <div className="staking-card-logo">
              <img
                src={StakingIncomeLogo}
                alt="income"
                className="staking-card-logo-image"
              />
            </div>
            <div className="staking-card-description">
              <span className="staking-card-description-normal">
                Stake-to-earn and Play-for-allocation in IDOs{" "}
                <span className="staking-card-description-bold">
                  on{" "}
                  <a
                    href="https://starter.xyz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Starter.xyz
                  </a>
                </span>{" "}
              </span>
            </div>
            <div className="staking-card-button-container">
              <Button
                text="STAKING"
                onClick={() => {
                  onStakingIncome();
                }}
              />
            </div>
          </div>
        </StarchiBox>
        <StarchiBox
          styles={{ width: "338px", height: "338px", padding: "30px" }}
        >
          <div className="staking-card-container">
            <div className="staking-card-heading">REWARDS</div>
            <div className="staking-card-logo">
              <img
                src={StarchiLogo}
                alt="starchi"
                className="staking-card-logo-image"
              />
            </div>
            <div className="staking-card-description">
              <span className="staking-card-description-normal">
                Staking rewards are distributed as{" "}
                <span className="staking-card-description-bold">ELXR </span>{" "}
                tokens.
              </span>
            </div>
            <div className="staking-card-button-container">
              <Button
                text="REWARDS"
                onClick={() => {
                  onRewards();
                }}
              />
            </div>
          </div>
        </StarchiBox>
        <StarchiBox
          styles={{ width: "338px", height: "338px", padding: "30px" }}
        >
          <div className="staking-card-container">
            <div className="staking-card-heading">VESTING</div>
            <div className="staking-card-logo">
              <img
                src={Vesting}
                alt="vesting"
                className="staking-card-logo-image"
              />
            </div>
            <div className="staking-card-description">
              <span className="staking-card-description-normal">
                <span className="staking-card-description-bold">View</span> and{" "}
                <span className="staking-card-description-bold">Claim</span>{" "}
                your vested rewards and deposits.{" "}
              </span>
            </div>
            <div className="staking-card-button-container">
              <Button
                text="VEST"
                onClick={() => {
                  onVest();
                }}
              />
            </div>
          </div>
        </StarchiBox>
      </div>
      <div className="staking-board-analyse-container">
        <StarchiBox
          styles={{ width: "100%", minHeight: "417px", padding: "30px" }}
        >
          <div className="staking-analyse-card">
            <div className="staking-rewards-claimed">
              <div className="staking-card-heading">GLOBAL REWARDS CLAIMED</div>
              <div className="staking-rewards-chart">
                <DoughnutChart
                  type="global"
                  label="Total distributed"
                  value={`${
                    (parseFloat(generals?.totalDistributed) || 0) / 1000
                  }k`}
                  scale={1}
                  percentageComplete={
                    (parseFloat(generals?.totalDistributed) || 0) /
                    TOTAL_REWARD_AMOUNT
                  }
                />
              </div>
            </div>
            <div className="staking-staked-amount">
              <div className="staking-card-heading">GLOBAL AMOUNT STAKED</div>
              <div className="staking-staked-info">
                <img
                  src={StakedLine}
                  alt="staked"
                  className="staking-staked-line"
                />
                <span className="staking-staked-text">
                  $
                  {parseFloat(
                    String(parseInt(generals?.totalStaked) || 0),
                  ).toLocaleString()}
                </span>
              </div>
            </div>
            <div className="staking-buidl-price">
              <div className="staking-price-value">
                ${generals?.price || "0"}
              </div>
              <div className="staking-price-text">ELXR Price</div>
              <div className="staking-price-change-24">
                {parseFloat(String(generals?.percent24)) > 0 ? (
                  <span className="staking-price-up">
                    <img
                      src={RiseArrow}
                      alt="rise"
                      className="staking-price-arrow-icon"
                    />{" "}
                    {(parseFloat(String(generals?.percent24)) || 0).toFixed(2)}%
                  </span>
                ) : (
                  <span className="staking-price-down">
                    <img
                      src={FallenArrow}
                      alt="fallen"
                      className="staking-price-arrow-icon"
                    />{" "}
                    {(parseFloat(String(generals?.percent24)) || 0).toFixed(2)}%
                  </span>
                )}
                <span className="staking-price-last-24h">last 24h</span>
              </div>
              <div className="staking-buidl-logo-container">
                <img
                  // src={ElxrLogo}
                  src={StarchiLogo}
                  alt="logo"
                  className="staking-buidl-logo"
                />
              </div>
            </div>
          </div>
        </StarchiBox>
      </div>
    </div>
  );
};

export default StakingBoard;
