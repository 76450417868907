import { MDBContainer, MDBRow } from "mdbreact";
import { useHistory } from "react-router";

import images from "../../../../images";
import "../../styles.scss";
import Button from "../../../../components/Button";

const PetStoreHome: React.FC = () => {
  const history = useHistory();

  return (
    <div className="section-row">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow className="col-center">
          <div className="title-group">
            <div className="section-title text-center">
              Raise Starchis Well to{" "}
            </div>
            <div className="section-title text-center">
              Help Avoid Extinction
            </div>
          </div>
        </MDBRow>
        <MDBRow className="row-center">
          {/* <img alt="" src={images.Pets} className="patner-logo"></img> */}
          <div className="pets">
            <img alt="" src={images.Ch4} className="patner-ch4" />
            <img alt="" src={images.Ch5} className="patner-ch5" />
            <img alt="" src={images.Ch3} className="patner-ch3" />
            <img alt="" src={images.Ch1} className="patner-ch1" />
            <img alt="" src={images.Ch2} className="patner-ch2" />
            <img alt="" src={images.Ch6} className="patner-ch6" />
            <img alt="" src={images.Ch7} className="patner-ch7" />
          </div>
        </MDBRow>
        <MDBRow className="col-center">
          <div className="btn-parent-group">
            <Button
              style={{ marginRight: "1rem" }}
              size="lg"
              text="GO TO PET STORE"
              onClick={() => {
                history.push("/store");
              }}
            />
            <Button
              style={{ marginLeft: "1rem" }}
              size="lg"
              text="GO TO CRATE"
              onClick={() => {
                history.push("/crate");
              }}
            />
          </div>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default PetStoreHome;
