import { useCallback, useEffect, useState } from "react";

import { getVesting } from "../api/pools";
import * as Pool from "../api/chain/pool";
import { useAccount } from "../contexts/AccountContext";
import { web3StakingPools } from "../config";

export const useVesting = (pools) => {
  const { account, web3 } = useAccount();
  const [vestings, setVestings] = useState({});
  const [isLoading, setLoading] = useState(true);

  const fetchVestings = useCallback(async () => {
    const fetchedVestings = await getVesting(
      account.address,
      web3StakingPools,
      pools,
    );
    setVestings(fetchedVestings);
    setLoading(false);
  }, [account.address, pools]);

  useEffect(() => {
    fetchVestings();
  }, [fetchVestings]);

  const unstake = useCallback(
    async (pool, depositId, amount) => {
      await Pool.unstake(web3, pool.pool, depositId, amount, account.address);
      fetchVestings();
    },
    [account.address, web3, fetchVestings],
  );

  return { vestings, isLoading, unstake };
};
