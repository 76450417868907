import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useAccount } from "../../../../contexts/AccountContext";

import "../../../Staking/components/StakingIncome.scss";

import Button from "../../../../components/Button";
import WalletModal from "../../../../components/WalletModal";
import NotInWhitelist from "../../../../components/NotInWhitelist";

import SellSuccessModal from "../SellSuccessModal";
import CrateCard from "../CrateCard";

import ElementsListModal from "../ElementsListModal";

import { SHORT_ADDRESS } from "../../../../constants/constants";
import { useCrate } from "../../contexts/CrateContext";
import { whitelistAccounts } from "../../../../config";

import "../styles.scss";

const MyCrateBox: React.FC = () => {
  const history = useHistory();
  const { account } = useAccount();
  const { myPets, isLoadingMyPets } = useCrate();

  const [walletModal, showWalletModal] = useState(false);
  const [showMyCardsModal, setShowMyCardsModal] = useState(false);
  const [isSellSuccessModal, showSellSuccessModal] = useState(false);

  const onConnect = useCallback(() => {
    showWalletModal(true);
  }, []);

  if (account.address && !whitelistAccounts.includes(account.address)) {
    return <NotInWhitelist address={account.address} />;
  }

  return (
    <>
      <div className="my-crate-container">
        <div className="my-crate-header">
          <div className="d-flex">
            <h1 className="my-crate-heading">My Crate</h1>
            {account?.address && (
              <Button
                text="Elements Card List"
                size="sm"
                onClick={() => setShowMyCardsModal(true)}
              />
            )}
          </div>
          <div className="d-flex">
            <Button
              style={{ marginRight: "0.8rem" }}
              text="Default crate"
              size="md"
              onClick={() => history.push("/crate")}
            />
            <div className="staking-income-title-right">
              {!isLoadingMyPets && account?.address && (
                <button
                  type="button"
                  className="staking-income-address"
                  onClick={() => {
                    showWalletModal(true);
                  }}
                >
                  {SHORT_ADDRESS(account.address)}
                </button>
              )}
            </div>
          </div>
        </div>

        {!account || !account.address ? (
          <div className="staking-income-connect">
            <div className="staking-income-connect-text">
              Connect your wallet to see your pets
            </div>
            <div className="staking-income-connect-button">
              <Button
                text="CONNECT WALLET"
                size="md"
                onClick={() => {
                  onConnect();
                }}
              />
            </div>
          </div>
        ) : isLoadingMyPets ? (
          <div className="my-crate-loader">
            <LinearProgress />
          </div>
        ) : !myPets.length ? (
          <div className="crate-box-call-action">
            <div className="crate-box-call-action-text">
              You don't have any Starchis, mint your first egg here:
            </div>
            <div className="crate-box-call-action-button">
              <Button
                text="GO TO STORE"
                size="md"
                onClick={() => history.push("store")}
              />
            </div>
          </div>
        ) : (
          account &&
          account.address && (
            <div className="my-crate-list">
              {myPets.map((pet) => (
                <CrateCard key={pet.petId} pet={pet} />
              ))}
            </div>
          )
        )}
      </div>

      <SellSuccessModal
        modalOpen={isSellSuccessModal}
        onConfirm={() => {
          window.open("/store", "_self");
        }}
        onClose={() => {
          showSellSuccessModal(false);
        }}
      />
      <WalletModal
        modalOpen={walletModal}
        onClose={() => {
          showWalletModal(false);
        }}
      />
      <ElementsListModal
        option="AVAILABLE_ELEMENTS"
        myPets={myPets}
        modalOpen={showMyCardsModal}
        onClose={() => {
          setShowMyCardsModal(false);
        }}
      />
    </>
  );
};

export default MyCrateBox;
