import { useCallback, useEffect, useState } from "react";
import { css } from "@emotion/react";
import Tooltip from "@material-ui/core/Tooltip";
import { GiClover, GiChickenLeg } from "react-icons/gi";
import { FaShower, FaWind, FaEdit } from "react-icons/fa";
import { MdBatteryCharging80 } from "react-icons/md";
import { ImHeart } from "react-icons/im";
import { BsLightningChargeFill } from "react-icons/bs";
import { CgGym } from "react-icons/cg";
import BarLoader from "react-spinners/BarLoader";

import HatchIcon from "../../../../img/hatchicon.png";
import WakeIcon from "../../../../img/wake.svg";
import SleepIcon from "../../../../img/sleep.png";
import PlayIcon from "../../../../img/play.svg";
import ShowerIcon from "../../../../img/shower.svg";
import FeedIcon from "../../../../img/feed.svg";

import StatValue from "../../../../components/StatValue";
import TamagochiStats from "../../../../components/TamagochiStats";
import ApproveTokenModal, {
  ApproveOption,
} from "../../../../components/ApproveTokenModal";
import Button from "../../../../components/Button";
import NameBadge from "../../../../components/StarchiNameBadge";
import StarchiBox from "../../../../components/Starchibox";

import NurturingInfoModal from "../NurturingInfoModal";
import ProgressBar from "../ProgressBar";
import ElementsListModal from "../ElementsListModal";
import NameChangeModal from "../NameChangeModal";

import { ELEMENTS } from "../../../../constants/constants";
import { toastError, toastSuccess } from "../../../../utils/errorHandlers";
import { usePets } from "../../../../hooks/usePets";
import { useContractsParams } from "../../../../contexts/ContractsParamsContext";

import { useCrate } from "../../contexts/CrateContext";
import { useNurturing } from "../../../../hooks/useNurturing";

import "../styles.scss";
import formatSecondsToDuration from "../../../../utils/formatSecondsToDuration";
import { PetWithDetailsProps } from "../../../../api/pets";

const barLoaderCss = css`
  display: block;
  margin-top: 5px;
`;

interface CrateCardProps {
  pet: PetWithDetailsProps;
}

const CrateCard: React.FC<CrateCardProps> = ({ pet }) => {
  const [canEvolve, setCanEvolve] = useState(false);
  const [remainTime, setRemainTime] = useState(0);
  const [isEvolving, setIsEvolving] = useState(false);

  const [nurturingInfoHeader, setNurturingInfoHeader] = useState("");
  const [showNurturingInfoModal, setShowNurturingInfoModal] = useState(false);
  const [showNurturingLoading, setShowNurturingLoading] = useState(false);
  const [isNurturingActionApproved, setIsNurturingActionApproved] =
    useState(false);

  const [showApproveTokenModal, setShowApproveTokenModal] = useState(false);
  const [currentNurturingAction, setCurrentNurturingAction] = useState("");
  const [approveTokenOption, setApproveTokenOption] =
    useState<ApproveOption>("PET_TO_BUIDL");
  const [isSelectedTokenApproved, setIsSelectedTokenApproved] = useState(false);
  const [isNameChangeModal, showNameChangeModal] = useState(false);

  const [showAvailableElementsModal, setShowAvailableElementsModal] =
    useState(false);
  const [showPetElementsModal, setShowPetElementsModal] = useState(false);
  const [canBattle, setCanBattle] = useState(true);

  const {
    evolvePetById,
    onAwake,
    onSleep,
    onExercise,
    onFeed,
    onShower,
    watchPetEvolution,
    updateStarchiName,
  } = useCrate();
  const {
    approvedTokens,
    hungerSecondsTimeout,
    showerSecondsTimeout,
    exerciseSecondsTimeout,
    sleepSecondsTimeout,
    wakeSecondsTimeout,
    percentageDenominator,
    minEnergyPercentage,
    minHungerPercentage,
    minHygienePercentage,
    minExercisePercentage,
  } = useContractsParams();

  const { getIsPetEvolving } = usePets();

  const { fetchFeedPrice, fetchShowerPrice } = useNurturing();

  const loaderColor = "#36D7B7";

  useEffect(() => {
    if (
      percentageDenominator !== undefined &&
      minEnergyPercentage !== undefined &&
      minHungerPercentage !== undefined &&
      minHygienePercentage !== undefined &&
      minExercisePercentage !== undefined &&
      pet.attributes
    ) {
      let requirementsValidation = true;

      const petEnergyRequirement =
        (pet.nurturingStats.energy.points * percentageDenominator) /
        pet.attributes.energyPoints;
      const petHungerRequirement =
        (pet.nurturingStats.hunger.points * percentageDenominator) /
        pet.attributes.hungerPoints;
      const petHygieneRequirement =
        (pet.nurturingStats.hygiene.points * percentageDenominator) /
        pet.attributes.hygienePoints;
      const petExerciseRequirement =
        (pet.nurturingStats.exercise.points * percentageDenominator) /
        pet.attributes.exercisePoints;

      requirementsValidation =
        petEnergyRequirement > minEnergyPercentage &&
        petHungerRequirement > minHungerPercentage &&
        petHygieneRequirement > minHygienePercentage &&
        petExerciseRequirement > minExercisePercentage;

      setCanBattle(requirementsValidation);
    }
  }, [
    pet,
    percentageDenominator,
    minEnergyPercentage,
    minHungerPercentage,
    minHygienePercentage,
    minExercisePercentage,
  ]);

  useEffect(() => {
    // Is triggered when the token was not approved previously & approve modal is closed with the user's approval
    if (!showApproveTokenModal && isSelectedTokenApproved) {
      setShowNurturingInfoModal(true);
      setIsSelectedTokenApproved(false);
    }
  }, [showApproveTokenModal, isSelectedTokenApproved]);

  useEffect(() => {
    const startNurturing = async () => {
      setShowNurturingLoading(true);

      switch (currentNurturingAction) {
        case "AWAKE":
          await onAwake(pet.petId);
          break;
        case "SHOWER":
          await onShower(pet.petId);
          break;
        case "EXERCISE":
          await onExercise(pet.petId);
          break;
        case "SLEEP":
          await onSleep(pet.petId);
          break;
        case "FEED":
          await onFeed(pet.petId);
          break;
        default:
          break;
      }

      setShowNurturingLoading(false);
      setIsNurturingActionApproved(false);
      setCurrentNurturingAction("");
    };

    // Is triggered when the nurturing action is approved by the user and NurturingInfoModal is closed
    if (
      !showNurturingInfoModal &&
      currentNurturingAction !== "" &&
      isNurturingActionApproved
    ) {
      startNurturing();
    }
  }, [
    pet.petId,
    showNurturingInfoModal,
    currentNurturingAction,
    isNurturingActionApproved,
    onAwake,
    onShower,
    onExercise,
    onSleep,
    onFeed,
  ]);

  const evolvePet = useCallback(async () => {
    try {
      setIsEvolving(true);
      await evolvePetById(pet.petId);
    } catch (error) {
      setIsEvolving(false);
      console.error("Fail to evolve pet", error);
      toastError(error);
    }
  }, [pet.petId, evolvePetById]);

  useEffect(() => {
    const checkIsPetEvolving = async () => {
      const isPetEvolving = await getIsPetEvolving(pet.petId);

      if (isPetEvolving) setIsEvolving(true);
    };

    checkIsPetEvolving();
  }, [pet.petId, getIsPetEvolving]);

  useEffect(() => {
    const checkIsPetEvolved = async () => {
      await watchPetEvolution(pet.petId);

      toastSuccess("Your Starchi was successfully evolved.");
      setCanEvolve(false);
      setIsEvolving(false);
    };

    if (isEvolving) {
      checkIsPetEvolved();
    }
  }, [pet.petId, isEvolving, watchPetEvolution]);

  useEffect(() => {
    const evolutionAt = new Date(Number(pet.evolutionAt) * 1000);
    const diff = Date.now() - evolutionAt.getTime();

    if (diff > 0) {
      setCanEvolve(true);
      return undefined;
    }
    // const _duration = Math.floor(pet.evolutionAt - pet.createdAt); // seconds
    const _timer = setInterval(() => {
      const _remaintTime = Math.floor(
        (evolutionAt.getTime() - Date.now()) / 1000,
      );
      // console.log("evolutionAt", evolutionAt);
      // console.log("evolutionAt.getTime()", evolutionAt.getTime());
      // console.log("_remaintTime", _remaintTime);
      // const _time = _duration - _remaintTime;
      // const _completed = 100 - Math.min(_time / _duration, 100);

      // setCompleted(_completed);
      setRemainTime(_remaintTime);

      if (_remaintTime <= 0) {
        setCanEvolve(true);
        clearInterval(_timer);
      }
    }, 1000);
    return () => {
      clearInterval(_timer);
    };
  }, [pet]);

  const handleAwakePet = useCallback(() => {
    setCurrentNurturingAction("AWAKE");
    setShowNurturingLoading(true);

    const formattedSleepToHours = formatSecondsToDuration(sleepSecondsTimeout);

    setNurturingInfoHeader(
      `Are you sure you want to wake up your Starchi? Your Starchi will not be able to sleep again for the next ${formattedSleepToHours}.`,
    );
    setShowNurturingInfoModal(true);
  }, [sleepSecondsTimeout]);

  const handleShowerPet = useCallback(async () => {
    setCurrentNurturingAction("SHOWER");
    setShowNurturingLoading(true);

    const formattedShowerToHours =
      formatSecondsToDuration(showerSecondsTimeout);
    const showerCost = await fetchShowerPrice(pet.petId);

    setNurturingInfoHeader(
      `Are you sure you want to shower ${pet.name} for ${showerCost} ELXR? Your Starchi will not be able to be showered again for the next ${formattedShowerToHours}.`,
    );

    if (approvedTokens?.nurturingIsApprovedToElxrTokens) {
      setShowNurturingInfoModal(true);
    } else {
      setApproveTokenOption("NURTURING_TO_ELXR");
      setShowApproveTokenModal(true);
    }
  }, [pet, approvedTokens, fetchShowerPrice, showerSecondsTimeout]);

  const handleExercisePet = useCallback(() => {
    setCurrentNurturingAction("EXERCISE");
    setShowNurturingLoading(true);

    const formattedExerciseToHours = formatSecondsToDuration(
      exerciseSecondsTimeout,
    );

    setNurturingInfoHeader(
      `Are you sure you want to exercise ${pet.name}? Your Starchi will not be able to exercise again for the next ${formattedExerciseToHours}.`,
    );
    setShowNurturingInfoModal(true);
  }, [pet, exerciseSecondsTimeout]);

  const handleSleepPet = useCallback(() => {
    setCurrentNurturingAction("SLEEP");
    setShowNurturingLoading(true);

    const formattedWakeToHours = formatSecondsToDuration(wakeSecondsTimeout);

    setNurturingInfoHeader(
      `Are you sure you want to put your Starchi to sleep? Your Starchi will not be able to wake up the next ${formattedWakeToHours}.`,
    );
    setShowNurturingInfoModal(true);
  }, [wakeSecondsTimeout]);

  const handleFeedPet = useCallback(async () => {
    setCurrentNurturingAction("FEED");
    setShowNurturingLoading(true);

    const formattedFeedToHours = formatSecondsToDuration(hungerSecondsTimeout);
    const feedCost = await fetchFeedPrice(pet.petId);

    setNurturingInfoHeader(
      `Are you sure you want to feed ${pet.name} for ${feedCost} ELXR? Your Starchi will not be able to be fed again for the next ${formattedFeedToHours}.`,
    );

    if (approvedTokens?.nurturingIsApprovedToElxrTokens) {
      setShowNurturingInfoModal(true);
    } else {
      setApproveTokenOption("NURTURING_TO_ELXR");
      setShowApproveTokenModal(true);
    }
  }, [pet, approvedTokens, hungerSecondsTimeout, fetchFeedPrice]);

  const onChangeNameConfirm = useCallback(
    async (newName) => {
      console.log("Change Name Confirm", newName);

      try {
        await updateStarchiName(pet.petId, newName);
        toastSuccess("Name changed successfully");
      } catch (err) {
        toastError(err);
      }

      showNameChangeModal(false);
    },
    [pet.petId, updateStarchiName],
  );

  return (
    <>
      <StarchiBox styles={{ padding: "30px 40px" }}>
        <div className="crate-card-container">
          <div className="crate-card-main-info">
            <div className="crate-card-left">
              <div className="d-flex align-items-center">
                <NameBadge
                  petBreedId={String(pet.breedId)}
                  name={pet.name}
                  id={String(pet.petId)}
                />
                <button
                  type="button"
                  className="edit-starchi-name"
                  onClick={() => showNameChangeModal(true)}
                >
                  <FaEdit size={20} color="#949daf" />
                </button>
              </div>

              <div className="crate-card-image-container">
                {canBattle ? (
                  <img
                    src={pet.image || undefined}
                    alt="Starchi"
                    className="crate-card-image"
                  />
                ) : (
                  <Tooltip
                    title={
                      <p
                        style={{
                          fontFamily: "Josefin Sans",
                          fontSize: "1.1rem",
                          margin: "0.3rem 0",
                        }}
                      >
                        This starchi is penalized, so it cannot battle
                      </p>
                    }
                    placement="bottom"
                    arrow
                  >
                    <img
                      src={pet.image || undefined}
                      alt="Starchi"
                      className={`crate-card-image ${
                        !canBattle && "gray-scale"
                      }`}
                    />
                  </Tooltip>
                )}
              </div>

              {pet.stage !== "Egg" && (
                <div className="crate-card-element">
                  <div className="crate-card-mint-element">
                    <Button
                      text="Attach element"
                      size="sm"
                      onClick={() => setShowAvailableElementsModal(true)}
                    />
                  </div>
                </div>
              )}

              <div className="crate-card-elements-wrapper">
                {pet.elements.map((element) => (
                  <div
                    key={element.typeId}
                    className="crate-card-element-container"
                  >
                    <img
                      src={ELEMENTS[Number(element.typeId)]?.icon}
                      alt="element"
                      className="crate-card-element-image"
                    />
                    <p className="element-name">
                      {ELEMENTS[element.typeId]?.name}
                    </p>
                  </div>
                ))}
              </div>

              {!canEvolve && pet.stage !== "Adult" && (
                <ProgressBar remainTime={remainTime} />
              )}
            </div>
            <div className="crate-card-right">
              <div className="crate-card-stage">
                <span className="crate-card-stage-label">Stage:</span>
                <span className="crate-card-stage-value">{pet.stage}</span>
              </div>

              <div className="crate-card-stats-container w-100">
                <div className="d-flex w-100">
                  <TamagochiStats
                    IconComponent={<GiChickenLeg />}
                    title="Hunger"
                    backgroundColor="#f07d30"
                    open={pet.stage !== "Egg"}
                    maxValue={pet.attributes?.hungerPoints || 0}
                    currentValue={pet.nurturingStats.hunger.points || 0}
                  />
                  <StatValue
                    IconComponent={<ImHeart />}
                    showIconIndicators={
                      pet.stage !== "Egg" && pet.stage !== "Child"
                    }
                    backgroundColor="#e12939"
                    title="HP"
                    currentValue={pet.nurturingStats.hunger.points || 0}
                    value={pet.attributes?.hungerPoints || 0}
                  />
                </div>
                <div className="d-flex w-100">
                  <TamagochiStats
                    IconComponent={<MdBatteryCharging80 />}
                    backgroundColor="#0d5058"
                    title="Stamina"
                    open={pet.stage !== "Egg"}
                    maxValue={pet.attributes?.energyPoints || 0}
                    currentValue={pet.nurturingStats.energy.points || 0}
                  />
                  <StatValue
                    IconComponent={<BsLightningChargeFill />}
                    showIconIndicators={
                      pet.stage !== "Egg" && pet.stage !== "Child"
                    }
                    backgroundColor="#7b21fc"
                    title="Energy"
                    currentValue={pet.nurturingStats.energy.points || 0}
                    value={pet.attributes?.energyPoints || 0}
                  />
                </div>

                <div className="d-flex w-100">
                  <TamagochiStats
                    IconComponent={<FaShower />}
                    backgroundColor="#cb21ae"
                    title="Hygiene"
                    open={pet.stage !== "Egg"}
                    maxValue={pet.attributes?.hygienePoints || 0}
                    currentValue={pet.nurturingStats.hygiene.points || 0}
                  />
                  <StatValue
                    IconComponent={<GiClover />}
                    showIconIndicators={
                      pet.stage !== "Egg" && pet.stage !== "Child"
                    }
                    backgroundColor="#16b200"
                    title="Luckiness"
                    currentValue={pet.nurturingStats.hygiene.points || 0}
                    value={pet.attributes?.hygienePoints || 0}
                  />
                </div>

                <div className="d-flex w-100">
                  <TamagochiStats
                    IconComponent={<CgGym />}
                    backgroundColor="#061ce0"
                    title="Exercise"
                    open={pet.stage !== "Egg"}
                    maxValue={pet.attributes?.exercisePoints || 0}
                    currentValue={pet.nurturingStats.exercise.points || 0}
                  />
                  <StatValue
                    IconComponent={<FaWind />}
                    showIconIndicators={
                      pet.stage !== "Egg" && pet.stage !== "Child"
                    }
                    backgroundColor="#cec505"
                    title="Speed"
                    currentValue={pet.nurturingStats.exercise.points || 0}
                    value={pet.attributes?.exercisePoints || 0}
                  />
                </div>
              </div>

              {pet.elements.length > 0 && (
                <div className="crate-card-element view-pet-elements">
                  <div className="crate-card-mint-element">
                    <Button
                      text="View pet elements"
                      size="sm"
                      onClick={() => setShowPetElementsModal(true)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="crate-card-actions">
            {pet.stage === "Egg" && canEvolve && (
              <div>
                {!isEvolving && (
                  <button
                    type="button"
                    className="crate-card-hatch-button"
                    onClick={evolvePet}
                  >
                    <div className="crate-card-waka-button-bg" />
                    <img
                      src={HatchIcon}
                      alt="hatch"
                      className="crate-card-hatch-button-image"
                    />
                    <span className="crate-card-hatch-button-text">
                      hatchegg
                    </span>
                  </button>
                )}
                <div className="spinner-center">
                  <BarLoader
                    color={loaderColor}
                    loading={isEvolving}
                    css={barLoaderCss}
                    height={4}
                    width={200}
                  />
                </div>
              </div>
            )}
            {(pet.stage === "Child" || pet.stage === "Teen") && canEvolve && (
              <div>
                {!isEvolving && (
                  <div style={{ margin: !isEvolving ? "1rem 0 0" : "0" }}>
                    <Button
                      text={
                        pet.stage === "Child"
                          ? "Evolve to teen"
                          : "Evolve to Adult"
                      }
                      size="md"
                      onClick={evolvePet}
                    />
                  </div>
                )}
                <div
                  className="spinner-center"
                  style={{ margin: isEvolving ? "1rem 0 0" : "0" }}
                >
                  <BarLoader
                    color={loaderColor}
                    loading={isEvolving}
                    css={barLoaderCss}
                    height={4}
                    width={200}
                  />
                </div>
              </div>
            )}

            <div className={`spinner-center ${showNurturingLoading && "mt-5"}`}>
              <BarLoader
                color={loaderColor}
                loading={showNurturingLoading}
                css={barLoaderCss}
                height={4}
                width={200}
              />
            </div>

            {pet.stage !== "Egg" && pet.sleeping && !showNurturingLoading && (
              <button
                type="button"
                className="crate-card-wake-button"
                onClick={handleAwakePet}
              >
                <div className="crate-card-waka-button-bg" />
                <img
                  src={WakeIcon}
                  alt="wake"
                  className="crate-card-wake-button-image"
                />
                <span className="crate-card-wake-button-text">WAKE</span>
              </button>
            )}

            {!pet.sleeping && pet.stage !== "Egg" && !showNurturingLoading && (
              <div className="crate-card-buttons">
                <button
                  type="button"
                  className="crate-card-normal-button"
                  onClick={handleFeedPet}
                >
                  <div className="crate-card-normal-button-bg">
                    <div className="crate-card-normal-button-bg-mask" />
                  </div>
                  <img
                    src={FeedIcon}
                    alt="feed"
                    className="crate-card-normal-button-image"
                  />
                  <span className="crate-card-normal-button-text">FEED</span>
                </button>
                <button
                  type="button"
                  className="crate-card-normal-button"
                  onClick={handleSleepPet}
                >
                  <div className="crate-card-normal-button-bg">
                    <div className="crate-card-normal-button-bg-mask" />
                  </div>
                  <img
                    src={SleepIcon}
                    alt="sleep"
                    className="crate-card-normal-button-image"
                  />
                  <span className="crate-card-normal-button-text">SLEEP</span>
                </button>
                <button
                  type="button"
                  className="crate-card-normal-button"
                  onClick={handleShowerPet}
                >
                  <div className="crate-card-normal-button-bg">
                    <div className="crate-card-normal-button-bg-mask" />
                  </div>
                  <img
                    src={ShowerIcon}
                    alt="shower"
                    className="crate-card-normal-button-image"
                  />
                  <span className="crate-card-normal-button-text">SHOWER</span>
                </button>
                <button
                  type="button"
                  className="crate-card-normal-button"
                  onClick={handleExercisePet}
                >
                  <div className="crate-card-normal-button-bg">
                    <div className="crate-card-normal-button-bg-mask" />
                  </div>
                  <img
                    src={PlayIcon}
                    alt="play"
                    className="crate-card-normal-button-image"
                  />
                  <span className="crate-card-normal-button-text">PLAY</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </StarchiBox>

      <ElementsListModal
        option="ATTACH_ELEMENT"
        pet={pet}
        modalOpen={showAvailableElementsModal}
        setShowModal={setShowAvailableElementsModal}
        onClose={() => {
          setShowAvailableElementsModal(false);
        }}
      />

      <ElementsListModal
        option="DETACH_ELEMENT"
        pet={pet}
        modalOpen={showPetElementsModal}
        onClose={() => {
          setShowPetElementsModal(false);
        }}
      />

      <ApproveTokenModal
        modalHeader={`Allow access to your 
        ${
          approveTokenOption === "NURTURING_TO_ELXR" ? "ELXR" : "BUIDL"
        } tokens`}
        modalOpen={showApproveTokenModal}
        approveOption={approveTokenOption}
        onApproved={(result) => setIsSelectedTokenApproved(result)}
        onClose={() => setShowApproveTokenModal(false)}
      />

      <NurturingInfoModal
        modalHeader={nurturingInfoHeader}
        modalOpen={showNurturingInfoModal}
        onConfirm={() => {
          setIsNurturingActionApproved(true);
          setShowNurturingInfoModal(false);
        }}
        onClose={() => {
          setIsNurturingActionApproved(false);
          setShowNurturingInfoModal(false);
          setShowNurturingLoading(false);
        }}
      />

      <NameChangeModal
        modalOpen={isNameChangeModal}
        onConfirm={(newName) => {
          onChangeNameConfirm(newName);
        }}
        onClose={() => {
          showNameChangeModal(false);
        }}
      />
    </>
  );
};

export default CrateCard;
