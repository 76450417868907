import { useCallback, useEffect, useState } from "react";

import ApproveTokenModal from "../../../components/ApproveTokenModal";

import NurturingInfoModal from "./NurturingInfoModal";
import ElementsListModal from "./ElementsListModal";
import NameChangeModal from "./NameChangeModal";

import { toastError, toastSuccess } from "../../../utils/errorHandlers";
import { usePets } from "../../../hooks/usePets";
import { useContractsParams } from "../../../contexts/ContractsParamsContext";

import { useCrate } from "../contexts/CrateContext";
import { useNurturing } from "../../../hooks/useNurturing";

import "./styles.scss";
import formatSecondsToDuration from "../../../utils/formatSecondsToDuration";

const CrateCardV2 = ({ pet, currentPetAction, handleUpdateActionState }) => {
  const [canEvolve, setCanEvolve] = useState(false);
  const [isEvolving, setIsEvolving] = useState(false);

  const [nurturingInfoHeader, setNurturingInfoHeader] = useState("");
  const [showNurturingInfoModal, setShowNurturingInfoModal] = useState(false);
  const [isNurturingActionApproved, setIsNurturingActionApproved] =
    useState(false);

  const [showApproveTokenModal, setShowApproveTokenModal] = useState(false);
  const [currentNurturingAction, setCurrentNurturingAction] = useState("");
  const [approveTokenOption, setApproveTokenOption] = useState("");
  const [isSelectedTokenApproved, setIsSelectedTokenApproved] = useState(false);
  const [isNameChangeModal, showNameChangeModal] = useState(false);

  const [showAvailableElementsModal, setShowAvailableElementsModal] =
    useState(false);
  const [showPetElementsModal, setShowPetElementsModal] = useState(false);
  const [actionState, setActionState] = useState({
    state: undefined,
    success: false,
  });

  const {
    evolvePetById,
    onAwake,
    onSleep,
    onExercise,
    onFeed,
    onShower,
    watchPetEvolution,
    updateStarchiName,
  } = useCrate();
  const {
    approvedTokens,
    hungerSecondsTimeout,
    showerSecondsTimeout,
    exerciseSecondsTimeout,
    sleepSecondsTimeout,
    wakeSecondsTimeout,
  } = useContractsParams();
  const { getIsPetEvolving } = usePets();

  const { fetchFeedPrice, fetchShowerPrice } = useNurturing();

  useEffect(() => {
    switch (currentPetAction.actionId) {
      case "feed":
        handleFeedPet(currentPetAction.petId);
        break;
      case "shower":
        handleShowerPet(currentPetAction.petId);
        break;
      case "sleep":
        handleSleepPet(currentPetAction.petId);
        break;
      case "wake":
        handleAwakePet(currentPetAction.petId);
        break;
      case "play":
        handleExercisePet(currentPetAction.petId);
        break;
      case "requestEvolution":
        if (currentPetAction.petId === pet.petId) {
          evolvePet();
        }
        break;
      case "hatch":
        if (currentPetAction.petId === pet.petId) {
          evolvePet();
        }
        break;
      case "attachElement":
        if (currentPetAction.petId === pet.petId) {
          setShowAvailableElementsModal(true);
        }
        break;
      case "viewElements":
        if (currentPetAction.petId === pet.petId) {
          setShowPetElementsModal(true);
        }
        break;
      case "editName":
        if (currentPetAction.petId === pet.petId) {
          showNameChangeModal(true);
        }
        break;
      default:
        break;
    }
  }, [currentPetAction]);

  useEffect(() => {
    // Is triggered when the token was not approved previously & approve modal is closed with the user's approval
    if (!showApproveTokenModal && isSelectedTokenApproved) {
      setShowNurturingInfoModal(true);
      setIsSelectedTokenApproved(false);
    }
  }, [showApproveTokenModal, isSelectedTokenApproved]);

  useEffect(() => {
    if (actionState.state !== undefined) {
      handleUpdateActionState(
        currentPetAction.actionId,
        pet,
        !actionState.success,
        currentPetAction.actionId,
      );

      setActionState({ state: undefined, success: false });
    }
  }, [pet, handleUpdateActionState, currentPetAction, actionState]);

  useEffect(() => {
    const startNurturing = async () => {
      let success;
      switch (currentNurturingAction) {
        case "AWAKE":
          success = await onAwake(pet.petId);
          break;
        case "SHOWER":
          success = await onShower(pet.petId);
          break;
        case "EXERCISE":
          success = await onExercise(pet.petId);
          break;
        case "SLEEP":
          success = await onSleep(pet.petId);
          break;
        case "FEED":
          success = await onFeed(pet.petId);
          break;
        default:
          break;
      }

      setIsNurturingActionApproved(false);
      setCurrentNurturingAction("");
      setActionState({ state: !actionState.state, success });
    };

    // Is triggered when the nurturing action is approved by the user and NurturingInfoModal is closed
    if (
      !showNurturingInfoModal &&
      currentNurturingAction !== "" &&
      isNurturingActionApproved
    ) {
      startNurturing();
    }
  }, [
    pet.petId,
    showNurturingInfoModal,
    currentNurturingAction,
    isNurturingActionApproved,
    onAwake,
    onShower,
    onExercise,
    onSleep,
    onFeed,
    actionState,
  ]);

  const evolvePet = useCallback(async () => {
    try {
      setIsEvolving(true);
      await evolvePetById(pet.petId);
      setActionState({ state: !actionState.state, success: true });
    } catch (error) {
      setIsEvolving(false);
      console.error("Fail to evolve pet", error);
      toastError(error);
      setActionState({ state: !actionState.state, success: false });
    }
  }, [pet.petId, evolvePetById, actionState.state]);

  const handleAwakePet = useCallback(
    (currentPetId) => {
      if (currentPetId !== pet.petId) {
        return undefined;
      }

      setCurrentNurturingAction("AWAKE");

      const formattedSleepToHours =
        formatSecondsToDuration(sleepSecondsTimeout);

      setNurturingInfoHeader(
        `Are you sure you want to wake up your Starchi? Your Starchi will not be able to sleep again for the next ${formattedSleepToHours}.`,
      );
      setShowNurturingInfoModal(true);
    },
    [pet, approvedTokens, onAwake, sleepSecondsTimeout],
  );

  const handleShowerPet = useCallback(
    async (currentPetId) => {
      if (currentPetId !== pet.petId) {
        return undefined;
      }

      setCurrentNurturingAction("SHOWER");

      const formattedShowerToHours =
        formatSecondsToDuration(showerSecondsTimeout);
      const showerCost = await fetchShowerPrice(pet.petId);

      setNurturingInfoHeader(
        `Are you sure you want to shower ${pet.name} for ${showerCost} ELXR? Your Starchi will not be able to be showered again for the next ${formattedShowerToHours}.`,
      );

      if (approvedTokens.nurturingIsApprovedToElxrTokens) {
        setShowNurturingInfoModal(true);
      } else {
        setApproveTokenOption("NURTURING_TO_ELXR");
        setShowApproveTokenModal(true);
      }
    },
    [pet, approvedTokens, onShower, showerSecondsTimeout],
  );

  const handleExercisePet = useCallback(
    (currentPetId) => {
      if (currentPetId !== pet.petId) {
        return undefined;
      }

      setCurrentNurturingAction("EXERCISE");

      const formattedExerciseToHours = formatSecondsToDuration(
        exerciseSecondsTimeout,
      );

      setNurturingInfoHeader(
        `Are you sure you want to exercise ${pet.name}? Your Starchi will not be able to exercise again for the next ${formattedExerciseToHours}.`,
      );
      setShowNurturingInfoModal(true);
    },
    [pet, approvedTokens, onExercise, exerciseSecondsTimeout],
  );

  const handleSleepPet = useCallback(
    (currentPetId) => {
      if (currentPetId !== pet.petId) {
        return undefined;
      }

      setCurrentNurturingAction("SLEEP");

      const formattedWakeToHours = formatSecondsToDuration(wakeSecondsTimeout);

      setNurturingInfoHeader(
        `Are you sure you want to put your Starchi to sleep? Your Starchi will not be able to wake up the next ${formattedWakeToHours}.`,
      );
      setShowNurturingInfoModal(true);
    },
    [pet, approvedTokens, onSleep, wakeSecondsTimeout],
  );

  const handleFeedPet = useCallback(
    async (currentPetId) => {
      if (currentPetId !== pet.petId) {
        return undefined;
      }

      setCurrentNurturingAction("FEED");

      const formattedFeedToHours =
        formatSecondsToDuration(hungerSecondsTimeout);
      const feedCost = await fetchFeedPrice(pet.petId);

      setNurturingInfoHeader(
        `Are you sure you want to feed ${pet.name} for ${feedCost} ELXR? Your Starchi will not be able to be fed again for the next ${formattedFeedToHours}.`,
      );

      if (approvedTokens.nurturingIsApprovedToElxrTokens) {
        setShowNurturingInfoModal(true);
      } else {
        setApproveTokenOption("NURTURING_TO_ELXR");
        setShowApproveTokenModal(true);
      }
    },
    [pet, approvedTokens, onFeed, hungerSecondsTimeout],
  );

  const onChangeNameConfirm = useCallback(
    async (newName) => {
      console.log("Change Name Confirm", newName);
      let success = false;

      try {
        await updateStarchiName(pet.petId, newName);
        toastSuccess("Name changed successfully");
        success = true;
      } catch (err) {
        toastError(err);
      }

      showNameChangeModal(false);
      setActionState({ state: !actionState.state, success });
    },
    [pet.petId, updateStarchiName],
  );

  return (
    <>
      <ElementsListModal
        option="ATTACH_ELEMENT"
        pet={pet}
        modalOpen={showAvailableElementsModal}
        setShowModal={setShowAvailableElementsModal}
        onClose={() => {
          setShowAvailableElementsModal(false);
          setActionState({ state: !actionState.state, success: false });
        }}
      />

      <ElementsListModal
        option="DETACH_ELEMENT"
        pet={pet}
        modalOpen={showPetElementsModal}
        onClose={() => {
          setShowPetElementsModal(false);
          setActionState({ state: !actionState.state, success: false });
        }}
      />

      <ApproveTokenModal
        modalHeader={`Allow access to your 
        ${
          approveTokenOption === "NURTURING_TO_ELXR" ? "ELXR" : "BUIDL"
        } tokens`}
        modalOpen={showApproveTokenModal}
        approveOption={approveTokenOption}
        onApproved={(result) => setIsSelectedTokenApproved(result)}
        onClose={() => {
          setShowApproveTokenModal(false);
          setActionState({ state: !actionState.state, success: false });
        }}
      />

      <NurturingInfoModal
        modalHeader={nurturingInfoHeader}
        modalOpen={showNurturingInfoModal}
        onConfirm={() => {
          setIsNurturingActionApproved(true);
          setShowNurturingInfoModal(false);
        }}
        onClose={() => {
          setIsNurturingActionApproved(false);
          setShowNurturingInfoModal(false);
          setActionState({ state: !actionState.state, success: false });
        }}
      />

      <NameChangeModal
        modalOpen={isNameChangeModal}
        onConfirm={(newName) => {
          onChangeNameConfirm(newName);
        }}
        onClose={() => {
          showNameChangeModal(false);
          setActionState({ state: !actionState.state, success: false });
        }}
      />
    </>
  );
};

export default CrateCardV2;
