import { useHistory } from "react-router";

import CloseIcon from "@material-ui/icons/Close";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import {
  GiClover,
  GiFist,
  GiMagicPortal,
  GiWingedScepter,
} from "react-icons/gi";
import { RiSwordLine } from "react-icons/ri";
import { FaWind } from "react-icons/fa";
import { ImHeart } from "react-icons/im";
import { BsLightningChargeFill } from "react-icons/bs";

import { EFFECTS } from "../../../../constants/constants";
import Button from "../../../../components/Button";
import StatValue from "../../../../components/StatValue";
import NameBadge from "../../../../components/StarchiNameBadge";

import { ContractTypes, useStore } from "../../contexts/StoreContext";
// import "../styles.scss";

const override = css`
  display: block;
  margin: auto;
`;

interface SellNFTModalProps {
  modalOpen: boolean;
  onClose(): void;
}

const SellNFTModal: React.FC<SellNFTModalProps> = ({ modalOpen, onClose }) => {
  const { myAvailableTokensToSell, isLoadingListedTokens } = useStore();

  const history = useHistory();

  return (
    <Modal
      show={modalOpen}
      size="lg"
      backdrop="static"
      centered
      contentClassName="quick-battle-modal-content"
      onHide={onClose}
    >
      <div className="quick-battle-modal-bg" />
      <ModalHeader bsPrefix="quick-battle-modal-header">
        <span className="quick-battle-modal-heading-left">Sell your NFTs</span>

        <CloseIcon
          className="quick-battle-modal-close-icon"
          onClick={onClose}
        />
      </ModalHeader>
      <ModalBody bsPrefix="quick-battle-modal-body">
        {isLoadingListedTokens ? (
          <ClipLoader
            color="#ffffff"
            loading={isLoadingListedTokens}
            css={override}
            size={100}
          />
        ) : myAvailableTokensToSell?.length === 0 ? (
          <span className="quick-battle-no-element-alert">
            No Tokens available to sell
          </span>
        ) : (
          myAvailableTokensToSell?.map((item) => (
            <div key={item.name} className="quick-battle-starchi-item">
              <div className="quick-battle-starchi-item-bg" />
              <div className="quick-battle-starchi-item-inner">
                <div className="d-flex w-100">
                  {item.contractType === ContractTypes.Elements ? (
                    <NameBadge
                      className="w-50 quick-battle-starchi-badge"
                      elementId={String(item.typeId)}
                      name={item.name}
                      id={String(item.tokenId)}
                    />
                  ) : (
                    item.contractType === ContractTypes.Pets && (
                      <NameBadge
                        className="w-50 quick-battle-starchi-badge"
                        petBreedId={String(item?.breedId)}
                        name={item.name}
                        id={String(item.tokenId)}
                      />
                    )
                  )}
                  <div className="quick-battle-stage w-50 mb-1">
                    {item.contractType === ContractTypes.Elements ? (
                      <>
                        <span className="quick-battle-stage-label">
                          Level:{" "}
                        </span>
                        <span className="quick-battle-stage-value">
                          {item?.level}
                        </span>
                      </>
                    ) : (
                      item.contractType === ContractTypes.Pets && (
                        <>
                          <span className="quick-battle-stage-label">
                            Stage:{" "}
                          </span>
                          <span className="quick-battle-stage-value">
                            {item?.stage}
                          </span>
                        </>
                      )
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center w-100">
                  <div className="quick-battle-starchi-left">
                    <div className="quick-battle-image-container">
                      <img
                        src={item.image || undefined}
                        alt="Starchi"
                        className="crate-card-image"
                      />
                    </div>
                  </div>
                  <div className="quick-battle-starchi-right">
                    <div className="quick-battle-top-info">
                      {item.contractType === ContractTypes.Pets && (
                        <div className="mb-3">
                          <span className="quick-battle-stage-label">
                            Attached Elements:{" "}
                          </span>

                          {item.elements && item.elements?.length > 0 ? (
                            item.elements?.map((element, index) => (
                              <span
                                key={element.tokenId}
                                className="quick-battle-stage-value"
                              >
                                {element.name}
                                {item.elements?.length !== index + 1 && ", "}
                              </span>
                            ))
                          ) : (
                            <span className="quick-battle-stage-value">--</span>
                          )}
                        </div>
                      )}

                      <div className="quick-battle-stats-container">
                        {item.contractType === ContractTypes.Elements ? (
                          <>
                            <StatValue
                              IconComponent={<GiFist />}
                              backgroundColor="#e92222"
                              title="PowerRatio"
                              value={item?.powerRatio || 0}
                            />
                            <StatValue
                              IconComponent={<BsLightningChargeFill />}
                              backgroundColor="#7b21fc"
                              title="Energy"
                              value={item?.energy || 0}
                            />
                            <StatValue
                              IconComponent={
                                item?.damageType === 0 ? (
                                  <RiSwordLine />
                                ) : (
                                  <GiWingedScepter />
                                )
                              }
                              backgroundColor="#2a22e9"
                              title="DamageType"
                              value={
                                item?.damageType === 0 ? "Physic" : "Magic"
                              }
                            />
                            {item.effect !== 0 && item?.effect && (
                              <StatValue
                                IconComponent={<GiMagicPortal />}
                                backgroundColor="#18545a"
                                title="Effect"
                                value={`${EFFECTS[item.effect]?.name}`}
                              />
                            )}
                          </>
                        ) : (
                          item.contractType === ContractTypes.Pets && (
                            <>
                              <StatValue
                                IconComponent={<ImHeart />}
                                backgroundColor="#e12939"
                                title="HP"
                                currentValue={item?.hp || 0}
                                value={item?.hp || 0}
                              />
                              <StatValue
                                IconComponent={<BsLightningChargeFill />}
                                backgroundColor="#7b21fc"
                                title="Energy"
                                currentValue={item?.energy || 0}
                                value={item?.energy || 0}
                              />
                              <StatValue
                                IconComponent={<GiClover />}
                                backgroundColor="#16b200"
                                title="Luckiness"
                                currentValue={item?.luckiness || 0}
                                value={item?.luckiness || 0}
                              />
                              <StatValue
                                IconComponent={<FaWind />}
                                backgroundColor="#cec505"
                                title="Speed"
                                currentValue={item?.speed || 0}
                                value={item?.speed || 0}
                              />
                            </>
                          )
                        )}
                      </div>
                    </div>
                    <Button
                      size="lg"
                      text="Sell NFT"
                      onClick={() => {
                        history.push(
                          `/store/sell/${item.tokenId}?contractType=${item.contractType}`,
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </ModalBody>
    </Modal>
  );
};

export default SellNFTModal;
