import { useCallback, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Button from "../Button";
import { blockchainAddresses } from "../../config";
import { toastError } from "../../utils/errorHandlers";
import { useContractsParams } from "../../contexts/ContractsParamsContext";

export type ApproveOption =
  | "PET_TO_BUIDL"
  | "NURTURING_TO_ELXR"
  | "BATTLE_TO_BUIDL";

interface ApproveTokenModalProps {
  modalOpen: boolean;
  modalHeader: string;
  onClose(): void;
  onApproved?(result: boolean): void;
  approveOption: ApproveOption;
}

const ApproveTokenModal: React.FC<ApproveTokenModalProps> = ({
  modalOpen,
  onClose,
  onApproved,
  approveOption,
  modalHeader,
}) => {
  const [isApprovingToken, setIsApprovingToken] = useState(false);

  const { approveToken } = useContractsParams();

  const getTokenAndSpender = useCallback(() => {
    switch (approveOption) {
      case "PET_TO_BUIDL":
        return [blockchainAddresses.buidlToken, blockchainAddresses.pets];
      case "NURTURING_TO_ELXR":
        return [blockchainAddresses.elxrToken, blockchainAddresses.nurturing];
      case "BATTLE_TO_BUIDL":
        return [blockchainAddresses.buidlToken, blockchainAddresses.battle];
      default:
        throw new Error("Invalid Approve Option");
    }
  }, [approveOption]);

  const handleApproveClick = useCallback(async () => {
    setIsApprovingToken(true);

    let hasApproved = false;
    try {
      const [token, spender] = getTokenAndSpender();
      await approveToken(token, spender);

      hasApproved = true;
    } catch (error) {
      console.error(error);
      toastError(error);
    }

    if (onApproved) {
      onApproved(hasApproved);
    }

    setIsApprovingToken(false);
    onClose();
  }, [approveToken, onApproved, onClose, getTokenAndSpender]);

  const handleCloseModal = useCallback(() => {
    if (onApproved) {
      onApproved(false);
    }
    onClose();
  }, [onApproved, onClose]);

  return (
    <Modal
      show={modalOpen}
      size="lg"
      centered
      contentClassName="manage-modal-content"
      onHide={handleCloseModal}
    >
      <div className="manage-modal-bg" />
      <ModalHeader bsPrefix="manage-modal-header">
        <span className="manage-modal-heading-lg">
          {!isApprovingToken && <div>{modalHeader}</div>}
          {isApprovingToken && <div>APPROVING TOKEN...</div>}
        </span>
        {!isApprovingToken && (
          <CloseIcon
            className="manage-modal-close-icon"
            onClick={handleCloseModal}
          />
        )}
      </ModalHeader>
      <ModalBody bsPrefix="name-change-modal-body">
        {isApprovingToken ? (
          <ClipLoader color="#ffffff" loading={isApprovingToken} size={60} />
        ) : (
          <div className="name-change-modal-confirm">
            <Button
              disabled={false}
              text={isApprovingToken ? "loading..." : "APPROVE IT"}
              onClick={handleApproveClick}
            />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ApproveTokenModal;
