import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import "./styles.scss";

const Header: React.FC = () => {
  const [isHomePage, setIsHomePage] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname === "/") {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  }, [location]);

  return (
    <div className={`HeaderContainer ${isHomePage && "green-bg"}`}>
      <Navbar />
    </div>
  );
};

export default Header;
