import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import BarLoader from "react-spinners/BarLoader";

import Button from "../../../../components/Button";
import "./styles.scss";

interface BattleResultProps {
  modalOpen: boolean;
  isFinishingBattle: boolean;
  result: boolean;
  onClose(): void;
}

const BattleResult: React.FC<BattleResultProps> = ({
  modalOpen,
  isFinishingBattle,
  result,
  onClose,
}) => {
  return (
    <Modal
      show={modalOpen}
      size="lg"
      centered
      contentClassName="battle-result-modal-content"
      onHide={() => {
        if (!isFinishingBattle) {
          onClose();
        }
      }}
    >
      <div className="battle-result-modal-bg" />
      <ModalHeader bsPrefix="battle-result-modal-header">
        <span className="battle-result-modal-heading-center">
          {result ? "YOU WON" : "YOU LOST"}
        </span>
        {!isFinishingBattle && (
          <CloseIcon
            className="battle-result-modal-close-icon"
            onClick={onClose}
          />
        )}
      </ModalHeader>
      {isFinishingBattle ? (
        <>
          <ModalBody bsPrefix="battle-result-modal-body">
            <div className="battle-result-modal-header">
              <span className="battle-result-modal-body-text-result">
                {isFinishingBattle ? "FINISHING BATTLE..." : "BATTLE FINISHED"}
              </span>
            </div>
          </ModalBody>
          <div className="spinner-center align-spinner-center">
            <BarLoader color="#36D7B7" loading height={4} width={300} />
          </div>
        </>
      ) : (
        <ModalBody bsPrefix="battle-result-modal-body">
          <div className="battle-result-modal-header">
            <span className="battle-result-modal-body-text-result">
              {isFinishingBattle ? "FINISHING BATTLE..." : "BATTLE FINISHED"}
            </span>
          </div>
          <div className="battle-result-button-body">
            <Button size="lg" text="OK" onClick={onClose} />
          </div>
        </ModalBody>
      )}
    </Modal>
  );
};

export default BattleResult;
