import { useState } from "react";
import bigInt from "big-integer";
import "./VestingBoard.scss";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useVesting } from "../../../hooks/useVesting";
import { useAccount } from "../../../contexts/AccountContext";
import { usePools } from "../../../hooks/usePools";

import StarchiBox from "../../../components/Starchibox";
import Button from "../../../components/Button";
import WalletModal from "../../../components/WalletModal";
import { toFixed, SHORT_ADDRESS } from "../../../constants/constants";
import { toastError } from "../../../utils/errorHandlers";

const VestingBoard = () => {
  const { account } = useAccount();
  const { items: pools, isLoading: isPoolsLoading } = usePools();
  const { vestings, unstake, isLoading } = useVesting(pools);
  const [walletModal, showWalletModal] = useState(false);
  const [isWaitingTrx, setIsWaitingTrx] = useState(false);

  const onWithdraw = async (poolAddress, depositId, amount) => {
    console.log("onWithdraw", depositId);
    setIsWaitingTrx(true);
    try {
      await unstake(
        pools[poolAddress],
        depositId,
        bigInt(amount).subtract(1).toString(),
      );
    } catch (error) {
      console.error("UNSTAKE ERROR", error);
      toastError(error);
    }
    setIsWaitingTrx(false);
  };

  const getUnlocksTime = (timestamp) => {
    const currentTimestamp = parseInt(Date.now() / 1000);
    if (timestamp === 0 || timestamp < currentTimestamp) {
      return "Unlocked";
    }
    const period = timestamp - currentTimestamp;

    if (period < 60) {
      return `Unlocks in ${period} seconds`;
    }
    if (period < 3600) {
      return `Unlocks in ${parseInt(period / 60)} minutes`;
    }
    if (period < 3600 * 24) {
      return `Unlocks in ${parseInt(period / 3600)} hours`;
    }
    if (period < 86400 * 30) {
      return `Unlocks in ${parseInt(period / 86400)} days`;
    }
    return `Unlocks in ${parseInt(period / 2592000)} months`;
  };

  const isUnlocked = (timestamp) => {
    const currentTimestamp = parseInt(Date.now() / 1000);
    if (timestamp === 0 || timestamp < currentTimestamp) {
      return true;
    }
    return false;
  };

  const onConnect = () => {
    showWalletModal(true);
  };

  return (
    <div className="vesting-board-container">
      <div className="vesting-board-title-container">
        <div className="vesting-board-title-left">
          <h1 className="vesting-board-heading">VESTING</h1>
          <span className="vesting-board-sub-heading">
            Vested rewards and unlocked deposits can be withdrawn{" "}
            <span className="vesting-board-sub-bold">at any time</span>.
          </span>
        </div>
        <div className="vesting-board-title-right">
          {account && account.address && (
            <div
              className="vesting-board-address"
              onClick={() => {
                showWalletModal(true);
              }}
              aria-hidden="true"
            >
              {SHORT_ADDRESS(account.address)}
            </div>
          )}
        </div>
      </div>
      {isLoading || (isPoolsLoading && Object.values(pools).length === 0) ? (
        <div className="vesting-board-loader">
          <LinearProgress />
        </div>
      ) : (
        <div className="vesting-board-list">
          <StarchiBox horizontal={false} styles={{ padding: "30px 40px" }}>
            {account && account.address ? (
              <table className="vesting-board-table">
                <tr className="vesting-board-table-header">
                  <th
                    className="vesting-board-table-th"
                    style={{ width: "30%", textAlign: "left" }}
                  >
                    Pools
                  </th>
                  <th
                    className="vesting-board-table-th"
                    style={{ width: "20%", textAlign: "center" }}
                  >
                    Amount
                  </th>
                  <th
                    className="vesting-board-table-th"
                    style={{ width: "20%", textAlign: "center" }}
                  >
                    Unlocks in
                  </th>
                  <th
                    className="vesting-board-table-th"
                    style={{ width: "30%" }}
                  >
                    Source
                  </th>
                  <th
                    className="vesting-board-table-th"
                    style={{ width: "30%" }}
                  />
                </tr>
                {Object.keys(vestings).map((poolAddress) => {
                  const pool = pools[poolAddress];
                  return vestings[poolAddress]
                    .filter((vesting) => {
                      return bigInt(vesting.tokenAmount).greater("0");
                    })
                    .map((vesting) => (
                      <tr
                        className="vesting-board-table-row"
                        key={`${poolAddress}-${vesting.depositId}`}
                      >
                        <td
                          className="vesting-board-table-td"
                          style={{ textAlign: "left" }}
                        >
                          <div className="vesting-board-table-img-symbol">
                            <img
                              src={`/tokens/${pool?.token?.address}.png`}
                              alt="img"
                              className="vesting-board-table-td-img"
                            />
                            <span className="vesting-board-table-td-text">
                              {pool?.token?.symbol}
                            </span>
                          </div>
                        </td>
                        <td className="vesting-board-table-td">
                          {toFixed(
                            vesting?.tokenAmount,
                            pool?.token?.decimals || 18,
                            2,
                          ) || "0"}
                        </td>
                        <td className="vesting-board-table-td">
                          <span className="vesting-board-time">
                            {getUnlocksTime(parseInt(vesting?.lockedUntil))}
                          </span>
                        </td>
                        <td className="vesting-board-table-td">
                          {vesting?.isYield ? "Yield" : "Deposit"}
                        </td>
                        <td className="vesting-board-table-td">
                          <div className="vesting-board-buttons-container">
                            {isWaitingTrx ? (
                              <Button
                                disabled
                                text="PLEASE WAIT..."
                                size="md"
                                onClick={() => {
                                  // TODO: Add wait function
                                }}
                                aria-hidden="true"
                              />
                            ) : isUnlocked(parseInt(vesting?.lockedUntil)) ? (
                              <Button
                                text="UNSTAKE"
                                size="md"
                                onClick={() => {
                                  console.log(
                                    "vesting?.tokenAmount",
                                    vesting?.tokenAmount,
                                  );
                                  onWithdraw(
                                    poolAddress,
                                    vesting?.depositId,
                                    vesting?.tokenAmount,
                                  );
                                }}
                                aria-hidden="true"
                              />
                            ) : (
                              <Button
                                disabled
                                text="LOCKED"
                                size="md"
                                onClick={() => {
                                  // TODO: Add locked function
                                }}
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ));
                })}
              </table>
            ) : (
              <div className="vesting-board-connect">
                <div className="vesting-board-connect-text">
                  Connect your wallet to see available pools
                </div>
                <div className="vesting-board-connect-button">
                  <Button
                    text="CONNECT WALLET"
                    size="md"
                    onClick={() => {
                      onConnect();
                    }}
                    aria-hidden="true"
                  />
                </div>
              </div>
            )}
          </StarchiBox>
        </div>
      )}
      <WalletModal
        modalOpen={walletModal}
        onClose={() => {
          showWalletModal(false);
        }}
      />
    </div>
  );
};

export default VestingBoard;
