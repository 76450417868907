import { css } from "@emotion/react";
import ClockLoader from "react-spinners/ClockLoader";

import "../styles.scss";

const clockLoaderCss = css`
  display: block;
`;

interface ProgressBarProps {
  remainTime: number;
}

const loaderColor = "#36D7B7";

const labelStyles = {
  padding: 5,
  color: "white",
  fontWeight: "bold",
  margin: 0,
};

const ProgressBar: React.FC<ProgressBarProps> = ({ remainTime }) => (
  <div className="clock-progress">
    <ClockLoader color={loaderColor} loading css={clockLoaderCss} size={30} />
    <span style={labelStyles}>{`Next Stage: ${new Date(remainTime * 1000)
      .toISOString()
      .substr(11, 8)}`}</span>
  </div>
);

export default ProgressBar;
