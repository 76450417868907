import axios from "axios";

export const api = axios.create({
  timeout: 20000,
});

export const defineInterceptor = () => {
  api.interceptors.response.use(
    (res) => res,
    (err) => {
      console.error("HTTP Request Error", err?.response?.data?.data);
      return Promise.reject(err);
    },
  );
};
