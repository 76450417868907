import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import BarLoader from "react-spinners/BarLoader";
import { BsLightningChargeFill } from "react-icons/bs";
import { GiFist, GiMagicPortal, GiWingedScepter } from "react-icons/gi";
import { RiSwordLine } from "react-icons/ri";
import { MdPets } from "react-icons/md";
import StarchiBox from "../../../../components/Starchibox";
import Button from "../../../../components/Button";
import StatValue from "../../../../components/StatValue";

import { toastError } from "../../../../utils/errorHandlers";
import { useContractsParams } from "../../../../contexts/ContractsParamsContext";
import CardTier, { CardTiers } from "../CardTier";

import "../styles.scss";
import { PetWithDetailsProps } from "../../../../api/pets";
import { ElementWithDetailsProps } from "../../../../api/elements";
import { EFFECTS } from "../../../../constants/constants";

const barLoaderCss = css`
  display: block;
  margin-top: 5px;
`;

interface ElementCardProps {
  isAttachElement?: boolean;
  showAttachedTo?: boolean;
  myPets?: PetWithDetailsProps[];
  element: ElementWithDetailsProps;
  onClose(): void;
  onClick(elementId: number): void;
  showButton?: boolean;
}

const ElementCard: React.FC<ElementCardProps> = ({
  isAttachElement = true,
  showAttachedTo = false,
  myPets = [],
  element,
  onClick,
  showButton = true,
}) => {
  const loaderColor = "#36D7B7";
  const [isLoading, setIsLoading] = useState(false);
  const [attachedPetName, setAttachedPetName] = useState("");
  const [attachedPetId, setAttachedPetId] = useState(0);
  const { detachElementPrice } = useContractsParams();

  useEffect(() => {
    if (showAttachedTo && element.attachedPetId !== 0) {
      const petName = myPets.find(
        (pet) => pet.petId === element.attachedPetId,
      )?.name;

      setAttachedPetName(petName || "");
      setAttachedPetId(element.attachedPetId);
    }
  }, [showAttachedTo, myPets, element.attachedPetId]);

  const onButtonClick = async () => {
    setIsLoading(true);
    try {
      await onClick(element.elementId);

      setIsLoading(false);
    } catch (error) {
      console.error("Fail to attach/detach element", error);
      toastError(error);
      setIsLoading(false);
    }
  };

  return (
    <StarchiBox styles={{ padding: "30px 40px" }}>
      <div className="crate-card-container">
        <div className="crate-card-main-info">
          <div className="crate-card-left">
            <div className="crate-card-image-container">
              <img
                src={element.image || undefined}
                alt="element"
                className="crate-card-element-gif"
              />
            </div>
          </div>
          <div className="crate-card-right">
            <div className="crate-card-element-stats-container">
              <CardTier value={String(element.level || 1) as CardTiers} />

              <StatValue
                IconComponent={<GiFist />}
                backgroundColor="#e92222"
                title="PowerRatio"
                value={element.powerRatio.toNumber() || 0}
              />
              <StatValue
                IconComponent={<BsLightningChargeFill />}
                backgroundColor="#7b21fc"
                title="Energy"
                value={element.energy || 0}
              />
              <StatValue
                IconComponent={
                  element.damageType === 0 ? (
                    <RiSwordLine />
                  ) : (
                    <GiWingedScepter />
                  )
                }
                backgroundColor="#2a22e9"
                title="DamageType"
                value={element.damageType === 0 ? "Physic" : "Magic"}
              />
              {element.effect !== 0 && (
                <StatValue
                  IconComponent={<GiMagicPortal />}
                  backgroundColor="#18545a"
                  title="Effect"
                  value={`${EFFECTS[element.effect]?.name}`}
                />
              )}
              {attachedPetName && (
                <StatValue
                  IconComponent={<MdPets />}
                  backgroundColor="#18545a"
                  title="Attached To"
                  value={`${attachedPetName} #${attachedPetId}`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {!isLoading && showButton && (
        <Button
          text={
            isAttachElement
              ? "Attach Element"
              : `Detach Element (${detachElementPrice} BUIDL)`
          }
          size="md"
          onClick={onButtonClick}
        />
      )}
      <div className="spinner-center">
        <BarLoader
          color={loaderColor}
          loading={isLoading}
          css={barLoaderCss}
          height={4}
          width={300}
        />
      </div>
    </StarchiBox>
  );
};

export default ElementCard;
