import "./styles.scss";

import Patners from "./components/Partners";
import ArenaHome from "./components/ArenaHome";
import PetStoreHome from "./components/PetStoreHome";
import About from "./components/About";
import Intro from "./components/Intro";

const Home: React.FC = () => {
  return (
    <div className="home-container">
      <Intro />
      <About />
      <PetStoreHome />
      <ArenaHome />
      <Patners />
    </div>
  );
};

export default Home;
