import { useCallback, useEffect, useState } from "react";
import { ContractReceipt } from "ethers";

import {
  evolvePetFinished,
  mintEggFinished,
  requestEvolution,
  requestMintEgg,
  getRequestedPetEvolution,
  changePetName,
} from "../api/chain/pets";
import { useAccount } from "../contexts/AccountContext";
import { listPetsWithDetailsByAccount, PetWithDetailsProps } from "../api/pets";

interface UsePetsProps {
  myPets: PetWithDetailsProps[];
  isLoadingMyPets: boolean;
  fetchMyPets(): Promise<PetWithDetailsProps[]>;
  getIsPetEvolving(petId: number): Promise<boolean>;
  mintEgg(petName: string): Promise<void>;
  evolvePet(petId: number): Promise<void>;
  updatePetName(petId: number, newName: string): Promise<ContractReceipt>;
  watchPetEvolving(petId: number): Promise<void>;
}

export const usePets = (): UsePetsProps => {
  const { account, provider } = useAccount();

  const [myPets, setMyPets] = useState<PetWithDetailsProps[]>([]);
  const [isLoadingMyPets, setIsLoadingMyPets] = useState(true);

  const fetchMyPets = useCallback(async () => {
    if (!provider || !account.address) {
      return [];
    }

    const pets = await listPetsWithDetailsByAccount(provider, account.address);

    if (pets) {
      setMyPets(pets);
      setIsLoadingMyPets(false);
    }

    return pets;
  }, [provider, account.address]);

  useEffect(() => {
    fetchMyPets();
  }, [fetchMyPets]);

  const watchEggMinting = useCallback(
    async (petName: string) => {
      if (account.address) {
        await mintEggFinished(provider, account.address, petName);
      }
    },
    [account.address, provider],
  );

  const mintEgg = useCallback(
    async (petName: string) => {
      await requestMintEgg(petName, provider);
      await watchEggMinting(petName);
    },
    [provider, watchEggMinting],
  );

  const watchPetEvolving = useCallback(
    (petId: number) => evolvePetFinished(provider, petId),
    [provider],
  );

  const evolvePet = useCallback(
    (petId: number) => requestEvolution(petId, provider),
    [provider],
  );

  const getIsPetEvolving = useCallback(
    (petId: number) => getRequestedPetEvolution(provider, petId),
    [provider],
  );

  const updatePetName = useCallback(
    (petId: number, newName: string) => changePetName(petId, newName, provider),
    [provider],
  );

  return {
    myPets,
    isLoadingMyPets,
    fetchMyPets,
    getIsPetEvolving,
    mintEgg,
    evolvePet,
    watchPetEvolving,
    updatePetName,
  };
};
