import "./styles.scss";

import MyCrateBoxV2 from "./components/MyCrateBoxV2";
import CrateProvider from "./contexts/CrateContext";

const CrateV2 = () => {
  return (
    <div className="crate-container">
      <CrateProvider>
        <MyCrateBoxV2 />
      </CrateProvider>
    </div>
  );
};

export default CrateV2;
