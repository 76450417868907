import { useState } from "react";
import bigInt from "big-integer";
import LinearProgress from "@material-ui/core/LinearProgress";

import "./RewardsBoard.scss";

import { usePools } from "../../../hooks/usePools";
import StarchiBox from "../../../components/Starchibox";
import Button from "../../../components/Button";
import WalletModal from "../../../components/WalletModal";
import { toFixed, SHORT_ADDRESS } from "../../../constants/constants";
import { useAccount } from "../../../contexts/AccountContext";
import { toastError } from "../../../utils/errorHandlers";

const RewardsBoard = () => {
  const { account } = useAccount();
  const { items: pools, claim, isLoading } = usePools();
  const [walletModal, showWalletModal] = useState(false);
  const [isWaitingTrx, setIsWaitingTrx] = useState(false);

  const onClaimRewards = async (pool) => {
    console.log("onClaim", pool);
    setIsWaitingTrx(true);
    try {
      await claim(pool);
    } catch (error) {
      console.error("CLAIM ERROR", error);
      toastError(error);
    }
    setIsWaitingTrx(false);
  };

  const onConnect = () => {
    showWalletModal(true);
  };

  console.log("Pools", pools);

  return (
    <div className="rewards-board-container">
      <div className="rewards-board-title-container">
        <div className="rewards-board-title-left">
          <h1 className="rewards-board-heading">REWARDS</h1>
          <span className="rewards-board-sub-heading">
            <span className="rewards-board-sub-bold">ELXR</span> rewards are
            subject up to a{" "}
            <span className="rewards-board-sub-bold">
              12 month vesting period
            </span>{" "}
            at which point they will begin compounding in the ELXR pool.
          </span>
        </div>
        <div className="rewards-board-title-right">
          {account && account.address && (
            <div
              className="rewards-board-address"
              onClick={() => {
                showWalletModal(true);
              }}
              aria-hidden="true"
            >
              {SHORT_ADDRESS(account.address)}
            </div>
          )}
        </div>
      </div>
      {isLoading && Object.values(pools).length === 0 ? (
        <div className="rewards-board-loader">
          <LinearProgress />
        </div>
      ) : (
        <div className="rewards-board-list">
          <StarchiBox horizontal={false} styles={{ padding: "30px 40px" }}>
            {account && account.address ? (
              <table className="rewards-board-table">
                <tr className="rewards-board-table-header">
                  <th
                    className="rewards-board-table-th"
                    style={{ width: "30%", textAlign: "left" }}
                  >
                    Pools
                  </th>
                  <th
                    className="rewards-board-table-th"
                    style={{ width: "20%", textAlign: "center" }}
                  >
                    Amount
                  </th>
                  <th
                    className="rewards-board-table-th"
                    style={{ width: "20%", textAlign: "center" }}
                  >
                    Total Deposits
                  </th>
                  <th
                    className="rewards-board-table-th"
                    style={{ width: "30%" }}
                  />
                </tr>
                {Object.values(pools).map((pool) => (
                  <tr
                    key={`rewards-${pool.token.address}`}
                    className="rewards-board-table-row"
                  >
                    <td
                      className="rewards-board-table-td"
                      style={{ textAlign: "left" }}
                    >
                      <div className="rewards-board-table-img-symbol">
                        <img
                          src={`/tokens/${pool.token.address}.png`}
                          alt="img"
                          className="rewards-board-table-td-img"
                        />
                        <span className="rewards-board-table-td-text">
                          {pool?.token?.symbol}
                        </span>
                      </div>
                    </td>
                    <td className="rewards-board-table-td">
                      {toFixed(pool?.pending || "0", 18, 2)}
                    </td>
                    <td className="rewards-board-table-td">
                      {pool?.depositLength || 0}
                    </td>
                    <td className="rewards-board-table-td">
                      <div className="rewards-board-buttons-container">
                        {isWaitingTrx ? (
                          <Button
                            text="PLEASE WAIT..."
                            size="md"
                            disabled
                            onClick={() => {
                              // TODO: Add wait function
                            }}
                            aria-hidden="true"
                          />
                        ) : (
                          <Button
                            text="CLAIM REWARDS"
                            size="md"
                            disabled={!bigInt(pool?.pending).greater("0")}
                            onClick={() => {
                              if (bigInt(pool?.pending).greater("0")) {
                                onClaimRewards(pool);
                              }
                            }}
                            aria-hidden="true"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            ) : (
              <div className="rewards-board-connect">
                <div className="rewards-board-connect-text">
                  Connect your wallet to see available pools
                </div>
                <div className="rewards-board-connect-button">
                  <Button
                    text="CONNECT WALLET"
                    size="md"
                    onClick={() => {
                      onConnect();
                    }}
                    aria-hidden="true"
                  />
                </div>
              </div>
            )}
          </StarchiBox>
        </div>
      )}
      <WalletModal
        modalOpen={walletModal}
        onClose={() => {
          showWalletModal(false);
        }}
      />
    </div>
  );
};

export default RewardsBoard;
