import { Switch, Route } from "react-router";

import ItemDetail from "./components/ItemDetail";
import ItemSell from "./components/ItemSell";
import ItemList from "./components/ItemList";

import "./styles.scss";
import StoreProvider from "./contexts/StoreContext";

const Store = () => {
  return (
    <div className="store-container">
      <StoreProvider>
        <Switch>
          <Route path="/store/sell/:id" component={ItemSell} />
          <Route path="/store/buy/:id" component={ItemDetail} />
          <Route path="/store" component={ItemList} />
        </Switch>
      </StoreProvider>
    </div>
  );
};

export default Store;
