import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Button from "../../../components/Button";
import {
  FORMAT_APY,
  SHORT_ADDRESS,
  toFixed,
} from "../../../constants/constants";

import "./PoolDetailsModal.scss";

const PoolDetailsModal = ({
  modalOpen,
  onClose,
  pool,
  onConfirm,
  onApprove,
}) => {
  return (
    <Modal
      show={modalOpen}
      size="lg"
      centered
      contentClassName="manage-modal-content"
      onHide={onClose}
    >
      <div className="manage-modal-bg" />
      <ModalHeader bsPrefix="manage-modal-header">
        <div className="manage-modal-heading-container">
          <div className="manage-modal-heading-logo-name">
            <img
              src={`/tokens/${pool?.token?.address}.png`}
              alt="logo"
              className="manage-modal-heading-logo"
            />
            <span className="manage-modal-heading-lg">
              {pool?.token?.symbol}
            </span>
          </div>
          <span
            className="manage-modal-heading-address"
            onClick={() => {
              window.open(`https://polygonscan.com/address/${pool?.pool}`);
            }}
            aria-hidden="true"
          >
            {pool?.pool && SHORT_ADDRESS(pool?.pool)}
          </span>
        </div>
        <CloseIcon
          className="manage-modal-close-icon"
          onClick={onClose}
          aria-hidden="true"
        />
      </ModalHeader>
      <ModalBody bsPrefix="pool-details-modal-body">
        <div className="pool-details-info-list">
          <div className="pool-details-heading">DETAILS</div>
          <div className="pool-details-line">
            <div className="pool-details-index">TVL</div>
            <div className="pool-details-value">
              ${pool?.tvl.toLocaleString()}
            </div>
          </div>
          <div className="pool-details-line">
            <div className="pool-details-index">Weight</div>
            <div className="pool-details-value">{pool?.weight}%</div>
          </div>
          <div className="pool-details-line">
            <div className="pool-details-index">Pending Rewards</div>
            <div className="pool-details-value">
              {toFixed(pool?.pending, 18, 6)} ELXR
            </div>
          </div>
          <div className="pool-details-line">
            <div className="pool-details-index">Pool APY</div>
            <div className="pool-details-value">{FORMAT_APY(pool?.apy)}%</div>
          </div>
          <div className="pool-details-line">
            <div className="pool-details-index">My Liquidity</div>
            <div className="pool-details-value">
              {toFixed(pool?.staked, pool?.token?.decimals, 2)}{" "}
              {pool?.token?.symbol}
            </div>
          </div>
          {/* <div className="pool-details-line">
            <div className="pool-details-index">Rewards Lock Period</div>
            <div className="pool-details-value">
              {FORMAT_DURATION(pool?.avgLockPeriod)}
            </div>
          </div> */}
        </div>
        {pool?.approved ? (
          <Button
            text="STAKE"
            onClick={() => {
              onConfirm();
            }}
          />
        ) : (
          <Button
            text="APPROVE"
            onClick={() => {
              onApprove();
            }}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default PoolDetailsModal;
