import { memo } from "react";

import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Button from "../../../../components/Button";

import "../styles.scss";

interface SellModalProps {
  modalOpen: boolean;
  onClose(): void;
  price: number;
  onConfirm(): void;
}

const SellModal: React.FC<SellModalProps> = ({
  modalOpen,
  onClose,
  price,
  onConfirm,
}) => {
  return (
    <Modal
      show={modalOpen}
      size="lg"
      centered
      contentClassName="manage-modal-content"
      onHide={onClose}
    >
      <div className="manage-modal-bg" />
      <ModalHeader bsPrefix="manage-modal-header">
        <span className="manage-modal-heading-lg">SELL YOUR STARCHI</span>
        <CloseIcon className="manage-modal-close-icon" onClick={onClose} />
      </ModalHeader>
      <ModalBody bsPrefix="name-change-modal-body">
        <span className="sell-question-text">
          Do you want to sell your starchi for{" "}
          <span className="sell-question-text-bold">{price} BUIDL</span> ?
        </span>
        <Button
          text="CONFIRM"
          onClick={() => {
            onConfirm();
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export default memo(SellModal);
