import Slider from "rc-slider";

import "./styles.scss";

const { createSliderWithTooltip } = Slider;
const SliderWithTooltip = createSliderWithTooltip(Slider);

interface SliderBarProps {
  title: string;
  color: string;
  icon: string;
  min: number;
  max: number;
  value: number | undefined;
  onChange(value: number): void;
  tipFormatter?: ((value: number) => React.ReactNode) | undefined;
}

const SliderBar: React.FC<SliderBarProps> = ({
  title,
  color,
  icon,
  min,
  max,
  value,
  onChange,
  tipFormatter,
}) => {
  return (
    <div className="bar-container">
      {icon && (
        <div className="bar-icon">
          <img src={icon} alt="icon" className="bar-image" />
        </div>
      )}
      <div className="bar-setting">
        <span className="bar-title">{title}</span>
        <div className="bar-tick-box">
          <div className="bar-tick-bar">
            <SliderWithTooltip
              trackStyle={{ backgroundColor: color }}
              handleStyle={{
                backgroundColor: "#ffffff",
                border: "none",
                width: "8px",
                height: "8px",
                marginTop: "-2px",
              }}
              min={min}
              max={max}
              defaultValue={0}
              value={value}
              tipFormatter={tipFormatter}
              onChange={(_newValue) => {
                onChange(_newValue);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderBar;
