import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Button from "../../../../components/Button";

import "../styles.scss";

interface NurturingInfoModalProps {
  modalOpen: boolean;
  modalHeader: string;
  onClose(): void;
  onConfirm(): void;
}

const NurturingInfoModal: React.FC<NurturingInfoModalProps> = ({
  modalOpen,
  onClose,
  onConfirm,
  modalHeader,
}) => {
  return (
    <Modal
      show={modalOpen}
      size="lg"
      centered
      contentClassName="manage-modal-content"
      onHide={onClose}
    >
      <div className="manage-modal-bg" />
      <ModalHeader bsPrefix="manage-modal-header">
        <h2 className="nurturing-info-modal">{modalHeader}</h2>
        <CloseIcon className="manage-modal-close-icon" onClick={onClose} />
      </ModalHeader>
      <ModalBody bsPrefix="name-change-modal-body">
        <div className="name-change-modal-confirm">
          <Button disabled={false} text="CONFIRM" onClick={onConfirm} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NurturingInfoModal;
