import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import "../styles.scss";
import { useContractsParams } from "../../../../contexts/ContractsParamsContext";
import { toastError } from "../../../../utils/errorHandlers";

const mintingSpinnerCss = css`
  display: block;
  margin: 0 auto;
`;

interface MintEggModalProps {
  modalOpen: boolean;
  isMintingEgg: boolean;
  onClose(): void;
  onConfirm(petName: string): void;
}

const MintEggModal: React.FC<MintEggModalProps> = ({
  modalOpen,
  isMintingEgg,
  onClose,
  onConfirm,
}) => {
  const [petName, setPetName] = useState("");

  const { mintEggPrice } = useContractsParams();

  // reset modal data when opening/reopening modal
  useEffect(() => {
    setPetName("");
  }, [modalOpen]);

  const onModalHide = () => {
    setPetName("");
    onClose();
  };

  return (
    <Modal
      show={modalOpen}
      size="lg"
      centered
      contentClassName="manage-modal-content"
      onHide={onModalHide}
    >
      <div className="manage-modal-bg" />
      <ModalHeader bsPrefix="manage-modal-header">
        <span className="manage-modal-heading-lg">
          {!isMintingEgg && <div>ENTER A STARCHI NAME</div>}
          {isMintingEgg && <div>YOUR EGG IS MINTING...</div>}
        </span>
        {!isMintingEgg && (
          <CloseIcon className="manage-modal-close-icon" onClick={onClose} />
        )}
      </ModalHeader>
      <ModalBody bsPrefix="name-change-modal-body">
        {!isMintingEgg && (
          <div>
            <div className="pet-name-modal-section">
              <Input
                placeholder="starchi name"
                value={petName}
                onChange={(ev) => {
                  setPetName(ev.target.value);
                }}
              />
            </div>
            <div className="name-change-modal-confirm">
              <Button
                text={
                  mintEggPrice
                    ? `Mint Egg (${mintEggPrice} BUIDL)`
                    : "loading..."
                }
                onClick={() => {
                  if (petName) {
                    onConfirm(petName);
                  } else {
                    toastError("Your Starchi must have a name");
                  }
                }}
              />
            </div>
          </div>
        )}

        <ClipLoader
          color="#fff"
          loading={isMintingEgg}
          css={mintingSpinnerCss}
          size={100}
        />
      </ModalBody>
    </Modal>
  );
};

export default MintEggModal;
