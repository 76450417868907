import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Button from "../../../../components/Button";
import CompletedIcon from "../../../../img/completed.svg";

import "../styles.scss";

interface NameChangeSuccessModalProps {
  modalOpen: boolean;
  onClose(): void;
  onConfirm(): void;
}

const NameChangeSuccessModal: React.FC<NameChangeSuccessModalProps> = ({
  modalOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      show={modalOpen}
      size="lg"
      centered
      contentClassName="manage-modal-content"
      onHide={onClose}
    >
      <div className="manage-modal-bg" />
      <ModalHeader bsPrefix="manage-modal-header">
        <CloseIcon className="manage-modal-close-icon" onClick={onClose} />
      </ModalHeader>
      <ModalBody bsPrefix="name-change-modal-body">
        <span className="name-change-success-text">
          <img
            src={CompletedIcon}
            alt="completed"
            className="name-change-success-icon"
          />
          Name changed successfully
        </span>
        <Button
          text="GO TO CRATE"
          onClick={() => {
            onConfirm();
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export default NameChangeSuccessModal;
