import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Button from "../../../../components/Button";
import { PetWithDetailsProps } from "../../../../api/pets";

import "../styles.scss";

interface SettingsModalProps {
  modalOpen: boolean;
  item: PetWithDetailsProps;
  onClose(): void;
  onChangeName(): void;
  onSell(sellPrice: number): void;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
  modalOpen,
  item,
  onClose,
  onChangeName,
}) => {
  return (
    <Modal
      show={modalOpen}
      size="lg"
      centered
      contentClassName="manage-modal-content"
      onHide={onClose}
    >
      <div className="manage-modal-bg" />
      <ModalHeader bsPrefix="manage-modal-header">
        <span className="manage-modal-heading-lg">Manage Starchi</span>
        <CloseIcon className="manage-modal-close-icon" onClick={onClose} />
      </ModalHeader>
      <ModalBody bsPrefix="manage-modal-body">
        <div className="manage-modal-name-info">
          <p className="manage-modal-heading-md">NAME</p>
          <p className="manage-modal-text">
            Choose a unique nick name for your Starchi.
          </p>
          <div className="manage-modal-section">
            <p className="manage-modal-heading-sm">Current Name</p>
            <div className="manage-modal-name-id">
              {item && item.name} #{item && item.petId}
            </div>
          </div>
          <Button text="CHANGE NAME" onClick={onChangeName} />
        </div>
        {/* <div className="manage-modal-divider" />
        <div className="manage-modal-sell">
          <p className="manage-modal-heading-md">SELL YOUR STARCHI</p>
          <p className="manage-modal-text">
            Your starchi will be listed on the pet store.
          </p>
          <div className="manage-modal-section">
            <p className="manage-modal-heading-sm">Sell for</p>
            <Input
              label="BUIDL"
              value={sellPrice}
              onChange={(ev) => {
                setSellPrice(Number(ev.target.value));
              }}
            />
          </div>
          <Button
            text="SELL STARCHI"
            onClick={() => {
              onSell(sellPrice);
            }}
          />
        </div> */}
      </ModalBody>
    </Modal>
  );
};

export default SettingsModal;
