import { useRef } from "react";

import { useAccount } from "../../../contexts/AccountContext";
import PlayerTwoMessage from "./PlayerTwoMessage";
import PlayerOneMessage from "./PlayerOneMessage";
import { ChatEventDto } from "../../../pages/Game/dtos/response/chat-event.dto";

interface MessagesProps {
  messages: ChatEventDto[];
}

const Messages: React.FC<MessagesProps> = ({ messages }) => {
  const { account } = useAccount();

  const el = useRef(null);
  // useEffect(() => {
  //   el?.current.scrollIntoView({ block: "end", behavior: "smooth" });
  // });

  return (
    <div className="messages">
      {messages.map(({ message, senderAddress }) =>
        senderAddress === account?.address ? (
          <PlayerOneMessage key={message + new Date()} text={message} />
        ) : (
          <PlayerTwoMessage key={message + new Date()} text={message} />
        ),
      )}
      <div id="el" ref={el} />
    </div>
  );
};

export default Messages;
