import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
// import Switch from "@material-ui/core/Switch";
import BarLoader from "react-spinners/BarLoader";
import { css } from "@emotion/react";

import { useCrate } from "../../contexts/CrateContext";
import { useElements } from "../../../../hooks/useElements";
import {
  ELEMENTS,
  MAX_ELEMENTS_ATTACHED_TO_PET,
  NEUTRAL_ELEMENT_INDEX,
} from "../../../../constants/constants";
import Button from "../../../../components/Button";
import SelectInput from "../../../../components/SelectInput";

import { ElementWithDetailsProps } from "../../../../api/elements";
import ElementCard from "../ElementCard";

import { PetWithDetailsProps } from "../../../../api/pets";
import { findListedToken } from "../../../../api/restapi";
import { blockchainAddresses } from "../../../../config";
import { ContractTypes } from "../../../Store/contexts/StoreContext";
import { toastError } from "../../../../utils/errorHandlers";

import "../styles.scss";
import "./styles.scss";

const barLoaderCss = css`
  display: block;
  margin-top: 5px;
`;

interface ElementsListModalProps {
  option: "ATTACH_ELEMENT" | "DETACH_ELEMENT" | "AVAILABLE_ELEMENTS";
  pet?: PetWithDetailsProps;
  myPets?: PetWithDetailsProps[];
  modalOpen: boolean;
  onClose(): void;
  setShowModal?(show: boolean): void;
}

const ElementsListModal: React.FC<ElementsListModalProps> = ({
  option,
  pet,
  modalOpen,
  myPets,
  setShowModal,
  onClose,
}) => {
  const [elementOptions, setElementOptions] = useState(ELEMENTS);
  const [petBreedElements, setPetBreedElements] = useState<number[]>([]);
  const { myElements, attachNewElementToPet, detachPetElement } = useCrate();
  const { fetchAllowedElementsByBreedId } = useElements();
  const [showAttachedCards, setShowAttachedCards] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [isAttachingToListedPet, setIsAttachingToListedPet] = useState(false);
  const [selectedElementToAttach, setSelectedElementToAttach] = useState<
    undefined | number
  >(undefined);
  const [filteredElements, setFilteredElements] = useState<
    ElementWithDetailsProps[]
  >([]);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedElementType, setSelectedElementType] = useState(0);

  const history = useHistory();

  useEffect(() => {
    if (option === "ATTACH_ELEMENT") {
      const filteredElementOptions = ELEMENTS.filter((elementOpt) => {
        const isAllowedByBreed =
          elementOpt?.id === NEUTRAL_ELEMENT_INDEX ||
          petBreedElements.includes(elementOpt?.id || 0);

        const elementIsAttachedToPet = pet?.elements.find(
          (element) => element.typeId === elementOpt?.id,
        );

        return elementOpt && isAllowedByBreed && !elementIsAttachedToPet;
      });

      filteredElementOptions.unshift(undefined);

      setElementOptions(filteredElementOptions);
    } else if (option === "DETACH_ELEMENT") {
      const petElementTypes = pet?.elements.map((element) => element.typeId);

      const filteredElementOptions = ELEMENTS.filter(
        (elementOpt) => elementOpt && petElementTypes?.includes(elementOpt?.id),
      );

      filteredElementOptions.unshift(undefined);

      setElementOptions(filteredElementOptions);
    }
  }, [pet, option, petBreedElements, filteredElements]);

  const sortElements = useCallback(
    (elementsToSort: ElementWithDetailsProps[]): ElementWithDetailsProps[] => {
      const sortedElements = elementsToSort.sort((prevElement, nextElement) => {
        const prevElementName = ELEMENTS[prevElement.typeId]?.name;
        const nextElementName = ELEMENTS[nextElement.typeId]?.name;

        if (
          prevElementName &&
          nextElementName &&
          prevElementName !== nextElementName
        ) {
          if (prevElementName < nextElementName) {
            return -1;
          }
          if (nextElementName > prevElementName) {
            return 1;
          }
          return 0;
        }

        return (
          nextElement.powerRatio.toNumber() - prevElement.powerRatio.toNumber()
        );
      });

      return sortedElements;
    },
    [],
  );

  const availableElementsToAttach = useCallback(
    (breedElements) => {
      return myElements.filter((element) => {
        const petElementTypes = pet?.elements.map(
          (petElement) => petElement.typeId,
        );

        const isElementAttachedToThisPet = !petElementTypes?.includes(
          element.typeId,
        );
        const isElementAttachedToAnotherPet = element.attachedPetId === 0;

        const isAllowedByBreed =
          element.typeId === NEUTRAL_ELEMENT_INDEX ||
          breedElements.includes(element.typeId);

        return (
          isElementAttachedToThisPet &&
          isAllowedByBreed &&
          isElementAttachedToAnotherPet
        );
      });
    },
    [myElements, pet],
  );

  const loadElements = useCallback(async () => {
    if (!pet || pet?.stage === "Egg") {
      return;
    }

    const breedElements = await fetchAllowedElementsByBreedId(
      String(pet.breedId),
    );
    setPetBreedElements(breedElements);

    let petAvailableElements: ElementWithDetailsProps[] = [];

    if (pet.elements.length < MAX_ELEMENTS_ATTACHED_TO_PET) {
      petAvailableElements = availableElementsToAttach(breedElements);
    }

    const sortedElements = sortElements(petAvailableElements);

    const newElements = sortedElements.filter(
      (element) =>
        selectedElementType === 0 || selectedElementType === element.typeId,
    );

    setFilteredElements(newElements);
  }, [
    fetchAllowedElementsByBreedId,
    pet,
    availableElementsToAttach,
    sortElements,
    selectedElementType,
  ]);

  const loadAvailableElements = useCallback(async () => {
    const newElements = myElements.filter(
      (element) =>
        selectedElementType === 0 || selectedElementType === element.typeId,
    );

    const availableElements = showAttachedCards
      ? newElements.filter((element) => element.attachedPetId !== 0)
      : newElements.filter((element) => element.attachedPetId === 0);

    const sortedElements = sortElements(availableElements);

    setFilteredElements(sortedElements);
  }, [showAttachedCards, myElements, sortElements, selectedElementType]);

  useEffect(() => {
    if (modalOpen) {
      setShowAttachedCards(false);
      setSelectedElementType(0);
    }
  }, [modalOpen]);

  useEffect(() => {
    const loadSwitch = async () => {
      let sortedElements;
      let newElements;

      switch (option) {
        case "ATTACH_ELEMENT":
          setModalTitle("CHOOSE AN ELEMENT TO ATTACH");
          await loadElements();
          break;
        case "DETACH_ELEMENT":
          if (!pet) {
            return;
          }
          setModalTitle("ATTACHED ELEMENTS");
          sortedElements = sortElements(pet?.elements);
          newElements = sortedElements.filter(
            (element) => selectedElementType === element.typeId,
          );
          setFilteredElements(
            !selectedElementType ? sortedElements : newElements,
          );
          break;
        case "AVAILABLE_ELEMENTS":
          setModalTitle("ELEMENTS CARD LIST");
          loadAvailableElements();
          break;
        default:
          break;
      }
    };

    if (modalOpen) {
      loadSwitch();
    }
  }, [
    modalOpen,
    loadElements,
    loadAvailableElements,
    option,
    myElements,
    pet,
    sortElements,
    selectedElementType,
  ]);

  const onConfirmAttachElement = useCallback(
    async (elementId: number) => {
      if (!pet) {
        return;
      }
      try {
        const token = await findListedToken({
          tokenId: pet.petId,
          contractAddress: blockchainAddresses.pets,
          contractType: ContractTypes.Pets,
        });

        const isPetListed = typeof token === "object";

        if (isPetListed) {
          setSelectedElementToAttach(elementId);
          if (setShowModal) {
            setShowModal(false);
          }
          setOpenAlertModal(true);
        } else {
          await attachNewElementToPet(pet.petId, elementId);
          onClose();
        }
      } catch (err) {
        console.error("onConfirmAttachElement", err);
        toastError(err);
      }
    },
    [attachNewElementToPet, pet, onClose, setShowModal],
  );

  const onAttachElementToListedPet = useCallback(async () => {
    if (!pet) {
      return;
    }
    try {
      if (pet && selectedElementToAttach) {
        setIsAttachingToListedPet(true);
        await attachNewElementToPet(pet.petId, selectedElementToAttach);
      }
      setIsAttachingToListedPet(false);

      setOpenAlertModal(false);
      onClose();
    } catch (err) {
      console.error("onConfirmAttachElement", err);
      toastError(err);

      setIsAttachingToListedPet(false);
      setOpenAlertModal(false);
      if (setShowModal) {
        setShowModal(true);
      }
    }
  }, [
    attachNewElementToPet,
    pet,
    selectedElementToAttach,
    onClose,
    setShowModal,
  ]);

  const onConfirmDetachElement = useCallback(
    async (elementId: number) => {
      if (!pet) {
        return;
      }
      await detachPetElement(pet.petId, elementId);
      onClose();
    },
    [detachPetElement, pet, onClose],
  );

  return (
    <>
      <Modal
        show={modalOpen}
        size="lg"
        centered
        contentClassName="manage-modal-content"
        onHide={onClose}
      >
        <div className="manage-modal-bg" />
        <ModalHeader bsPrefix="manage-modal-header">
          <span className="manage-modal-heading-lg">{modalTitle}</span>
          <CloseIcon className="manage-modal-close-icon" onClick={onClose} />
        </ModalHeader>
        <ModalBody
          bsPrefix="elements-list-modal-body"
          className="elements-list-modal"
        >
          {!(pet?.elements.length === 3 && option === "ATTACH_ELEMENT") && (
            <div className="element-modal-section filters">
              <div style={{ width: "50%" }}>
                <p className="element-modal-filter-label">Filter By element</p>
                <SelectInput
                  placeholder="element type"
                  value={String(selectedElementType)}
                  options={elementOptions.map((element) => ({
                    value: element?.id ? String(element?.id) : "0",
                    label: element?.name || "Select an element",
                  }))}
                  onChange={(ev) => {
                    setSelectedElementType(Number(ev.target.value));
                  }}
                />
              </div>

              {/* {option === "AVAILABLE_ELEMENTS" && (
              <div className="elements-switch">
                <Switch
                  checked={showAttachedCards}
                  onChange={() => {
                    setShowAttachedCards(!showAttachedCards);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <p className="staging-switch-label">Show Attached Cards</p>
              </div>
            )} */}
            </div>
          )}

          <div className="elements-list-modal-section">
            {filteredElements.length ? (
              <div className="my-elements-list">
                {filteredElements.map((element) => (
                  <ElementCard
                    key={element.elementId}
                    showAttachedTo={showAttachedCards}
                    isAttachElement={option !== "DETACH_ELEMENT"}
                    element={element}
                    myPets={myPets}
                    showButton={option !== "AVAILABLE_ELEMENTS"}
                    onClose={onClose}
                    onClick={async (elementId: number) => {
                      if (option === "ATTACH_ELEMENT") {
                        await onConfirmAttachElement(elementId);
                      } else if (option === "DETACH_ELEMENT") {
                        await onConfirmDetachElement(elementId);
                      }
                    }}
                  />
                ))}
              </div>
            ) : option === "AVAILABLE_ELEMENTS" ? (
              <div className="my-elements-list">
                <span className="view-cards-modal-heading">
                  You don't have{" "}
                  {selectedElementType
                    ? ELEMENTS[selectedElementType]?.name
                    : "any"}{" "}
                  cards, mint your first element here:
                </span>
                <div className="crate-box-call-action-button view-cards-modal-btn">
                  <Button
                    text="GO TO STORE"
                    size="md"
                    onClick={() => history.push("store")}
                  />
                </div>
              </div>
            ) : (
              <div className="my-elements-list">
                <span
                  className="manage-modal-heading-md"
                  style={{ textAlign: "center", marginTop: "0.8rem" }}
                >
                  {option === "ATTACH_ELEMENT"
                    ? pet?.elements.length === 3
                      ? "Attached elements limit reached"
                      : `No ${
                          selectedElementType
                            ? ELEMENTS[selectedElementType]?.name
                            : ""
                        } Elements to Attach`
                    : "No Attached Elements"}
                </span>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        show={openAlertModal}
        size="lg"
        backdrop="static"
        centered
        contentClassName="quick-battle-modal-content"
        onHide={() => {
          setOpenAlertModal(false);
          if (setShowModal) {
            setShowModal(true);
          }
        }}
      >
        <div className="quick-battle-modal-bg" />
        <ModalHeader bsPrefix="quick-battle-modal-header">
          <CloseIcon
            className="quick-battle-modal-close-icon"
            onClick={() => {
              setOpenAlertModal(false);
              if (setShowModal) {
                setShowModal(true);
              }
            }}
          />
        </ModalHeader>
        <ModalBody bsPrefix="alert-elements-modal">
          <span className="alert-elements-modal-attention">Attention!</span>
          <span className="alert-elements-modal-text">
            This is Starchi is listed on our marketplace and all the elements
            attached to it will also be sold.
          </span>

          {isAttachingToListedPet ? (
            <div className="spinner-center">
              <BarLoader
                color="#36D7B7"
                loading
                css={barLoaderCss}
                height={4}
                width={300}
              />
            </div>
          ) : (
            <div className="elements-button">
              <Button
                text="ATTACH ELEMENT"
                onClick={onAttachElementToListedPet}
              />
              <Button
                style={{ marginLeft: "0.8rem" }}
                text="CANCEL"
                onClick={() => {
                  setOpenAlertModal(false);
                  if (setShowModal) {
                    setShowModal(true);
                  }
                }}
              />
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ElementsListModal;
