import { useMemo, useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { ImHeart } from "react-icons/im";
import { BsLightningChargeFill } from "react-icons/bs";
import { GiClover, GiFist } from "react-icons/gi";
import { FaWind } from "react-icons/fa";

import {
  BREEDS,
  CARD_LEVELS,
  DAMAGE_TYPES,
  EFFECTS,
  ELEMENTS,
  PET_STAGES,
  PET_TYPES,
} from "../../../../constants/constants";

import Input from "../../../../components/Input";
import CheckBox from "../../../../components/Checkbox";
import StatBar from "../../../../components/StatBar";

import "./styles.scss";
import {
  ElementStatsProps,
  StarchiStatsProps,
  useStore,
} from "../../contexts/StoreContext";
import FilterMenu from "../../../../components/FilterMenu";

const starchiStatsFilters = [
  { type: "HP", color: "#e12939", icon: <ImHeart /> },
  { type: "Energy", color: "#7B21FC", icon: <BsLightningChargeFill /> },
  { type: "Luckiness", color: "#16b200", icon: <GiClover /> },
  { type: "Speed", color: "#cec505", icon: <FaWind /> },
];

const elementStatsFilters = [
  { type: "PowerRatio", color: "#e92222", icon: <GiFist /> },
  { type: "Energy", color: "#7B21FC", icon: <BsLightningChargeFill /> },
];

const sortStarchiesValues = [
  { name: "Sort by", value: 0 },
  { name: "Price", value: 1 },
  { name: "HP", value: 2 },
  { name: "Energy", value: 3 },
  { name: "Luckiness", value: 4 },
  { name: "Speed", value: 5 },
];

const sortElementsValues = [
  { name: "Sort by", value: 0 },
  { name: "Price", value: 1 },
  { name: "PowerRatio", value: 2 },
  { name: "Energy", value: 3 },
];

export type FilterStarchiStatsProps = {
  [type: string]: {
    min: number;
    max: number;
  };
};

export type FilterElementStatsProps = {
  [type: string]: {
    min: number;
    max: number;
  };
};

interface FilterBoxProps {
  onClose(): void;
}

const FilterBox: React.FC<FilterBoxProps> = ({ onClose }) => {
  const {
    selectedTab,
    setSelectedTab,
    setElementFilters,
    elementFilters,
    setStarchiFilters,
    starchiFilters,
    setElementsSort,
    elementsSort,
    setStarchiesSort,
    starchiesSort,
  } = useStore();

  const [filterStarchiStats, setFilterStarchiStats] =
    useState<FilterStarchiStatsProps>({} as FilterStarchiStatsProps);

  const [filterElementStats, setFilterElementStats] =
    useState<FilterElementStatsProps>({} as FilterElementStatsProps);

  const handleChange = (event: any, newTab: 0 | 1) => {
    setSelectedTab(newTab);
  };

  const filterCount = useMemo(() => {
    let total;

    if (selectedTab === 0) {
      const { stages, breeds, petTypes, stats, starchiName } = starchiFilters;

      total =
        stages.length +
        breeds.length +
        petTypes.length +
        Object.keys(stats).filter(
          (key) =>
            stats[key as keyof StarchiStatsProps]?.min > 0 ||
            stats[key as keyof StarchiStatsProps]?.max < 100,
        ).length;

      total += starchiName?.length ? 1 : 0;
    } else {
      const { elementNames, cardLevels, damageTypes, effects, stats } =
        elementFilters;

      total =
        elementNames.length +
        cardLevels.length +
        damageTypes.length +
        effects.length +
        Object.keys(stats).filter(
          (key) =>
            stats[key as keyof ElementStatsProps]?.min > 0 ||
            stats[key as keyof ElementStatsProps]?.max < 100,
        ).length;
    }
    return total;
  }, [elementFilters, starchiFilters, selectedTab]);

  return (
    <div className="filter-box">
      <div className="filter-box-background" />
      <CloseIcon className="filter-box-close-icon" onClick={onClose} />

      <Tabs
        centered
        className="filter-tabs"
        value={selectedTab}
        onChange={handleChange}
      >
        <Tab disableRipple label="Starchies" />
        <Tab disableRipple label="Elements" />
      </Tabs>

      {selectedTab === 0 ? (
        <>
          <div className="filter-box-header">
            <div className="filter-box-header-left">
              <span className="filter-box-heading">Filter({filterCount})</span>
              <button
                type="button"
                className="filter-box-clear-filter"
                onClick={() => {
                  setStarchiFilters({
                    stages: [],
                    stats: {} as StarchiStatsProps,
                    breeds: [],
                    petTypes: [],
                    starchiName: "",
                  });
                }}
              >
                Clear Filters
              </button>
              <div className="item-list-sort">
                <FilterMenu
                  items={sortStarchiesValues}
                  value={starchiesSort?.sortBy}
                  sort={starchiesSort}
                  onChange={setStarchiesSort}
                  showSortingIcons
                  toogleSortingDirection={() =>
                    setStarchiesSort({
                      ...starchiesSort,
                      sortingDirection:
                        starchiesSort.sortingDirection === 1 ? 0 : 1,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="filter-box-body">
            <div className="filter-box-section">
              <p className="filter-box-type-heading">Find By Name:</p>
              <div className="filter-box-controls-input">
                <Input
                  placeholder="Starchi Name"
                  value={starchiFilters.starchiName}
                  onChange={(ev) => {
                    setStarchiFilters({
                      ...starchiFilters,
                      starchiName: ev.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="filter-box-section">
              <p className="filter-box-type-heading">Phase:</p>
              <div className="filter-box-controls">
                {PET_STAGES.map((type) => (
                  <CheckBox
                    key={type}
                    title={type}
                    checked={starchiFilters.stages.includes(type)}
                    onChange={(checked) => {
                      let newStages = [];
                      if (!checked) {
                        newStages = starchiFilters.stages.filter(
                          (stage) => stage !== type,
                        );
                      } else {
                        newStages = [...starchiFilters.stages, type];
                      }
                      setStarchiFilters({
                        ...starchiFilters,
                        stages: newStages,
                      });
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="filter-box-section">
              <p className="filter-box-type-heading">Breed:</p>
              <div className="filter-box-controls">
                {BREEDS.map(
                  (breed) =>
                    breed && (
                      <CheckBox
                        key={breed.name}
                        title={breed.name}
                        checked={starchiFilters.breeds.includes(breed.id)}
                        onChange={(checked) => {
                          let newBreeds = [];

                          if (!checked) {
                            newBreeds = starchiFilters.breeds.filter(
                              (e) => e !== breed.id,
                            );
                          } else {
                            newBreeds = [...starchiFilters.breeds, breed.id];
                          }
                          setStarchiFilters({
                            ...starchiFilters,
                            breeds: newBreeds,
                          });
                        }}
                      />
                    ),
                )}
              </div>
            </div>
            <div className="filter-box-section">
              <p className="filter-box-type-heading">Pet Type:</p>
              {/* TODO: Replace with dropdown */}
              <div className="filter-box-controls">
                {PET_TYPES.map(
                  (petType) =>
                    petType && (
                      <CheckBox
                        key={petType.name}
                        title={petType.name}
                        checked={starchiFilters.petTypes.includes(petType.id)}
                        onChange={(checked) => {
                          let newPetTypes = [];

                          if (!checked) {
                            newPetTypes = starchiFilters.petTypes.filter(
                              (e) => e !== petType.id,
                            );
                          } else {
                            newPetTypes = [
                              ...starchiFilters.petTypes,
                              petType.id,
                            ];
                          }
                          setStarchiFilters({
                            ...starchiFilters,
                            petTypes: newPetTypes,
                          });
                        }}
                      />
                    ),
                )}
              </div>
            </div>

            <div className="filter-box-section">
              <p className="filter-box-type-heading">Stats:</p>
              <div className="filter-box-controls-stats">
                {starchiStatsFilters.map((stat) => (
                  <StatBar
                    key={stat.type}
                    title={stat.type}
                    color={stat.color}
                    IconComponent={stat.icon}
                    min={
                      filterStarchiStats[stat.type as keyof StarchiStatsProps]
                        ?.min || 0
                    }
                    max={
                      filterStarchiStats[stat.type as keyof StarchiStatsProps]
                        ?.max || 100
                    }
                    onChange={(newMin, newMax) => {
                      setFilterStarchiStats({
                        [stat.type]: {
                          min: newMin,
                          max: newMax,
                        },
                      });
                    }}
                    onAfterChange={(newMin, newMax) => {
                      const newStats = {
                        ...starchiFilters.stats,
                        [stat.type]: {
                          ...starchiFilters.stats[
                            stat.type as keyof StarchiStatsProps
                          ],
                          min: newMin,
                          max: newMax,
                        },
                      };
                      setStarchiFilters({
                        ...starchiFilters,
                        stats: newStats,
                      });
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="filter-box-header">
            <div className="filter-box-header-left">
              <span className="filter-box-heading">Filter({filterCount})</span>
              <button
                type="button"
                className="filter-box-clear-filter"
                onClick={() => {
                  setElementFilters({
                    elementNames: [],
                    cardLevels: [],
                    damageTypes: [],
                    effects: [],
                    stats: {} as ElementStatsProps,
                  });
                }}
              >
                Clear Filters
              </button>

              <div className="item-list-sort">
                <FilterMenu
                  items={sortElementsValues}
                  value={elementsSort?.sortBy}
                  sort={elementsSort}
                  onChange={setElementsSort}
                  showSortingIcons
                  toogleSortingDirection={() =>
                    setElementsSort({
                      ...elementsSort,
                      sortingDirection:
                        elementsSort.sortingDirection === 1 ? 0 : 1,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="filter-box-body">
            <div className="filter-box-section">
              <p className="filter-box-type-heading">Element:</p>
              <div className="filter-box-controls">
                {ELEMENTS.map(
                  (element) =>
                    element && (
                      <CheckBox
                        key={element.name}
                        icon={element.icon}
                        title={element.name}
                        checked={elementFilters.elementNames.includes(
                          element.name,
                        )}
                        onChange={(checked) => {
                          let newElements = [];

                          if (!checked) {
                            newElements = elementFilters.elementNames.filter(
                              (e) => e !== element.name,
                            );
                          } else {
                            newElements = [
                              ...elementFilters.elementNames,
                              element.name,
                            ];
                          }

                          setElementFilters({
                            ...elementFilters,
                            elementNames: newElements,
                          });
                        }}
                      />
                    ),
                )}
              </div>
            </div>
            <div className="filter-box-section">
              <p className="filter-box-type-heading">Level:</p>
              <div className="filter-box-controls">
                {CARD_LEVELS.map((cardLevelName, cardIndex) => (
                  <CheckBox
                    key={cardLevelName}
                    title={cardLevelName}
                    checked={elementFilters.cardLevels.includes(cardIndex + 1)}
                    onChange={(checked) => {
                      const cardLevel = cardIndex + 1;
                      let newCardLevels = [];
                      if (!checked) {
                        newCardLevels = elementFilters.cardLevels.filter(
                          (level) => level !== cardLevel,
                        );
                      } else {
                        newCardLevels = [
                          ...elementFilters.cardLevels,
                          cardLevel,
                        ];
                      }

                      setElementFilters({
                        ...elementFilters,
                        cardLevels: newCardLevels,
                      });
                    }}
                  />
                ))}
              </div>
            </div>

            <div className="filter-box-section">
              <p className="filter-box-type-heading">Stats:</p>
              <div className="filter-box-controls-stats">
                {elementStatsFilters.map((stat) => (
                  <StatBar
                    key={stat.type}
                    title={stat.type}
                    color={stat.color}
                    IconComponent={stat.icon}
                    min={
                      filterElementStats[stat.type as keyof ElementStatsProps]
                        ?.min || 0
                    }
                    max={
                      filterElementStats[stat.type as keyof ElementStatsProps]
                        ?.max || 100
                    }
                    onChange={(newMin, newMax) => {
                      setFilterElementStats({
                        [stat.type]: {
                          min: newMin,
                          max: newMax,
                        },
                      });
                    }}
                    onAfterChange={(newMin, newMax) => {
                      const newStats = {
                        ...elementFilters.stats,
                        [stat.type]: {
                          ...elementFilters.stats[
                            stat.type as keyof ElementStatsProps
                          ],
                          min: newMin,
                          max: newMax,
                        },
                      };
                      setElementFilters({
                        ...elementFilters,
                        stats: newStats,
                      });
                    }}
                  />
                ))}
              </div>
            </div>

            <div className="filter-box-section">
              <p className="filter-box-type-heading">Effects:</p>
              <div className="filter-box-controls">
                {EFFECTS.map(
                  (effectData) =>
                    effectData && (
                      <CheckBox
                        key={effectData.name}
                        title={effectData.name}
                        checked={elementFilters.effects.includes(effectData.id)}
                        onChange={(checked) => {
                          let newEffects = [];
                          if (!checked) {
                            newEffects = elementFilters.effects.filter(
                              (e) => e !== effectData.id,
                            );
                          } else {
                            newEffects = [
                              ...elementFilters.effects,
                              effectData.id,
                            ];
                          }

                          setElementFilters({
                            ...elementFilters,
                            effects: newEffects,
                          });
                        }}
                      />
                    ),
                )}
              </div>
            </div>

            <div className="filter-box-section">
              <p className="filter-box-type-heading">Damage Type:</p>
              <div className="filter-box-controls">
                {DAMAGE_TYPES.map((damageType) => (
                  <CheckBox
                    key={damageType.name}
                    title={damageType.name}
                    checked={elementFilters.damageTypes.includes(damageType.id)}
                    onChange={(checked) => {
                      // const cardLevel = cardIndex + 1;
                      let newDamageTypes = [];
                      if (!checked) {
                        newDamageTypes = elementFilters.damageTypes.filter(
                          (type) => type !== damageType.id,
                        );
                      } else {
                        newDamageTypes = [
                          ...elementFilters.damageTypes,
                          damageType.id,
                        ];
                      }

                      setElementFilters({
                        ...elementFilters,
                        damageTypes: newDamageTypes,
                      });
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FilterBox;
