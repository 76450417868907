import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import ClipLoader from "react-spinners/ClipLoader";

import { BsLightningChargeFill } from "react-icons/bs";
import {
  GiClover,
  GiFist,
  GiMagicPortal,
  GiWingedScepter,
} from "react-icons/gi";
import { RiSwordLine } from "react-icons/ri";
import { ImHeart } from "react-icons/im";
import { FaWind, FaArrowLeft } from "react-icons/fa";

import StarchiBox from "../../../../components/Starchibox";
import Button from "../../../../components/Button";
import StatValue from "../../../../components/StatValue";

import NameBadge from "../../../../components/StarchiNameBadge";
import {
  BREEDS,
  CARD_LEVELS,
  EFFECTS,
  ELEMENTS,
  PET_TYPES,
} from "../../../../constants/constants";
import {
  ContractTypes,
  TokenProps,
  useStore,
} from "../../contexts/StoreContext";
import { useQuery } from "../../../../hooks/useQuery";
import { useAccount } from "../../../../contexts/AccountContext";
import { blockchainAddresses } from "../../../../config";
import { toastError, toastSuccess } from "../../../../utils/errorHandlers";
import { useContractsParams } from "../../../../contexts/ContractsParamsContext";
import "../styles.scss";

import "./styles.scss";

interface ParamProps {
  id: string;
}

const ItemDetail: React.FC = () => {
  const { account } = useAccount();
  const [item, setItem] = useState<TokenProps>({} as TokenProps);
  const [isApprovingToken, setIsApprovingToken] = useState(false);
  const [isBuyingNFT, setIsBuyingNFT] = useState(false);
  const [isCancelingListing, setIsCancelingListing] = useState(false);

  const history = useHistory();
  const { id } = useParams<ParamProps>();
  const {
    findToken,
    isLoadingListedTokens,
    buyToken,
    cancelTokenSell,
    listedTokens,
  } = useStore();
  const { approveToken, approvedTokens } = useContractsParams();

  const query = useQuery();

  useEffect(() => {
    const loadIten = async () => {
      const contractType = query?.get("contractType");

      const foundToken = findToken(Number(id), Number(contractType), false);

      if (foundToken) {
        setItem(foundToken);
      }
    };

    if (!isLoadingListedTokens && listedTokens.length) {
      loadIten();
    }
  }, [query, id, findToken, isLoadingListedTokens, listedTokens]);

  const handleApproveClick = useCallback(async () => {
    setIsApprovingToken(true);
    try {
      if (item.contractType === ContractTypes.Elements) {
        await approveToken(
          blockchainAddresses.buidlToken,
          blockchainAddresses.elementMarket,
        );
      } else if (item.contractType === ContractTypes.Pets) {
        await approveToken(
          blockchainAddresses.buidlToken,
          blockchainAddresses.starchiMarket,
        );
      }
    } catch (error) {
      console.error(error);
      toastError(error);
    }
    setIsApprovingToken(false);
  }, [approveToken, item.contractType]);

  const handleBuyNFT = useCallback(async () => {
    setIsBuyingNFT(true);
    try {
      await buyToken(item);
      toastSuccess("NFT successfully bought from the market");
      history.replace(
        `/store/sell/${item.tokenId}?contractType=${item.contractType}`,
      );
    } catch (err: any) {
      toastError("Fail to buy NFT from the market");
      console.error("buyToken", err);
    }
    setIsBuyingNFT(false);
  }, [buyToken, item, history]);

  const handlCancelListing = useCallback(async () => {
    setIsCancelingListing(true);
    try {
      await cancelTokenSell(item);

      toastSuccess("NFT successfully removed from the market");
      history.replace(
        `/store/sell/${item.tokenId}?contractType=${item.contractType}`,
      );
    } catch (err) {
      toastError("Fail to remove NFT from the market");
      console.error("cancelListing", err);
    }

    setIsCancelingListing(false);
  }, [cancelTokenSell, item, history]);

  return (
    <div className="item-detail-container">
      <StarchiBox styles={{ padding: "30px" }}>
        {(isLoadingListedTokens || !item?.tokenId) && (
          <div className="item-detail-info item-loader">
            <ClipLoader
              color="#ffffff"
              loading={isLoadingListedTokens}
              css="margin: auto"
              size={120}
            />
          </div>
        )}
        {!isLoadingListedTokens && item?.tokenId && (
          <>
            <div className="item-detail-info">
              <FaArrowLeft
                className="item-detail-arrow-left"
                onClick={() => {
                  history.push("/store");
                }}
              />

              <div className="item-detail-title-image">
                <div className="item-detail-title">
                  <NameBadge
                    name={item.name}
                    id={item.tokenId}
                    petBreedId={
                      item.contractType === ContractTypes.Pets
                        ? String(item?.breedId)
                        : ""
                    }
                    elementId={
                      item.contractType === ContractTypes.Elements
                        ? String(item.typeId)
                        : ""
                    }
                  />
                </div>
                <div className="item-detail-image-box">
                  <img
                    src={item.image || undefined}
                    alt={
                      item.contractType === ContractTypes.Elements
                        ? "Element Card"
                        : item.contractType === ContractTypes.Pets
                        ? "Starchi"
                        : ""
                    }
                    className="item-detail-image"
                  />
                </div>
              </div>
              <div className="item-detail-divider" />
              <div className="item-detail-right">
                <span className="item-detail-heading">About the NFT</span>
                {item.contractType === ContractTypes.Elements ? (
                  <>
                    <div className="item-detail-stage-element">
                      <span className="quick-battle-stage-label">Level: </span>
                      <span className="quick-battle-stage-value">
                        {CARD_LEVELS[Number(item?.level) - 1]}
                      </span>
                    </div>

                    <div className="item-detail-stage-element">
                      <span className="item-detail-stage">
                        Element:{" "}
                        <span className="item-detail-stage-value">
                          {ELEMENTS[item.typeId]?.name || ""}
                        </span>
                      </span>
                    </div>
                  </>
                ) : (
                  item.contractType === ContractTypes.Pets && (
                    <>
                      {item?.stage !== "Egg" && (
                        <>
                          <div className="item-detail-stage-element">
                            <span className="item-detail-stage">
                              Pet Type:{" "}
                              <span className="item-detail-stage-value">
                                {PET_TYPES[Number(item.typeId)]?.name}
                              </span>
                            </span>
                          </div>

                          <div className="item-detail-stage-element">
                            <span className="item-detail-stage">
                              Breed:{" "}
                              <span className="item-detail-stage-value">
                                {BREEDS[Number(item?.breedId)]?.name}
                              </span>
                            </span>
                          </div>
                        </>
                      )}

                      <div className="item-detail-stage-element">
                        <span className="quick-battle-stage-label">
                          Stage:{" "}
                        </span>
                        <span className="quick-battle-stage-value">
                          {item?.stage}
                        </span>
                      </div>
                    </>
                  )
                )}

                <span className="item-detail-owner">
                  Owner:{" "}
                  <span className="item-detail-owner-value">
                    {item?.sellerAddress === account?.address
                      ? "You"
                      : item?.sellerAddress}
                  </span>
                </span>

                <span className="item-detail-heading">Stats</span>
                <div className="item-detail-stats">
                  {item.contractType === ContractTypes.Elements ? (
                    <>
                      <StatValue
                        IconComponent={<GiFist />}
                        backgroundColor="#e92222"
                        title="PowerRatio"
                        value={item?.powerRatio || 0}
                      />
                      <StatValue
                        IconComponent={<BsLightningChargeFill />}
                        backgroundColor="#7b21fc"
                        title="Energy"
                        value={item?.energy || 0}
                      />
                      <StatValue
                        IconComponent={
                          item?.damageType === 0 ? (
                            <RiSwordLine />
                          ) : (
                            <GiWingedScepter />
                          )
                        }
                        backgroundColor="#2a22e9"
                        title="DamageType"
                        value={item?.damageType === 0 ? "Physic" : "Magic"}
                      />
                      {item.effect !== 0 && item?.effect && (
                        <StatValue
                          IconComponent={<GiMagicPortal />}
                          backgroundColor="#18545a"
                          title="Effect"
                          value={`${EFFECTS[item.effect]?.name}`}
                        />
                      )}
                    </>
                  ) : (
                    item.contractType === ContractTypes.Pets && (
                      <>
                        <StatValue
                          IconComponent={<ImHeart />}
                          backgroundColor="#e12939"
                          title="HP"
                          currentValue={item?.hp || 0}
                          value={item?.hp || 0}
                        />
                        <StatValue
                          IconComponent={<BsLightningChargeFill />}
                          backgroundColor="#7b21fc"
                          title="Energy"
                          currentValue={item?.energy || 0}
                          value={item?.energy || 0}
                        />
                        <StatValue
                          IconComponent={<GiClover />}
                          backgroundColor="#16b200"
                          title="Luckiness"
                          currentValue={item?.luckiness || 0}
                          value={item?.luckiness || 0}
                        />
                        <StatValue
                          IconComponent={<FaWind />}
                          backgroundColor="#cec505"
                          title="Speed"
                          currentValue={item?.speed || 0}
                          value={item?.speed || 0}
                        />
                      </>
                    )
                  )}
                </div>

                <div className="item-detail-price-buy">
                  <div className="item-detail-buy">
                    {item?.sellerAddress === account?.address ? (
                      <Button
                        text={
                          isCancelingListing ? "Canceling..." : "Cancel Listing"
                        }
                        disabled={isCancelingListing}
                        onClick={handlCancelListing}
                      />
                    ) : (item.contractType === ContractTypes.Elements &&
                        approvedTokens?.elementMarketIsApprovedToBuidlTokens) ||
                      (item.contractType === ContractTypes.Pets &&
                        approvedTokens?.starchiMarketIsApprovedToBuidlTokens) ? (
                      <Button
                        text={
                          isBuyingNFT
                            ? "Buying NFT..."
                            : `Buy ${
                                item.contractType === ContractTypes.Elements
                                  ? "Element"
                                  : item.contractType === ContractTypes.Pets
                                  ? "Starchi"
                                  : ""
                              } (${item.price} BUIDL)`
                        }
                        disabled={isBuyingNFT}
                        onClick={handleBuyNFT}
                      />
                    ) : (
                      <Button
                        text={
                          isApprovingToken
                            ? "Approving..."
                            : "Approve BUIDL for Market"
                        }
                        disabled={isApprovingToken}
                        onClick={handleApproveClick}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {item.contractType === ContractTypes.Pets && (
              <div className="item-detail-elements-container">
                <p className="item-detail-elements-description">
                  Attached Elements:
                </p>
                <div className="item-detail-elements-wrapper">
                  {item.elements && item.elements?.length > 0 ? (
                    item.elements?.map((element) => (
                      <div className="item-detail-elements-inner-content">
                        <img
                          src={ELEMENTS[element.typeId]?.image || undefined}
                          alt="icon"
                          className="card-image"
                        />
                        <div className="item-detail-elements-inner-content-props">
                          <div className="item-detail-stage-element">
                            <span className="quick-battle-stage-label">
                              Level:{" "}
                            </span>
                            <span className="quick-battle-stage-value">
                              {CARD_LEVELS[Number(element.level) - 1]}
                            </span>
                          </div>

                          <div className="item-detail-elements-inner-content-stats">
                            <StatValue
                              containerStyle={{ marginRight: "0.8rem" }}
                              IconComponent={<GiFist />}
                              backgroundColor="#e92222"
                              title="PowerRatio"
                              value={element.powerRatio}
                            />
                            <StatValue
                              IconComponent={<BsLightningChargeFill />}
                              backgroundColor="#7b21fc"
                              title="Energy"
                              value={element.energy}
                            />
                            <StatValue
                              IconComponent={
                                element?.damageType === 0 ? (
                                  <RiSwordLine />
                                ) : (
                                  <GiWingedScepter />
                                )
                              }
                              backgroundColor="#2a22e9"
                              title="DamageType"
                              value={
                                element?.damageType === 0 ? "Physic" : "Magic"
                              }
                            />
                            {item.effect !== 0 && item?.effect && (
                              <StatValue
                                IconComponent={<GiMagicPortal />}
                                backgroundColor="#18545a"
                                title="Effect"
                                value={`${EFFECTS[item.effect]?.name}`}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="item-detail-element-empty">--</p>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </StarchiBox>
    </div>
  );
};

export default ItemDetail;
