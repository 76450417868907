import { api } from "./api";

import Web3 from "web3";
import {
  buidlTokenAddress,
  elxrTokenAddress,
  starchiNftsAddress,
  elementNftsAddress,
  elementMarketAddress,
  starchiMarketAddress,
  petsAddress,
  nurturingAddress,
  battleAddress,
} from "./constants/addresses";

export const isDevEnv = process.env.NODE_ENV === "development";
export const isComingSoon = process.env.REACT_APP_COMING_SOON === "true";
export const isStaging = process.env.REACT_APP_STAGING === "true";

export const isUnityBattleV2 = !!process.env.REACT_APP_UNITY_BATTLE_PATH_V2;

export let whitelistAccounts = [];
const initWhitelistAccounts = async () => {
  const { data } = await api.get("https://docs.starchi.gg/beta-whitelist.json");
  whitelistAccounts = data.map((address) => address.toLowerCase());
};
initWhitelistAccounts();

export const unityBattleContextPath = isUnityBattleV2
  ? process.env.REACT_APP_UNITY_BATTLE_PATH_V2
  : process.env.REACT_APP_UNITY_BATTLE_PATH || "webgl";

export const unityTamagochiContextPath =
  process.env.REACT_APP_UNITY_TAMAGOCHI_PATH;

export const blockchainParams = {
  chainId: process.env.REACT_APP_BLOCKCHAIN_ID || "0x539",
  chainName: process.env.REACT_APP_BLOCKCHAIN_NAME,
  nativeCurrency: {
    name: process.env.REACT_APP_BLOCKCHAIN_CURRENCY_NAME,
    symbol: process.env.REACT_APP_BLOCKCHAIN_CURRENCY_SYMBOL,
    decimals: +process.env.REACT_APP_BLOCKCHAIN_DECIMALS,
  },
  rpcUrls: [process.env.REACT_APP_BLOCKCHAIN_RPC_URL],
  blockExplorerUrls: [process.env.REACT_APP_BLOCKCHAIN_EXPLORER_URL],
};

export const socketServerUrl =
  process.env.REACT_APP_BATTLE_SERVER_URL || "http://localhost:3001";

const stakingPoolsRpcUrl =
  process.env.REACT_APP_STAKING_POOLS_RPC_URL || "https://polygon-rpc.com/";

// TODO: at some point we need to unify the pools with the test environments
export const web3StakingPools = new Web3(stakingPoolsRpcUrl);

export const universalGasPrice =
  process.env.REACT_APP_UNIVERSAL_GAS_PRICE || "200";

export const blockchainAddresses = {
  buidlToken:
    process.env.REACT_APP_BLOCKCHAIN_ADDRESS_BUIDLTOKEN || buidlTokenAddress,
  elxrToken:
    process.env.REACT_APP_BLOCKCHAIN_ADDRESS_ELXRTOKEN || elxrTokenAddress,
  starchiNfts:
    process.env.REACT_APP_BLOCKCHAIN_ADDRESS_STARCHINFTS || starchiNftsAddress,
  elementNfts:
    process.env.REACT_APP_BLOCKCHAIN_ADDRESS_ELEMENTNFTS || elementNftsAddress,
  elementMarket:
    process.env.REACT_APP_BLOCKCHAIN_ADDRESS_ELEMENTMARKET ||
    elementMarketAddress,
  starchiMarket:
    process.env.REACT_APP_BLOCKCHAIN_ADDRESS_STARCHIMARKET ||
    starchiMarketAddress,
  pets: process.env.REACT_APP_BLOCKCHAIN_ADDRESS_PETS || petsAddress,
  nurturing:
    process.env.REACT_APP_BLOCKCHAIN_ADDRESS_NURTURING || nurturingAddress,
  battle: process.env.REACT_APP_BLOCKCHAIN_ADDRESS_BATTLE || battleAddress,
};

if (isDevEnv) {
  console.info("Loaded Blockchain Params >>>", blockchainParams);
  console.info("Loaded Blockchain Addresses >>>", blockchainAddresses);
}
