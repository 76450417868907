import { SelectHTMLAttributes } from "react";
import "./styles.scss";

interface OptionProps {
  value: string;
  label: string | undefined;
}

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string;
  options: OptionProps[];
};

const Select: React.FC<SelectProps> = ({ label = "", options, ...props }) => {
  return (
    <div className="input-container">
      <span className="input-label">{label}</span>
      <select className="input-box" id="element" {...props}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
