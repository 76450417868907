import { Contract } from "ethers";
import { blockchainAddresses } from "../../config";
import promiseWithTimeout from "../../utils/promiseWithTimeout";
import { StarchiMarket, ElementMarket } from "../types/contracts";

import starchiMarketAbi from "./abis/StarchiMarket.json";
import elementMarketAbi from "./abis/ElementMarket.json";

export const starchiSellExecuted = async (provider: any, tokenId: number) => {
  const starchiMarketContract = new Contract(
    blockchainAddresses.starchiMarket,
    starchiMarketAbi,
    provider?.getSigner(),
  ) as StarchiMarket;
  const startBlockNumber = (await provider.getBlockNumber()) - 20;

  let transactionHash;

  const eventWatcher = new Promise((resolve) => {
    starchiMarketContract.on(
      "SellExecuted",
      (_tokenId, listingPrice, fee, sellerAddress, buyerAddress, _event) => {
        if (_event.blockNumber <= startBlockNumber) return;

        transactionHash = _event.transactionHash;

        if (tokenId === _tokenId.toNumber()) {
          console.log("NFT was sucessfully sold.");

          resolve(tokenId);
        }
      },
    );
  });

  await promiseWithTimeout(
    150000,
    eventWatcher,
    "NFT market timeout, please go to CRATE to check if your starchi is already there!",
  );
  starchiMarketContract.off(
    "SellExecuted",
    (_tokenId, listingPrice, fee, sellerAddress, buyerAddress, _event) => {
      if (_event.blockNumber <= startBlockNumber) return;

      transactionHash = _event.transactionHash;

      if (tokenId === _tokenId.toNumber()) {
        console.log("NFT was sucessfully sold.");
      }
    },
  );

  const transaction = await provider.getTransaction(transactionHash);
  await transaction.wait();
};

export const elementSellExecuted = async (provider: any, tokenId: number) => {
  const elementMarketContract = new Contract(
    blockchainAddresses.elementMarket,
    elementMarketAbi,
    provider?.getSigner(),
  ) as ElementMarket;
  const startBlockNumber = (await provider.getBlockNumber()) - 20;

  let transactionHash;

  const eventWatcher = new Promise((resolve) => {
    elementMarketContract.on(
      "SellExecuted",
      (_tokenId, listingPrice, fee, sellerAddress, buyerAddress, _event) => {
        if (_event.blockNumber <= startBlockNumber) return;

        transactionHash = _event.transactionHash;

        if (tokenId === _tokenId.toNumber()) {
          console.log("NFT was sucessfully sold.");

          resolve(tokenId);
        }
      },
    );
  });

  await promiseWithTimeout(
    150000,
    eventWatcher,
    "NFT market timeout, please go to CRATE to check if your element is already there!",
  );
  elementMarketContract.off(
    "SellExecuted",
    (_tokenId, listingPrice, fee, sellerAddress, buyerAddress, _event) => {
      if (_event.blockNumber <= startBlockNumber) return;

      transactionHash = _event.transactionHash;

      if (tokenId === _tokenId.toNumber()) {
        console.log("NFT was sucessfully sold.");
      }
    },
  );

  const transaction = await provider.getTransaction(transactionHash);
  await transaction.wait();
};
