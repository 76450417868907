import { Contract } from "ethers";

import { Battle } from "../types/contracts";

import battleAbi from "./abis/Battle.json";
import { blockchainAddresses } from "../../config";
import promiseWithTimeout from "../../utils/promiseWithTimeout";
import parseReceiptEvents from "../../utils/parseReceiptEvents";

const TOLERANCE_BLOCKS_TO_LISTEN_BATTLE_FINISH_EVENT = 20;

export const arenasByPlayer = async (provider: any, account: string) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;
  return battleContract.getArenasByPlayer(account);
};

export const busyArenas = async (provider: any) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;
  return battleContract.getBusyArenas();
};

export const percentageFactor = (provider: any) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;
  return battleContract.getPercentageFactor();
};

export const energyMinPercentage = async (provider: any) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;
  const battleSettings = await battleContract.getBattleSettings();
  return battleSettings.energyMinPercentage;
};

export const hungerMinPercentage = async (provider: any) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;
  const battleSettings = await battleContract.getBattleSettings();
  return battleSettings.hungerMinPercentage;
};

export const hygieneMinPercentage = async (provider: any) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;
  const battleSettings = await battleContract.getBattleSettings();
  return battleSettings.hygieneMinPercentage;
};

export const exerciseMinPercentage = async (provider: any) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;
  const battleSettings = await battleContract.getBattleSettings();
  return battleSettings.exerciseMinPercentage;
};

export const battleFinished = async (provider: any, arenaId: string) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;
  const startBlockNumber =
    (await provider.getBlockNumber()) -
    TOLERANCE_BLOCKS_TO_LISTEN_BATTLE_FINISH_EVENT;

  let transactionHash;

  const eventWatcher = new Promise((resolve) => {
    battleContract.on(
      "BattleFinished",
      (_arenaId, winner, loser, winnerPets, loserPets, _event) => {
        if (_event.blockNumber <= startBlockNumber) return;

        transactionHash = _event.transactionHash;

        if (arenaId === _arenaId.toString()) {
          console.log("Battle has finished.");

          resolve(arenaId);
        }
      },
    );
  });

  await promiseWithTimeout(
    150000,
    eventWatcher,
    "Finish battle timeout, please go to ARENA to check if your battle is still there!",
  );
  battleContract.off(
    "BattleFinished",
    (_arenaId, winner, loser, winnerPets, loserPets, _event) => {
      if (_event.blockNumber <= startBlockNumber) return;

      transactionHash = _event.transactionHash;

      if (arenaId === _arenaId.toString()) {
        console.log("Battle has finished.");
      }
    },
  );

  const transaction = await provider.getTransaction(transactionHash);
  await transaction.wait();
};

export const arenas = async (provider: any, index: string) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;
  return battleContract.getArena(index);
};

export const maxArenas = async (provider: any) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;
  const arenaConfig = await battleContract.getArenaConfig();

  return arenaConfig.maxArenas;
};

export const hostingArenaPrice = async (provider: any) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;
  const arenaConfig = await battleContract.getArenaConfig();

  return arenaConfig.hostingArenaPrice;
};

export const getPendingArenas = async (provider: any) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;
  return battleContract.getPendingArenas();
};

export const pendingPlayerRewards = async (provider: any, account: string) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;
  return battleContract.getPendingPlayerRewards(account);
};

export const hostBattle = async (mode: string, provider: any) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;

  const tx = await battleContract.hostBattle(mode);
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  const { BattleHosted } = parseReceiptEvents(
    battleContract.interface,
    receipt,
  );

  return { BattleHosted, receipt };
};

export const joinBattle = async (arenaId: string, provider: any) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;

  const tx = await battleContract.joinBattle(arenaId);
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  return receipt;
};

export const cancelBattle = async (provider: any) => {
  const battleContract = new Contract(
    blockchainAddresses.battle,
    battleAbi,
    provider?.getSigner(),
  ) as Battle;

  const tx = await battleContract.cancelBattle();
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  return receipt;
};
