import { useCallback, useState } from "react";
import { ContractReceipt } from "ethers";

import {
  attachElement,
  getAllowedElementsByBreedId,
  detachElement,
  mintElementFinished,
  requestMintElement,
} from "../api/chain/pets";
import { useAccount } from "../contexts/AccountContext";
import { useContractsParams } from "../contexts/ContractsParamsContext";
import {
  ElementWithDetailsProps,
  listElementsWithDetailsByAccount,
} from "../api/elements";

interface UseElementsProps {
  myElements: ElementWithDetailsProps[];
  isLoadingMyElements: boolean;
  fetchMyElements: () => Promise<ElementWithDetailsProps[]>;
  fetchAllowedElementsByBreedId: (breedId: string) => Promise<number[]>;
  mintElement: (elementType: string) => Promise<void>;
  attachElementToPet: (
    petId: string,
    elementId: number,
  ) => Promise<ContractReceipt>;
  detachElementFromPet: (
    petId: string,
    elementId: number,
  ) => Promise<ContractReceipt>;
}

export const useElements = (): UseElementsProps => {
  const { account, provider } = useAccount();

  const [myElements, setMyElements] = useState<ElementWithDetailsProps[]>([]);
  const [isLoadingMyElements, setIsLoadingMyElements] = useState(false);
  const { approvePetsToNFTs, approvedTokens } = useContractsParams();

  const fetchElementsByOwner = useCallback(
    (owner) => {
      if (!owner || !provider) {
        return [];
      }
      return listElementsWithDetailsByAccount(provider, owner);
    },
    [provider],
  );

  const fetchMyElements = useCallback(async () => {
    if (!account.address) {
      return [];
    }
    setIsLoadingMyElements(true);
    const elements = await fetchElementsByOwner(account.address);
    setMyElements(elements);
    setIsLoadingMyElements(false);
    return elements;
  }, [account.address, fetchElementsByOwner]);

  const watchElementMinting = useCallback(
    async (elementType): Promise<void> => {
      if (account.address) {
        await mintElementFinished(provider, account.address, elementType);
      }
    },
    [account.address, provider],
  );

  const mintElement = useCallback(
    async (elementType) => {
      await requestMintElement(elementType, provider);
      await watchElementMinting(elementType);
    },
    [provider, watchElementMinting],
  );

  const fetchAllowedElementsByBreedId = useCallback(
    (breedId) => getAllowedElementsByBreedId(provider, breedId),
    [provider],
  );

  const attachElementToPet = useCallback(
    async (petId, elementId) => {
      if (!approvedTokens.petIsApprovedToElementNFTs) {
        await approvePetsToNFTs();
      }

      return attachElement(petId, elementId, provider);
    },
    [provider, approvePetsToNFTs, approvedTokens.petIsApprovedToElementNFTs],
  );

  const detachElementFromPet = useCallback(
    async (petId, elementId) => {
      if (!approvedTokens.petIsApprovedToElementNFTs) {
        await approvePetsToNFTs();
      }

      return detachElement(petId, elementId, provider);
    },
    [provider, approvePetsToNFTs, approvedTokens.petIsApprovedToElementNFTs],
  );

  return {
    myElements,
    isLoadingMyElements,
    fetchMyElements,
    fetchAllowedElementsByBreedId,
    mintElement,
    attachElementToPet,
    detachElementFromPet,
  };
};
