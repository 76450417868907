import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import images from "../../../../images";
import "../../styles.scss";

import Button from "../../../../components/Button";

const About: React.FC = () => {
  return (
    <div className="learnmore-section">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow className="col-center">
          <div className="title-group">
            <div className="section-title text-center learnmore-section-title">
              A compelling and rewarding game
            </div>
            <div className="section-title text-center learnmore-section-title">
              about the power of love and care
            </div>
          </div>
          <div className="learnmore-section-content1">
            The World of Starchi is overrun with greed, strife, misery, hunger
            and indifference. Humanity has lost its way and has forgotten its
            purpose, but most of all, it has forgotten that it alone does not
            inhabit the Earth. A darkness has spread across the planet,
            invisible to all but a species of never before seen creatures,
            called Starchi. Having dwelled within the Earth, and having survived
            on the energy of the Sun, the Starchi have now come out in search of
            new energy, for the Sun no longer shines upon their homes.
          </div>
        </MDBRow>
        <MDBRow className="space-one" />
        <MDBRow>
          <MDBCol md="3">
            <img className="book" alt="" src={images.Book} />
          </MDBCol>
          <MDBCol md="6" className="col-center">
            <span className="learnmore-title">The Bonding of Two Kinds</span>
            <div className="learnmore-section-content2">
              In the early days, most humans despised the Starchi, so they
              wandered the Land, Seas and Skies in search of food, love and a
              new home. That is all until one fine day, humans approach a
              Starchi and find a way to befriend it. Lo and behold, Starchis and
              Humans slowly begin to form a bond and the relationship between
              the two kinds forms.
            </div>
            <div className="btn-parent">
              <Button
                style={{ marginTop: "1rem" }}
                size="lg"
                text="LEARN MORE"
                onClick={() => {
                  window.open("https://docs.starchi.gg/", "_blank");
                }}
              />
            </div>
          </MDBCol>
          <MDBCol md="3">
            <img className="eggs" alt="" src={images.Eggs} />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default About;
