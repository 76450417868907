import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import Button from "../../../../components/Button";
import SelectInput from "../../../../components/SelectInput";
import { ELEMENTS } from "../../../../constants/constants";
import { useContractsParams } from "../../../../contexts/ContractsParamsContext";
import "../styles.scss";

const override = css`
  display: block;
  margin: 0 auto;
`;

interface MintElementModalProps {
  modalOpen: boolean;
  isMintingElement: boolean;
  onClose(): void;
  onConfirm(elementType: number): void;
}

const MintElementModal: React.FC<MintElementModalProps> = ({
  modalOpen,
  isMintingElement,
  onClose,
  onConfirm,
}) => {
  const [elementType, setElementType] = useState(1);

  const { mintElementPrice } = useContractsParams();

  // reset element card upon modal reopening
  useEffect(() => setElementType(1), [modalOpen]);

  return (
    <Modal
      show={modalOpen}
      size="lg"
      centered
      contentClassName="manage-modal-content"
      onHide={onClose}
    >
      <div className="manage-modal-bg" />
      <ModalHeader bsPrefix="manage-modal-header">
        <span className="manage-modal-heading-lg">
          {!isMintingElement && <div>CHOOSE ELEMENT TYPE</div>}
          {isMintingElement && <div>YOUR ELEMENT IS MINTING...</div>}
        </span>
        {!isMintingElement && (
          <CloseIcon className="manage-modal-close-icon" onClick={onClose} />
        )}
      </ModalHeader>
      <ModalBody bsPrefix="name-change-modal-body">
        {!isMintingElement && (
          <div>
            <div className="element-modal-section">
              <SelectInput
                placeholder="element type"
                value={elementType}
                options={ELEMENTS.slice(1).map((element) => ({
                  value: `${element?.id}`,
                  label: element?.name,
                }))}
                onChange={(ev) => {
                  setElementType(Number(ev.target.value));
                }}
              />
            </div>
            <div className="name-change-modal-confirm">
              <Button
                text={
                  mintElementPrice
                    ? `Mint Element (${mintElementPrice} BUIDL)`
                    : "loading..."
                }
                onClick={() => {
                  if (elementType >= 1) {
                    onConfirm(elementType);
                  }
                }}
              />
            </div>
          </div>
        )}

        <ClipLoader
          color="#fff"
          loading={isMintingElement}
          css={override}
          size={100}
        />
      </ModalBody>
    </Modal>
  );
};

export default MintElementModal;
