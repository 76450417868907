import { ElementWithDetailsProps, getElementWithDetails } from "./elements";
import { PetStats } from "./interfaces/INurturing";
import { PetProps, PetStage } from "./interfaces/IPet";
import { getPetAndUpdatedStats } from "./chain/nurturing";
import { getPetElements, getPets } from "./chain/pets";

export interface PetWithDetailsProps extends PetProps {
  petId: number;
  sleeping: boolean;
  nurturingStats: PetStats;
  elements: ElementWithDetailsProps[];
  stage: PetStage;
  image: string | null;
}

const isSleeping = (lastBedAt: number, lastAwakeAt: number): boolean => {
  return lastBedAt >= lastAwakeAt;
};

interface GetPetElementsWithDetailsProps {
  petId: number;
  provider: any;
}

export const getPetElementsWithDetails = async ({
  petId,
  provider,
}: GetPetElementsWithDetailsProps) => {
  const petElementsIds = await getPetElements(provider, petId);

  return Promise.all(
    petElementsIds.map(async (elementId) =>
      getElementWithDetails(provider, elementId.toNumber()),
    ),
  );
};

export const getPetWithDetails = async (
  provider: any,
  petId: number,
): Promise<PetWithDetailsProps> => {
  const petData = await getPetAndUpdatedStats(provider, petId);
  const pet = { ...petData[0] };
  const nurturingStats = petData[1];

  const petWithDetails: PetWithDetailsProps = {
    petId,
    name: pet.name,
    typeId: pet.typeId,
    breedId: pet.breedId,
    phase: pet.phase,
    evolutionAt: pet.evolutionAt.toNumber(),
    createdAt: pet.createdAt.toNumber(),
  } as PetWithDetailsProps;

  // init Value
  petWithDetails.sleeping = isSleeping(
    nurturingStats.energy.lastBedAt.toNumber(),
    nurturingStats.energy.lastAwakeAt.toNumber(),
  );

  petWithDetails.nurturingStats = {
    energy: {
      ...nurturingStats.energy,
      lastBedAt: nurturingStats.energy.lastBedAt.toNumber(),
      lastAwakeAt: nurturingStats.energy.lastAwakeAt.toNumber(),
    },
    exercise: {
      ...nurturingStats.exercise,
      lastExercisedAt: nurturingStats.exercise.lastExercisedAt.toNumber(),
    },
    hunger: {
      ...nurturingStats.hunger,
      lastFedAt: nurturingStats.hunger.lastFedAt.toNumber(),
    },
    hygiene: {
      ...nurturingStats.hygiene,
      lastShowerAt: nurturingStats.hygiene.lastShowerAt.toNumber(),
    },
  };

  petWithDetails.attributes = {
    hungerPoints: pet.attributes.hungerPoints,
    exercisePoints: pet.attributes.exercisePoints,
    hygienePoints: pet.attributes.hygienePoints,
    energyPoints: pet.attributes.energyPoints,
  };

  petWithDetails.elements = await getPetElementsWithDetails({
    petId,
    provider,
  });

  if (pet.phase === 0) {
    petWithDetails.stage = "Egg";
    petWithDetails.image = `/img/eggs/${pet.typeId}.png`;
  } else {
    if (pet.phase === 1) {
      petWithDetails.stage = "Child";
    } else if (pet.phase === 2) {
      petWithDetails.stage = "Teen";
    } else if (pet.phase === 3) {
      petWithDetails.stage = "Adult";
    }
    petWithDetails.image = `/img/pets/${pet.typeId}-${pet.phase}.png`;
  }
  return petWithDetails;
};

export const listPetsWithDetailsByAccount = async (
  provider: any,
  account: string,
) => {
  const petIds = await getPets(provider, account);
  const petsWithDetails = await Promise.all(
    petIds.map((petId) => getPetWithDetails(provider, petId.toNumber())),
  );
  return petsWithDetails;
};
