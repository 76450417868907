import { useCallback, useEffect, useState } from "react";

import { expandFixed } from "../constants/constants";
import { getStakingPools } from "../api/pools";
import { useAccount } from "../contexts/AccountContext";
import { web3StakingPools } from "../config";
import * as Pool from "../api/chain/pool";

export const usePools = () => {
  const { account, web3 } = useAccount();
  const [items, setItems] = useState({});
  const [isLoading, setLoading] = useState(true);

  const fetchPools = useCallback(async () => {
    setLoading(true);
    const fetchedPools = await getStakingPools(
      account.address,
      web3StakingPools,
    );
    setItems(fetchedPools);
    setLoading(false);
  }, [account.address]);

  useEffect(() => {
    fetchPools();
    const interval = setInterval(fetchPools, 10000);
    return () => clearInterval(interval);
  }, [fetchPools]);

  const approve = useCallback(
    async (pool) => {
      if (!web3) {
        return;
      }

      await Pool.approvePool(
        web3,
        pool.token.address,
        pool.pool,
        account.address,
      );
    },
    [account.address, web3],
  );

  const stake = useCallback(
    async (pool, amount, lockUntil) => {
      const fixedAmount = expandFixed(amount, pool?.token?.decimals).toString();
      await Pool.stake(
        web3,
        pool.pool,
        fixedAmount,
        lockUntil,
        account.address,
      );
      await fetchPools();
    },
    [account.address, web3, fetchPools],
  );

  const claim = useCallback(
    async (pool) => {
      await Pool.claim(web3, pool.pool, account.address);
      await fetchPools();
    },
    [account.address, web3, fetchPools],
  );

  return { items, isLoading, approve, stake, claim };
};
