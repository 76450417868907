import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";

import "mdbreact/dist/css/mdb.css";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";

import App from "./pages/App";
import Home from "./pages/Home";
import Game from "./pages/Game";
import Store from "./pages/Store";
import Crate from "./pages/Crate";
import CrateV2 from "./pages/Crate/CrateV2";
import Arena from "./pages/Arena";
import Rank from "./pages/Rank/Rank";
import Staking from "./pages/Staking/Staking";
import NotFound from "./pages/NotFound";
import ComingSoon from "./pages/ComingSoon";

import Footer from "./components/Footer";
import Header from "./components/Header";
import { isComingSoon } from "./config";
import reportWebVitals from "./reportWebVitals";

import "./styles.scss";

const battleComponent = Game;
const storeComponent = Store;
const crateComponent = CrateV2;
const classicCrateComponent = Crate;
const arenaComponent = Arena;
let rankComponent: React.FC = Rank;
if (isComingSoon) {
  // battleComponent = ComingSoon;
  // storeComponent = ComingSoon;
  // crateComponent = ComingSoon;
  // arenaComponent = ComingSoon;
  rankComponent = ComingSoon;
}

ReactDOM.render(
  <StrictMode>
    <Router>
      <App>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/battle" component={battleComponent} />
          <Route path="/crate" component={crateComponent} />
          <Route path="/crate-classic" component={classicCrateComponent} />
          <Route path="/rank" component={rankComponent} />
          <Route path="/arena" component={arenaComponent} />
          <Route path="/store" component={storeComponent} />
          <Route path="/staking" component={Staking} />
          <Route path="*" component={NotFound} />
        </Switch>
        <Footer />
      </App>
    </Router>
  </StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
