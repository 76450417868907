import { useCallback } from "react";
import ReactPlayer from "react-player";
import "./styles.scss";

interface PopUpProps {
  toggle: () => void;
}

const PopUp: React.FC<PopUpProps> = ({ toggle }) => {
  const handleClick = useCallback(() => {
    toggle();
  }, [toggle]);

  return (
    <div className="modal1">
      <div className="modal_content">
        <button type="button" className="close" onClick={handleClick}>
          &times;
        </button>

        <div className="player-trailer">
          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=LF1DRJWzZa4"
            width="100%"
            height="100%"
            controls
          />
        </div>
      </div>
    </div>
  );
};

export default PopUp;
