import { useCallback } from "react";
import { ethers } from "ethers";

import { formatDistanceStrict } from "date-fns";

import { useAccount } from "../contexts/AccountContext";
import { useContractsParams } from "../contexts/ContractsParamsContext";

import { toastError } from "../utils/errorHandlers";

import {
  awakePet,
  bedPet,
  exercisePet,
  feedPet,
  getFeedPrice,
  getShowerPrice,
  showerPet,
} from "../api/chain/nurturing";

import { getPetWithDetails } from "../api/pets";

interface UseNurturingProps {
  fetchFeedPrice: (petId: number) => Promise<string>;
  fetchShowerPrice: (petId: number) => Promise<string>;
  feedMyPet: (petId: number) => Promise<boolean>;
  showerMyPet: (petId: number) => Promise<boolean>;
  exerciseMyPet: (petId: number) => Promise<boolean>;
  bedMyPet: (petId: number) => Promise<boolean>;
  awakeMyPet: (petId: number) => Promise<boolean>;
}

export const useNurturing = (): UseNurturingProps => {
  const { provider } = useAccount();

  const {
    hungerSecondsTimeout,
    showerSecondsTimeout,
    exerciseSecondsTimeout,
    sleepSecondsTimeout,
    wakeSecondsTimeout,
  } = useContractsParams();

  const fetchFeedPrice = useCallback(
    async (petId: number) => {
      const feedCostAndHungerPoints = await getFeedPrice(provider, petId);
      const rawFeedCost = feedCostAndHungerPoints[0];

      const formattedFeedCost = ethers.utils.formatEther(rawFeedCost);

      return formattedFeedCost;
    },
    [provider],
  );

  const fetchShowerPrice = useCallback(
    async (petId: number) => {
      const hygieneCostAndHygienePoints = await getShowerPrice(provider, petId);
      const rawShowerCost = hygieneCostAndHygienePoints[0];

      const formattedShowerCost = ethers.utils.formatEther(rawShowerCost);

      return formattedShowerCost;
    },
    [provider],
  );

  const formatTimeoutDistance = useCallback(
    async (message, petId) => {
      if (!provider) {
        return undefined;
      }
      const errorPet = await getPetWithDetails(provider, petId);
      let lastActionTimestamp = 0;
      let actionTimeoutInSeconds = 0;

      if (message.indexOf("STILL_SLEEPING") >= 0) {
        lastActionTimestamp = Number(errorPet.nurturingStats.energy.lastBedAt);

        actionTimeoutInSeconds = wakeSecondsTimeout ?? 0;
      }

      if (message.indexOf("EXERCISE_TIMEOUT") >= 0) {
        lastActionTimestamp = Number(
          errorPet.nurturingStats.exercise.lastExercisedAt,
        );

        actionTimeoutInSeconds = exerciseSecondsTimeout ?? 0;
      }

      if (message.indexOf("SLEEP_TIMEOUT") >= 0) {
        lastActionTimestamp = Number(
          errorPet.nurturingStats.energy.lastAwakeAt,
        );

        actionTimeoutInSeconds = sleepSecondsTimeout ?? 0;
      }

      if (message.indexOf("FEED_TIMEOUT") >= 0) {
        lastActionTimestamp = Number(errorPet.nurturingStats.hunger.lastFedAt);

        actionTimeoutInSeconds = hungerSecondsTimeout ?? 0;
      }

      if (message.indexOf("SHOWER_TIMEOUT") >= 0) {
        lastActionTimestamp = Number(
          errorPet.nurturingStats.hygiene.lastShowerAt,
        );
        actionTimeoutInSeconds = showerSecondsTimeout ?? 0;
      }
      const lastActionInDate = new Date(lastActionTimestamp * 1000);
      const alreadyWaitedMilliseconds = Math.abs(
        lastActionInDate.getTime() - new Date().getTime(),
      );

      const formattedTimeout = formatDistanceStrict(
        new Date().setMilliseconds(alreadyWaitedMilliseconds),
        new Date().setSeconds(actionTimeoutInSeconds),
      );

      return formattedTimeout;
    },
    [
      provider,
      wakeSecondsTimeout,
      exerciseSecondsTimeout,
      sleepSecondsTimeout,
      hungerSecondsTimeout,
      showerSecondsTimeout,
    ],
  );

  const feedMyPet = useCallback(
    async (petId: number) => {
      try {
        const receipt = await feedPet(petId, provider);
        if (receipt?.status) {
          return true;
        }
      } catch (error: any) {
        console.error("NURTURING ERROR", error);

        let message = error?.data?.message ?? String(error);

        if (
          typeof message === "string" &&
          message?.indexOf("FEED_TIMEOUT") >= 0
        ) {
          const formattedTimeout = await formatTimeoutDistance(message, petId);

          message = `You cannot feed your Starchi for the next ${formattedTimeout}.`;
        }

        console.log("message", message);

        toastError(message);
      }
      return false;
    },
    [provider, formatTimeoutDistance],
  );

  const showerMyPet = useCallback(
    async (petId: number) => {
      try {
        const receipt = await showerPet(petId, provider);
        if (receipt?.status) {
          return true;
        }
      } catch (error: any) {
        console.error("NURTURING ERROR", error);
        let message = error?.data?.message ?? String(error);

        if (
          typeof message === "string" &&
          message?.indexOf("SHOWER_TIMEOUT") >= 0
        ) {
          const formattedTimeout = await formatTimeoutDistance(message, petId);

          message = `You cannot shower your Starchi for the next ${formattedTimeout}.`;
        }

        toastError(message);
      }
      return false;
    },
    [provider, formatTimeoutDistance],
  );

  const exerciseMyPet = useCallback(
    async (petId: number) => {
      try {
        const receipt = await exercisePet(petId, provider);
        if (receipt?.status) {
          return true;
        }
      } catch (error: any) {
        console.error("NURTURING ERROR", error);
        let message = error?.data?.message ?? String(error);

        if (
          typeof message === "string" &&
          message?.indexOf("EXERCISE_TIMEOUT") >= 0
        ) {
          const formattedTimeout = await formatTimeoutDistance(message, petId);

          message = `You cannot exercise your Starchi for the next ${formattedTimeout}.`;
        }

        toastError(message);
      }
      return false;
    },
    [provider, formatTimeoutDistance],
  );

  const bedMyPet = useCallback(
    async (petId: number) => {
      try {
        const receipt = await bedPet(petId, provider);
        if (receipt?.status) {
          return true;
        }
      } catch (error: any) {
        console.error("NURTURING ERROR", error);
        let message = error?.data?.message ?? String(error);

        if (
          typeof message === "string" &&
          message?.indexOf("SLEEP_TIMEOUT") >= 0
        ) {
          const formattedTimeout = await formatTimeoutDistance(message, petId);

          message = `You cannot put your Starchi to sleep for the next ${formattedTimeout}.`;
        }

        toastError(message);
      }
      return false;
    },
    [provider, formatTimeoutDistance],
  );

  const awakeMyPet = useCallback(
    async (petId: number) => {
      try {
        const receipt = await awakePet(petId, provider);
        if (receipt?.status) {
          return true;
        }
      } catch (error: any) {
        console.error("NURTURING ERROR", error);
        let message = error?.data?.message ?? String(error);

        if (
          typeof message === "string" &&
          message?.indexOf("STILL_SLEEPING") >= 0
        ) {
          const formattedTimeout = await formatTimeoutDistance(message, petId);

          message = `You cannot awake your Starchi for the next ${formattedTimeout}.`;
        }

        toastError(message);
      }
      return false;
    },
    [provider, formatTimeoutDistance],
  );

  return {
    fetchFeedPrice,
    fetchShowerPrice,
    feedMyPet,
    showerMyPet,
    exerciseMyPet,
    bedMyPet,
    awakeMyPet,
  };
};
