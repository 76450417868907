import { Contract } from "ethers";

import { blockchainAddresses } from "../../config";
import nurturingAbi from "./abis/Nurturing.json";
import { Nurturing } from "../types/contracts";

export const getPetAndUpdatedStats = async (provider: any, petId: number) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;
  return nurturingContract.getPetAndUpdatedStats(petId);
};

export const hungerTimeoutSeconds = async (provider: any) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;
  const hungerConfig = await nurturingContract.getHungerConfig();
  return hungerConfig.hungerTimeoutSeconds;
};

export const hygieneTimeoutSeconds = async (provider: any) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;
  const hygieneConfig = await nurturingContract.getHygieneConfig();
  return hygieneConfig.hygieneTimeoutSeconds;
};

export const exerciseTimeoutSeconds = async (provider: any) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;
  const exerciseConfig = await nurturingContract.getExerciseConfig();
  return exerciseConfig.exerciseTimeoutSeconds;
};

export const energySleepTimeoutSeconds = async (provider: any) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;
  const energyConfig = await nurturingContract.getEnergyConfig();
  return energyConfig.energySleepTimeoutSeconds;
};

export const energySleepLockedSeconds = async (provider: any) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;
  const energyConfig = await nurturingContract.getEnergyConfig();
  return energyConfig.energySleepLockedSeconds;
};

export const hungerPointsPrice = async (provider: any) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;
  const hungerConfig = await nurturingContract.getHungerConfig();
  return hungerConfig.hungerPointsPrice;
};

export const getFeedPrice = async (provider: any, petId: number) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;
  return nurturingContract.getFeedPrice(petId);
};

export const hygienePointsPrice = async (provider: any) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;
  const hygieneConfig = await nurturingContract.getHygieneConfig();
  return hygieneConfig.hygienePointsPrice;
};

export const getShowerPrice = async (provider: any, petId: number) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;
  return nurturingContract.getShowerPrice(petId);
};

export const feedPet = async (petId: number, provider: any) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;

  const tx = await nurturingContract.feedPet(petId);
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  return receipt;
};

export const showerPet = async (petId: number, provider: any) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;

  const tx = await nurturingContract.showerPet(petId);
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  return receipt;
};

export const exercisePet = async (petId: number, provider: any) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;

  const tx = await nurturingContract.exercisePet(petId);
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  return receipt;
};

export const bedPet = async (petId: number, provider: any) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;

  const tx = await nurturingContract.bedPet(petId);
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  return receipt;
};

export const awakePet = async (petId: number, provider: any) => {
  const nurturingContract = new Contract(
    blockchainAddresses.nurturing,
    nurturingAbi,
    provider?.getSigner(),
  ) as Nurturing;

  const tx = await nurturingContract.awakePet(petId);
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  return receipt;
};
