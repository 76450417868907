import { useHistory } from "react-router";
import Button from "../../components/Button";

import "./styles.scss";

const NotFound: React.FC = () => {
  const history = useHistory();

  return (
    <div className="not-found-container">
      <h1 className="not-found-title">Ops! Page Not Found</h1>
      <Button
        size="md"
        text="Go to Starchi Home Page"
        onClick={() => {
          history.replace("/");
        }}
      />
    </div>
  );
};

export default NotFound;
