import { useHistory } from "react-router";
import { FaWind } from "react-icons/fa";
import {
  GiClover,
  GiFist,
  GiMagicPortal,
  GiWingedScepter,
} from "react-icons/gi";
import { RiSwordLine } from "react-icons/ri";
import { BsLightningChargeFill } from "react-icons/bs";
import { ImHeart } from "react-icons/im";

import NameBadge from "../../../../components/StarchiNameBadge";
import StatValue from "../../../../components/StatValue";
import { ContractTypes, TokenProps } from "../../contexts/StoreContext";
import "./styles.scss";
import { EFFECTS } from "../../../../constants/constants";

interface CardProps {
  item: TokenProps;
}

const Card: React.FC<CardProps> = ({ item }) => {
  const history = useHistory();

  return (
    <button
      type="button"
      className="card-container"
      onClick={() => {
        history.push(
          `/store/buy/${item.tokenId}?contractType=${item.contractType}`,
        );
      }}
    >
      <div className="card-bg" />
      <div className="sale-card-body">
        <NameBadge
          name={item.name}
          id={item.tokenId}
          petBreedId={
            item.contractType === ContractTypes.Pets
              ? String(item?.breedId)
              : ""
          }
          elementId={
            item.contractType === ContractTypes.Elements
              ? String(item.typeId)
              : ""
          }
        />

        <div className="card-image-container">
          <img
            src={item.image || undefined}
            alt="icon"
            className="card-image"
          />
        </div>

        {item.contractType === ContractTypes.Pets && (
          <div className="card-elements-container">
            <p className="card-elements-description">Attached Elements:</p>
            <div className="card-elements-wrapper">
              {item.elements && item.elements?.length > 0 ? (
                item.elements?.map((element) => (
                  <p className="card-element-name">{element.name}</p>
                ))
              ) : (
                <p className="card-element-empty">--</p>
              )}
            </div>
          </div>
        )}

        <div className="card-stats-container">
          {item.contractType === ContractTypes.Elements ? (
            <>
              <StatValue
                IconComponent={<GiFist />}
                backgroundColor="#e92222"
                title="PowerRatio"
                value={item?.powerRatio || 0}
              />
              <StatValue
                IconComponent={<BsLightningChargeFill />}
                backgroundColor="#7b21fc"
                title="Energy"
                value={item?.energy || 0}
              />
              <StatValue
                IconComponent={
                  item?.damageType === 0 ? <RiSwordLine /> : <GiWingedScepter />
                }
                backgroundColor="#2a22e9"
                title="DamageType"
                value={item?.damageType === 0 ? "Physic" : "Magic"}
              />
              {item.effect !== 0 && item?.effect && (
                <StatValue
                  IconComponent={<GiMagicPortal />}
                  backgroundColor="#18545a"
                  title="Effect"
                  value={`${EFFECTS[item.effect]?.name}`}
                />
              )}
            </>
          ) : (
            item.contractType === ContractTypes.Pets && (
              <>
                <StatValue
                  IconComponent={<ImHeart />}
                  backgroundColor="#e12939"
                  title="HP"
                  currentValue={item?.hp || 0}
                  value={item?.hp || 0}
                />
                <StatValue
                  IconComponent={<BsLightningChargeFill />}
                  backgroundColor="#7b21fc"
                  title="Energy"
                  currentValue={item?.energy || 0}
                  value={item?.energy || 0}
                />
                <StatValue
                  IconComponent={<GiClover />}
                  backgroundColor="#16b200"
                  title="Luckiness"
                  currentValue={item?.luckiness || 0}
                  value={item?.luckiness || 0}
                />
                <StatValue
                  IconComponent={<FaWind />}
                  backgroundColor="#cec505"
                  title="Speed"
                  currentValue={item?.speed || 0}
                  value={item?.speed || 0}
                />
              </>
            )
          )}
        </div>

        <div className="card-image-buidl-price">{item.price} BUIDL</div>
        {/* <div className="card-image-usd-price">${item.usdPrice}</div> */}
      </div>
    </button>
  );
};

export default Card;
