import "./styles.scss";

interface StarchiBoxProps {
  horizontal?: boolean;
  styles: Record<string, unknown>;
}

const StarchiBox: React.FC<StarchiBoxProps> = ({
  horizontal = true,
  styles = {},
  children,
}) => {
  return (
    <div
      className={`starchi-box-container starchi-box-${
        horizontal ? "hz" : "vt"
      }`}
    >
      <div className="starchi-box-bg" />
      <div className="starchi-box-main" style={styles}>
        {children}
      </div>
    </div>
  );
};

export default StarchiBox;
