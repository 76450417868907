import { connect as ioConnect, Socket } from "socket.io-client";

import LinearProgress from "@material-ui/core/LinearProgress";

import { useEffect, useState, useCallback, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ArenaCard from "../ArenaCard";
import SelectPetsForBattleModal from "../SelectPetsForBattleModal";
import JoiningBattleModal from "../JoiningBattleModal";

import { BattleHostedProps, useArena } from "../../../../hooks/useArena";
import { useAccount } from "../../../../contexts/AccountContext";

import Button from "../../../../components/Button";
import WalletModal from "../../../../components/WalletModal";
import ArenaSelectionModal from "../ArenaSelectionModal";
import { toastError, toastSuccess } from "../../../../utils/errorHandlers";

import { usePets } from "../../../../hooks/usePets";
import {
  blockchainAddresses,
  isStaging,
  whitelistAccounts,
  socketServerUrl,
} from "../../../../config";
import { useContractsParams } from "../../../../contexts/ContractsParamsContext";
import { useBattle } from "../../../../contexts/BattleContext";
import { timeout } from "../../../../utils/time";
import NotInWhitelist from "../../../../components/NotInWhitelist";
import { MIN_PHASE_TO_BATTLE } from "../../../../constants/constants";
import { ArenaDetailsProps } from "../../../../api/battle";
import { OverrideTyping } from "../../../../utils/OverrideTyping";

import "../styles.scss";
import { _joinedPetsToArenaRoom } from "../../../../api/restapi";

type BattleHostedOverriden = OverrideTyping<
  BattleHostedProps,
  {
    arenaId: number;
  }
>;

const ArenaList: React.FC = () => {
  const {
    fetchPendingArenas,
    submitHostBattle,
    submitJoinBattle,
    submitCancelBattle,
    fetchMyArena,
    myPendingBattleRewards,
    fetchMyPendingBattleRewards,
  } = useArena();
  const {
    setJoinConfig,
    updateAuthSignature,
    isOffChain,
    setIsOffChain,
    authSignature,
  } = useBattle();
  const { myPets, isLoadingMyPets } = usePets();
  const { account } = useAccount();
  const { approvedTokens, approveToken } = useContractsParams();
  const history = useHistory();
  const { search } = useLocation();

  const [arenaMode, setArenaMode] = useState(1);
  const [isJoiningBattleModal, showJoiningBattleModal] = useState(false);
  const [isApprovingToken, setIsApprovingToken] = useState(false);
  const [showPetsSelectionModal, setShowPetsSelectionModal] = useState(false);
  const [showArenaSelectionModal, setShowSelectionArenaModal] = useState(false);
  const [loadingArenaMessage, setLoadingArenaMessage] = useState("");
  const [selectedArenaId, setSelectedArenaId] = useState<string | undefined>(
    undefined,
  );
  const [walletModal, showWalletModal] = useState(false);
  const [isInviteToArena, setIsInviteToArena] = useState(false);
  const [hasMinimumPets, setHasMinimumPets] = useState(false);
  const [invitedArena, setInvitedArena] = useState<ArenaDetailsProps>(
    {} as ArenaDetailsProps,
  );
  const [pendingArenas, setPendingArenas] = useState<ArenaDetailsProps[]>([]);
  const [isLoadingPendingArenas, setIsLoadingPendingArenas] = useState(true);
  const [myArena, setMyArena] = useState<ArenaDetailsProps | undefined>(
    undefined,
  );
  const [myOnChainArena, setMyOnChainArena] = useState<
    ArenaDetailsProps | undefined
  >(undefined);
  const [accountAddress, setAccountAddress] = useState("");
  const [pendingOnChainArenas, setPendingOnChainArenas] = useState<
    ArenaDetailsProps[]
  >([]);

  const socket = useRef<Socket | null>(null);

  useEffect(() => {
    if (account?.address) {
      setAccountAddress(account?.address);
    }
  }, [account]);

  const handleArenasList = useCallback(
    async (_arenasList: ArenaDetailsProps[]) => {
      const arenasList = _arenasList.filter(
        (arena) => arena.id?.toString() !== "0",
      );
      console.log("arenasList", arenasList);

      if (!isOffChain) {
        setPendingArenas(arenasList);
      }

      setPendingOnChainArenas(arenasList);
      setIsLoadingPendingArenas(false);
    },
    [isOffChain],
  );

  const joinGameRoom = useCallback(
    async (
      pets,
      updateSignature = true,
      petsInitialElementType = [],
      myArenaId = undefined,
    ) => {
      const arenaId = selectedArenaId || myArenaId;

      if (!arenaId && !isOffChain) {
        toastError("Invalid arenaId");
        return;
      }

      console.info("selected pets and joining arena room", pets, arenaId);

      if (updateSignature || !authSignature) {
        await updateAuthSignature(arenaId);
      }

      const joinConfig = {
        arenaId: arenaId!,
        pets,
        petsInitialElementType,
      };
      console.log("join Info", joinConfig);
      setJoinConfig(joinConfig);

      setShowPetsSelectionModal(false);
      showJoiningBattleModal(true);
    },
    [
      isOffChain,
      selectedArenaId,
      updateAuthSignature,
      authSignature,
      setJoinConfig,
    ],
  );

  const handleMyArena = useCallback(async (arena: ArenaDetailsProps | null) => {
    console.log("MyArena", arena || undefined);

    setMyArena(arena || undefined);
    setMyOnChainArena(arena || undefined);
    setSelectedArenaId(arena?.id?.toString());
  }, []);

  const handleHasJoinedPets = useCallback(
    async ({ arePetsJoined, arenaId }) => {
      console.log("arePetsJoined", arePetsJoined);

      if (!arePetsJoined || isOffChain) {
        setShowPetsSelectionModal(true);
      } else {
        await joinGameRoom([], false, [], arenaId);
      }
    },
    [joinGameRoom, isOffChain],
  );

  const handleMessage = useCallback(
    (data: any) => {
      const messageBody = JSON.parse(data);

      if (messageBody.event === "error") {
        const { message } = messageBody.data;

        // In case the signature is invalid
        if (message.includes("Invalid address or signature") && myArena) {
          socket.current?.emit("isConnectedToArena", Number(myArena.id));
        }

        toastError(`Server Error: ${message}`);
        console.error(`Server Error: ${message}`);
      }
    },
    [myArena],
  );

  const cleaupSocketConnection = useCallback(() => {
    socket.current?.disconnect();
  }, []);

  useEffect(() => {
    // remove socket event listeners on page refresh
    window.addEventListener("beforeunload", cleaupSocketConnection);

    return () => {
      window.removeEventListener("beforeunload", cleaupSocketConnection);
    };
  }, [cleaupSocketConnection]);

  useEffect(() => {
    // remove socket event listeners on page change
    return history.listen(() => {
      cleaupSocketConnection();
    });
  }, [history, cleaupSocketConnection]);

  // Manages battle server socket connection
  useEffect(() => {
    const loadSocket = () => {
      console.info("connecting to arenas socket...");

      socket.current = ioConnect(socketServerUrl);

      socket.current.on("arenasList", handleArenasList);
      socket.current.on("myArena", handleMyArena);
      socket.current.on("hasPetsConnected", handleHasJoinedPets);
      socket.current.on("message", handleMessage);

      socket.current?.emit("getArenas", accountAddress);
    };

    if (!isOffChain && !socket.current && accountAddress) {
      loadSocket();
    }
  }, [
    handleArenasList,
    handleMyArena,
    handleHasJoinedPets,
    handleMessage,
    isOffChain,
    accountAddress,
  ]);

  useEffect(() => {
    if (!isOffChain) {
      setMyArena(myOnChainArena);
      setSelectedArenaId(myOnChainArena?.id?.toString());
      setPendingArenas(pendingOnChainArenas);
    }
  }, [myOnChainArena, pendingOnChainArenas, isOffChain]);

  useEffect(() => {
    const loadOffChainArena = async () => {
      const playerArena = await fetchMyArena(isOffChain);

      setMyArena(playerArena);
      setSelectedArenaId(String(playerArena?.id) ?? undefined);

      if (playerArena) {
        setIsLoadingPendingArenas(false);
        return;
      }

      setIsLoadingPendingArenas(true);

      const pendingOffChainArenas = await fetchPendingArenas(isOffChain);

      setPendingArenas(pendingOffChainArenas);
      setIsLoadingPendingArenas(false);
    };

    if (isOffChain) {
      loadOffChainArena();
    }
  }, [isOffChain, fetchPendingArenas, fetchMyArena]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    const arenaId = queryParams.get("arenaId");
    const invite = queryParams.get("invite");

    if (invite && arenaId) {
      setIsInviteToArena(true);
      const inviteArena = pendingArenas.find(
        (arena) => arena?.id?.toString() === arenaId,
      );

      if (inviteArena) {
        setInvitedArena(inviteArena);
      }
    } else {
      setIsInviteToArena(false);
    }
  }, [search, pendingArenas]);

  useEffect(() => {
    // Ensure the player has at least one pet with the minimum stage required to battle
    const evolvedPet = myPets.find(
      (pet) => Number(pet.phase) > MIN_PHASE_TO_BATTLE - 1,
    );

    if (evolvedPet) {
      setHasMinimumPets(true);
    } else {
      setHasMinimumPets(false);
    }
  }, [myPets]);

  useEffect(() => {
    fetchMyPendingBattleRewards();
  }, [fetchMyPendingBattleRewards]);

  const handleApproveClick = useCallback(async () => {
    setIsApprovingToken(true);
    try {
      await approveToken(
        blockchainAddresses.buidlToken,
        blockchainAddresses.battle,
      );
    } catch (error) {
      console.error(error);
      toastError(error);
    }
    setIsApprovingToken(false);
  }, [approveToken]);

  const onHostArena = useCallback(
    async (_arenaType: string) => {
      try {
        setLoadingArenaMessage("Creating Arena... Please wait...");

        let BattleHosted: BattleHostedOverriden;

        if (!isOffChain) {
          const { BattleHosted: submittedBattle } = await submitHostBattle(
            _arenaType,
          );

          BattleHosted = {
            ...submittedBattle,
            arenaId: submittedBattle.arenaId.toNumber(),
          };
        } else {
          const MockedBattleHosted = {
            host: "0x0000000000000000000000000000000000000000",
            mode: 1,
            arenaId: 10000000 + Math.floor(Math.random() * 9999999),
          };

          BattleHosted = MockedBattleHosted;
        }

        setArenaMode(Number(_arenaType));
        console.info("Hosted battle >>>", BattleHosted);
        setSelectedArenaId(String(BattleHosted.arenaId));
        setShowPetsSelectionModal(true);
        setShowSelectionArenaModal(false);
        toastSuccess(
          `Hosted arena ${String(
            BattleHosted.arenaId,
          )} successfully! Please select your Starchis to battle!`,
        );
      } catch (error) {
        console.error(error);
        toastError(error);
      }
      setLoadingArenaMessage("");
    },
    [submitHostBattle, isOffChain],
  );

  const onJoinArena = useCallback(
    async (arenaId: string) => {
      try {
        // todo: include isInviteToArena verification
        const arena = pendingArenas.find(
          (currentArena) => currentArena.id?.toString() === arenaId,
        );

        if (!arena) {
          throw new Error("Failed to connect to arena");
        }

        console.info("joining arena", arena);

        setArenaMode(arena?.mode);
        setShowSelectionArenaModal(true);
        setLoadingArenaMessage("Joining the Arena... Please wait...");
        if (!isOffChain) {
          await submitJoinBattle(arenaId);
        }
        setSelectedArenaId(arenaId);
        setShowSelectionArenaModal(false);
        setShowPetsSelectionModal(true);
        toastSuccess(
          `Joined arena ${arenaId} successfully! Please select your Starchis to battle!`,
        );
      } catch (error) {
        console.error(error);
        toastError(error);
      }
      setShowSelectionArenaModal(false);
      setLoadingArenaMessage("");
    },
    [submitJoinBattle, pendingArenas, isOffChain],
  );

  const handleCancelBattleClick = useCallback(async () => {
    try {
      setShowSelectionArenaModal(true);
      setLoadingArenaMessage("Cancelling empty arena... Please wait...");
      await submitCancelBattle(isOffChain);
      await timeout(5000);
      toastSuccess(`Your arena was canceled!`);
    } catch (error) {
      console.error(error);
      toastError(error);
    }
    setShowSelectionArenaModal(false);
    setLoadingArenaMessage("");
  }, [submitCancelBattle, isOffChain]);

  const onConnect = useCallback(() => {
    showWalletModal(true);
  }, []);

  const handleCreateArenaClick = useCallback(() => {
    setShowSelectionArenaModal(true);
  }, []);

  const reconnectToMyArena = useCallback(async () => {
    if (!myArena) {
      return;
    }

    setArenaMode(parseInt(myArena.mode.toString()));
    console.info("joining arena", myArena, parseInt(myArena.mode.toString()));
    console.log("here");

    if (!isOffChain) {
      socket.current?.emit("isConnectedToArena", Number(myArena.id));
    } else {
      let joinedPets: number[] | undefined;

      let signature = authSignature;

      if (!signature) {
        signature = await updateAuthSignature(myArena.id?.toString());
      }

      if (!signature || !account?.address) {
        return;
      }

      try {
        joinedPets = await _joinedPetsToArenaRoom({
          signature,
          arenaId: Number(myArena.id),
          address: account?.address,
        });
      } catch (err) {
        // In case of an error, retry to get the player's signature
        signature = await updateAuthSignature(myArena.id?.toString());

        if (!signature) {
          return;
        }

        joinedPets = await _joinedPetsToArenaRoom({
          signature,
          arenaId: Number(myArena.id),
          address: account.address,
        });

        if (err) {
          console.error(err);
        }
      }

      if (joinedPets) {
        if (joinedPets.length <= 0) {
          setShowPetsSelectionModal(true);
        } else {
          await joinGameRoom(joinedPets, false, [], myArena.id);
        }
      }
    }
  }, [
    myArena,
    socket,
    joinGameRoom,
    isOffChain,
    account.address,
    authSignature,
    updateAuthSignature,
  ]);

  const handleChainToogle = useCallback(() => {
    localStorage.setItem("isOffChain", String(!isOffChain));

    setIsOffChain(!isOffChain);
  }, [setIsOffChain, isOffChain]);

  if (!account || !account.address) {
    return (
      <div className="my-arena-container">
        <div className="staking-income-connect">
          <div className="staking-income-connect-text">
            Connect your wallet to see available arenas
          </div>
          <div className="staking-income-connect-button">
            <Button
              text="CONNECT WALLET"
              size="md"
              onClick={() => {
                onConnect();
              }}
            />
          </div>
        </div>
        <WalletModal
          modalOpen={walletModal}
          onClose={() => {
            showWalletModal(false);
          }}
        />
      </div>
    );
  }

  if (account.address && !whitelistAccounts.includes(account.address)) {
    return <NotInWhitelist address={account.address} />;
  }

  return (
    <div className="my-arena-container">
      <div className="my-arena-title-container arenas-header">
        <div className="my-arena-title-count">
          <h1 className="my-arena-heading">ARENA</h1>
          <span className="my-arena-sub-heading">
            Available Arenas to Battle
          </span>
          {isStaging && (
            <div className="custom-control custom-switch staging-switch">
              <input
                type="checkbox"
                className="custom-control-input staging-switch-input"
                id="staging-switch-input"
                defaultChecked={isOffChain}
                onChange={handleChainToogle}
              />
              <label
                className="custom-control-label staging-switch-label"
                htmlFor="staging-switch-input"
              >
                Use off chain arenas
              </label>
            </div>
          )}
        </div>
        <div className="arena-card-text" style={{ textAlign: "center" }}>
          {myPendingBattleRewards && (
            <>
              <strong>Battle Pending Rewards</strong>
              <br />
              {myPendingBattleRewards.arenaFeesAmount} BUIDL <br />
              {myPendingBattleRewards.extraRewardsAmount} ELXR
            </>
          )}
        </div>

        <div>
          {hasMinimumPets &&
            (!approvedTokens?.battleIsApprovedToBuidlTokens ? (
              <div className="my-arena-approve-token">
                <Button
                  text={
                    isApprovingToken
                      ? "Approving..."
                      : "Approve BUIDL for Hosting Arenas"
                  }
                  size="md"
                  disabled={isApprovingToken}
                  onClick={handleApproveClick}
                />
              </div>
            ) : myArena ? (
              <div className="arena-card-text" style={{ display: "flex" }}>
                <span>Reconnect to your ongoing battle below!</span>
                {myArena.startedAt.toString() === "0" &&
                  myArena.host.toLowerCase() === account?.address && (
                    <Button
                      size="sm"
                      onClick={handleCancelBattleClick}
                      text="Cancel"
                      style={{ marginLeft: 12 }}
                    />
                  )}
              </div>
            ) : (
              <div className="my-arena-quick-battle">
                <Button
                  size="lg"
                  text="Create arena"
                  onClick={handleCreateArenaClick}
                />
              </div>
            ))}
        </div>
      </div>

      {isLoadingPendingArenas || isLoadingMyPets ? (
        <div className="my-arena-loader">
          <LinearProgress />
        </div>
      ) : !hasMinimumPets ? (
        <div className="my-arenas-list">
          <div className="arena-card-text">
            You do not currently own any{" "}
            <a
              href="https://docs.starchi.gg/battle#qualified-starchis"
              target="_blank"
              rel="noreferrer"
            >
              Qualified Starchis
            </a>{" "}
            to battle with
          </div>
        </div>
      ) : (
        <div className="my-arenas-list">
          {myArena ? (
            <ArenaCard
              key={myArena.id?.toString()}
              arena={myArena}
              onJoin={reconnectToMyArena}
            />
          ) : isInviteToArena && !invitedArena?.id ? (
            <>
              <div className="arena-card-text">
                Looks like the invited arena is already full. You can also host
                an Epic Battle!
              </div>
              <Button
                size="md"
                text="Go To Arenas List"
                style={{ width: 200, margin: "0 auto" }}
                onClick={() => {
                  history.replace("/arena?invite=false");
                }}
              />
            </>
          ) : isInviteToArena && invitedArena?.id ? (
            <ArenaCard arena={invitedArena} onJoin={onJoinArena} />
          ) : pendingArenas?.length === 0 ? (
            <div className="arena-card-text">
              Looks like there are no hosted arenas for now. Be the first one to
              Host an Epic Battle!
            </div>
          ) : (
            pendingArenas?.map((arena) => (
              <ArenaCard
                key={arena?.id?.toString()}
                arena={arena}
                onJoin={onJoinArena}
              />
            ))
          )}
        </div>
      )}

      <SelectPetsForBattleModal
        modalOpen={showPetsSelectionModal}
        myPets={myPets}
        mode={arenaMode}
        onClose={() => {
          setShowPetsSelectionModal(false);
        }}
        onJoin={joinGameRoom}
      />

      <JoiningBattleModal modalOpen={isJoiningBattleModal} />

      <ArenaSelectionModal
        modalOpen={showArenaSelectionModal}
        loadingMessage={loadingArenaMessage}
        onConfirm={onHostArena}
        onClose={() => {
          setShowSelectionArenaModal(false);
        }}
      />
    </div>
  );
};

export default ArenaList;
