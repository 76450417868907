import "./styles.scss";

const ComingSoon: React.FC = () => {
  return (
    <div className="coming-soon-container">
      <span className="coming-soon-text">Coming Soon...</span>
    </div>
  );
};

export default ComingSoon;
