import { api } from ".";

import * as Pool from "./chain/pool";

export const getStakingPools = async (account, web3) => {
  const poolsResponse = await api.get("https://api.starchi.gg/pools");
  const pools = poolsResponse.data;
  console.info("pools >> ", pools);

  // remove non relevant pools
  for (const poolKey in pools) {
    if (!pools[poolKey].token?.address) {
      delete pools[poolKey];
    }
  }

  if (account) {
    const stakedInfoRequests = Object.keys(pools).map((pool) =>
      Pool.getStakedInfo(web3, pool, pools[pool].token.address, account),
    );
    const stakedInfos = await Promise.all(stakedInfoRequests);
    for (const poolInfo of stakedInfos) {
      pools[poolInfo.poolAddress] = {
        ...pools[poolInfo.poolAddress],
        pending: poolInfo.pendingRewards,
        staked: poolInfo.userInfo?.tokenAmount || 0,
        avgLockPeriod: poolInfo.avgRewardLockPeriod,
        userInfo: poolInfo.userInfo,
        approved: poolInfo.approved,
        balance: poolInfo.balance,
        depositLength: poolInfo.depositLength,
      };
    }
  }
  return pools;
};

export const getVesting = async (account, web3, pools) => {
  const vesting = {};
  if (!account) {
    return vesting;
  }
  const vestingInfoRequests = Object.keys(pools).map((pool) =>
    Pool.getVestingInfo(web3, pool, account),
  );
  const vestInfos = await Promise.all(vestingInfoRequests);
  vestInfos.forEach((vestInfo, index) => {
    vesting[Object.keys(pools)[index]] = vestInfo;
  });

  return vesting;
};
