import { BigNumber, ContractReceipt, ethers } from "ethers";
import { useCallback, useState } from "react";

import {
  ArenaDetailsProps,
  getMyArena,
  listPendingArenasWithDetails,
} from "../api/battle";
import {
  listPendingOffChainArenas,
  getMyOffChainArena,
  cancelOffChainBattle,
} from "../api/restapi";
import {
  hostBattle,
  joinBattle,
  cancelBattle,
  pendingPlayerRewards,
  battleFinished,
} from "../api/chain/battle";
import { useAccount } from "../contexts/AccountContext";

interface RewardsProps {
  lastClaimedAt: number;
  arenaFeesAmount: string;
  extraRewardsAmount: string;
}

export interface BattleHostedProps {
  host: string;
  arenaId: BigNumber;
  mode: number;
}

interface UseArenaProps {
  fetchPendingArenas: (isOffChain: boolean) => Promise<ArenaDetailsProps[]>;
  pendingArenas: ArenaDetailsProps[];
  isLoadingPendingArenas: boolean;
  fetchMyArena: (isOffChain: boolean) => Promise<ArenaDetailsProps | undefined>;
  myArena: ArenaDetailsProps | undefined;
  isLoadingMyArena: boolean;
  fetchMyPendingBattleRewards: () => Promise<RewardsProps | undefined>;
  myPendingBattleRewards: RewardsProps | undefined;
  isLoadingMyPendingRewards: boolean;
  submitHostBattle: (mode: string) => Promise<{
    BattleHosted: BattleHostedProps;
    receipt: ContractReceipt;
  }>;
  submitJoinBattle: (arenaId: string) => Promise<ContractReceipt>;
  submitCancelBattle: (isOffChain: boolean) => Promise<void>;
  watchBattleFinished: (arenaId: string) => Promise<void>;
}

export const useArena = (): UseArenaProps => {
  const { account, provider } = useAccount();

  const [pendingArenas, setPendingArenas] = useState<ArenaDetailsProps[]>([]);
  const [isLoadingPendingArenas, setIsLoadingPendingArenas] = useState(false);

  const [myPendingBattleRewards, setMyPendingBattleRewards] = useState<
    RewardsProps | undefined
  >(undefined);
  const [isLoadingMyPendingRewards, setIsLoadingMyPendingBattleRewards] =
    useState(false);

  const [myArena, setMyArena] = useState<ArenaDetailsProps | undefined>(
    undefined,
  );
  const [isLoadingMyArena, setIsLoadingMyArena] = useState(false);

  const fetchMyArena = useCallback(
    async (isOffChain: boolean) => {
      if (!provider || !account?.address || account?.address === undefined) {
        return undefined;
      }
      setIsLoadingMyArena(true);
      try {
        let myArenaData;

        if (!isOffChain) {
          myArenaData = await getMyArena(provider, account.address);
        } else {
          myArenaData = await getMyOffChainArena(account.address);
        }

        console.log("myArenaData", myArenaData);
        console.log("myAccountAddress", account.address);

        if (
          myArenaData?.mode?.toString() !== "0" &&
          (myArenaData?.host?.toLowerCase() === account.address ||
            myArenaData?.guest?.toLowerCase() === account.address)
        ) {
          setMyArena(myArenaData);
          setIsLoadingMyArena(false);
          return myArenaData;
        }
      } catch (e) {
        setPendingArenas([]);
        throw e;
      }

      setIsLoadingMyArena(false);
      return undefined;
    },
    [provider, account.address],
  );

  const watchBattleFinished = useCallback(
    (arenaId: string) => battleFinished(provider, arenaId),
    [provider],
  );

  const fetchMyPendingBattleRewards = useCallback(async () => {
    if (!provider || !account.address) {
      return undefined;
    }
    setIsLoadingMyPendingBattleRewards(true);
    try {
      const playerRewardsData = await pendingPlayerRewards(
        provider,
        account.address,
      );

      const formattedArenaFees = ethers.utils.formatEther(
        playerRewardsData.arenaFeesAmount,
      );

      const formattedExtraRewards = ethers.utils.formatEther(
        playerRewardsData.extraRewardsAmount,
      );

      const rewards = {
        lastClaimedAt: playerRewardsData.lastClaimedAt.toNumber(),
        arenaFeesAmount: formattedArenaFees,
        extraRewardsAmount: formattedExtraRewards,
      };

      setMyPendingBattleRewards(rewards);
      setIsLoadingMyPendingBattleRewards(false);
      return rewards;
    } catch (e) {
      setMyPendingBattleRewards(undefined);
      setIsLoadingMyPendingBattleRewards(false);
      throw e;
    }
  }, [provider, account]);

  const fetchPendingArenas = useCallback(
    async (isOffChain: boolean) => {
      if (!provider) {
        return [];
      }
      setIsLoadingPendingArenas(true);
      try {
        let pendingArenasData = [];
        if (!isOffChain) {
          pendingArenasData = await listPendingArenasWithDetails(provider);
        } else {
          pendingArenasData = await listPendingOffChainArenas();
        }

        setPendingArenas(pendingArenasData);
        setIsLoadingPendingArenas(false);
        return pendingArenasData;
      } catch (e) {
        setPendingArenas([]);
        setIsLoadingPendingArenas(false);
        throw e;
      }
    },
    [provider],
  );

  const submitHostBattle = useCallback(
    (mode: string) => hostBattle(mode, provider),
    [provider],
  );

  const submitJoinBattle = useCallback(
    (arenaId: string) => joinBattle(arenaId, provider),
    [provider],
  );

  const submitCancelBattle = useCallback(
    async (isOffChain: boolean) => {
      if (!isOffChain) {
        await cancelBattle(provider);
      } else if (isOffChain && account?.address) {
        await cancelOffChainBattle(account.address);
      }
    },
    [account, provider],
  );

  return {
    fetchPendingArenas,
    pendingArenas,
    isLoadingPendingArenas,

    fetchMyArena,
    myArena,
    isLoadingMyArena,

    fetchMyPendingBattleRewards,
    myPendingBattleRewards,
    isLoadingMyPendingRewards,

    submitHostBattle,
    submitJoinBattle,
    submitCancelBattle,

    watchBattleFinished,
  };
};
