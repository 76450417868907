interface PlayerTwoMessageProps {
  text: string;
}

const PlayerTwoMessage: React.FC<PlayerTwoMessageProps> = ({ text }) => {
  return (
    <div className="message-container">
      <div className="player-two-message">{text}</div>
    </div>
  );
};

export default PlayerTwoMessage;
