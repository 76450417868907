import { useEffect, useState } from "react";

export type CardTiers = "1" | "2" | "3" | "4";
interface CardTierProps {
  value: CardTiers;
}

const CardTier: React.FC<CardTierProps> = ({ value }) => {
  const [badgeTierName, setBadgeTierName] = useState("");
  const [badgeBackgroundColor, setBadgeBackgroundColor] = useState("");

  useEffect(() => {
    switch (value) {
      case "1":
        setBadgeTierName("Bronze");
        setBadgeBackgroundColor("#cd7f32");
        break;
      case "2":
        setBadgeTierName("Silver");
        setBadgeBackgroundColor("#bec2cb");
        break;
      case "3":
        setBadgeTierName("Golden");
        setBadgeBackgroundColor("#ffbf00");
        break;
      case "4":
        setBadgeTierName("Diamond");
        setBadgeBackgroundColor("#00a0ff");
        break;
      default:
        break;
    }
  }, [value]);

  return (
    <div className="stat-value-container">
      <div
        className="stat-value-icon"
        style={{ backgroundColor: badgeBackgroundColor }}
      />
      <div className="stat-value-setting">
        <span className="stat-value-title">{badgeTierName}</span>
      </div>
    </div>
  );
};

export default CardTier;
