import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import { useHistory } from "react-router-dom";
import "../styles.scss";
import { useBattle } from "../../../../contexts/BattleContext";

interface JoiningBattleModalProps {
  modalOpen: boolean;
}

const JoiningBattleModal: React.FC<JoiningBattleModalProps> = ({
  modalOpen,
}) => {
  const { joinConfig } = useBattle();

  const history = useHistory();
  useEffect(() => {
    if (modalOpen) {
      setTimeout(() => {
        history.push(
          `/battle?arenaId=${joinConfig?.arenaId}&pets=${joinConfig?.pets?.join(
            ",",
          )}`,
        );
      }, 5000);
    }
  }, [modalOpen, history, joinConfig?.arenaId, joinConfig?.pets]);

  return (
    <Modal
      show={modalOpen}
      size="lg"
      centered
      contentClassName="manage-modal-content"
    >
      <div className="manage-modal-bg" />
      <ModalHeader bsPrefix="manage-modal-header">
        <span className="manage-modal-heading-lg">JOINING BATTLE...</span>
      </ModalHeader>
      <ModalBody bsPrefix="joining-battle-modal-body">
        <span className="joining-battle-modal-text">
          Waiting for Opponents...
        </span>
      </ModalBody>
    </Modal>
  );
};

export default JoiningBattleModal;
