import { useState } from "react";
import { useWeb3React } from "@web3-react/core";

import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import { OutlineButton } from "../Button";

import { useAccount } from "../../contexts/AccountContext";

import Metamask from "../../img/assets/Metamask.png";
import WalletConnect from "../../img/assets/WalletConnect.svg";
import TrustWallet from "../../img/assets/TrustWallet.png";
// import Portis from "../../img/assets/PortisIcon.png";
// import Fortmatic from "../../img/assets/FormaticIcon.png";
// import Ledger from "../../img/assets/Ledger.svg";
// import SquareLink from "../../img/assets/SquareLink.png";
// import Trezor from "../../img/assets/Trezor.png";
// import Torus from "../../img/assets/Torus.jpg";
// import Authereum from "../../img/assets/Authereum.svg";
import Coinbase from "../../img/assets/Coinbase.svg";

import { connectorsByName } from "../../connectors";
import { blockchainParams } from "../../config";

import "./styles.scss";

interface WalletModalProps {
  modalOpen: boolean;
  onClose(): void;
}

const WalletModal: React.FC<WalletModalProps> = ({ modalOpen, onClose }) => {
  const context = useWeb3React();
  const { connector, error, chainId } = context;

  const [activatingConnector] = useState();
  const { connectWallet, disconnectWallet } = useAccount();
  const getUrlByName = (name: keyof typeof connectorsByName) => {
    let url;
    switch (name) {
      case "MetaMask":
        url = Metamask;
        break;
      case "WalletConnect":
        url = WalletConnect;
        break;
      case "TrustWallet":
        url = TrustWallet;
        break;
      case "Coinbase":
        url = Coinbase;
        break;
      // case "Portis":
      //   url = Portis;
      //   break;
      // case "Fortmatic":
      //   url = Fortmatic;
      //   break;
      // case "Ledger":
      //   url = Ledger;
      //   break;
      // case "Squarelink":
      //   url = SquareLink;
      //   break;
      // case "Trezor":
      //   url = Trezor;
      //   break;
      // case "Torus":
      //   url = Torus;
      //   break;
      // case "Authereum":
      //   url = Authereum;
      //   break;
      default:
        break;
    }

    return url;
  };

  return (
    <Modal
      show={modalOpen}
      // size="md"
      centered
      contentClassName="manage-modal-content"
      onHide={onClose}
    >
      <div className="manage-modal-bg" />
      <ModalHeader bsPrefix="manage-modal-header">
        <div className="wallet-modal-heading-container">
          <div className="manage-modal-heading-logo-name">
            <span className="manage-modal-heading-lg">Connect Wallet</span>
          </div>
        </div>
        <CloseIcon className="manage-modal-close-icon" onClick={onClose} />
      </ModalHeader>
      <ModalBody bsPrefix="wallet-modal-body">
        {Object.keys(connectorsByName).map((name) => {
          const currentConnector =
            connectorsByName[name as keyof typeof connectorsByName];
          const activating = currentConnector === activatingConnector;
          const connected =
            currentConnector === connector &&
            chainId === parseInt(blockchainParams.chainId, 16);
          const disabled = !!activatingConnector || !!error;

          const url = getUrlByName(name as keyof typeof connectorsByName);
          return (
            <button
              type="button"
              key={name}
              className="wallet-item"
              onClick={() => {
                if (!disabled) {
                  connectWallet(name as keyof typeof connectorsByName);
                  onClose();
                }
              }}
            >
              <span className="wallet-item-text">{name}</span>
              {!activating && !connected && (
                <img className="wallet-icon" src={url} alt="logo" />
              )}
              {activating && (
                <CircularProgress size={15} style={{ marginRight: "10px" }} />
              )}
              {!activating && connected && <div className="wallet-item-mark" />}
            </button>
          );
        })}
        {context.account && (
          <OutlineButton
            text="DISCONNECT"
            onClick={() => {
              disconnectWallet();
              onClose();
            }}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default WalletModal;
