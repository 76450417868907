import { ButtonHTMLAttributes } from "react";

import "./styles.scss";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string;
  size?: "md" | "lg" | "sm";
  disabled?: boolean;
  style?: Record<string, unknown>;
  onClick(): void;
};

const Button: React.FC<ButtonProps> = ({
  text,
  size = "md",
  disabled = false,
  style = {},
  onClick,
}) => {
  return (
    <div
      style={style}
      className={`btn-wrapper button-${size} button-${
        disabled ? "disabled" : "container"
      }`}
      onClick={() => {
        if (!disabled) onClick();
      }}
      aria-hidden="true"
    >
      {!disabled && <div className={`button-bg-mask button-bg-mask-${size}`} />}
      <span className={`button-text button-text-${size}`}>{text} </span>
    </div>
  );
};

type OutlineButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string;
  size?: "md" | "lg" | "sm";
  onClick(): void;
};

export const OutlineButton: React.FC<OutlineButtonProps> = ({
  text,
  size = "md",
  onClick,
}) => {
  return (
    <div
      className={`outlined-button-container outlined-button-${size}`}
      onClick={onClick}
      aria-hidden="true"
    >
      <span className={`outlined-button-text outlined-button-text-${size}`}>
        {text}
      </span>
    </div>
  );
};

export default Button;
