import CryptoDiffer from "../../../../img/partners/cryptodiffer.png";
import PlayToEarn from "../../../../img/partners/playToEarn.png";
import PolygonDaily from "../../../../img/partners/polygondaily.png";
import NftDaily from "../../../../img/partners/nftdaily.png";
import ChainStride from "../../../../img/partners/chainstride.png";
import ZbsCapital from "../../../../img/partners/zbs-capital.jpeg";
import StarterCapital from "../../../../img/partners/starter-capital.png";
import TkVentures from "../../../../img/partners/tk-ventures.svg";
import QuickSwap from "../../../../img/partners/quickswap.png";
import GlobalEmpire from "../../../../img/partners/empire-global.png";
import RdAuditors from "../../../../img/partners/rd-auditors.png";
import Polygon from "../../../../img/partners/polygon.png";
import SeedThrift from "../../../../img/partners/seed-thrift.png";
import KangarooCapital from "../../../../img/partners/kangaroo-capital.jpeg";
import MintedLab from "../../../../img/partners/minted-lab.png";
import OigInvestment from "../../../../img/partners/oig-investment.png";
import ScnCryptoNews from "../../../../img/partners/scn-crypto-news.jpeg";
import HalvingCapital from "../../../../img/partners/halving-capital.png";
import BadCryptoPod from "../../../../img/partners/bad-crypto-pod.png";
import DutchCryptoInvestors from "../../../../img/partners/dutch-crypto-investors.png";
import SpaceCapital from "../../../../img/partners/space-capital.png";
import MavenCapital from "../../../../img/partners/maven-capital.png";
import RdVentures from "../../../../img/partners/rd-ventures.png";
import VbcVentures from "../../../../img/partners/vbc-ventures.png";

import "../../styles.scss";

const Partners: React.FC = () => {
  return (
    <div className="section">
      <span className="section-title">Our Partners</span>
      <div className="list-container">
        <div className="partners-list">
          <img src={CryptoDiffer} className="partner-logo" alt="logo" />
          <img src={PlayToEarn} className="partner-logo" alt="logo" />
          <img src={ChainStride} className="partner-logo" alt="logo" />
          <img src={PolygonDaily} className="partner-logo" alt="logo" />
          <img src={NftDaily} className="partner-logo" alt="logo" />
          <img src={ZbsCapital} className="partner-logo" alt="logo" />
          <img src={StarterCapital} className="partner-logo" alt="logo" />
          <img src={TkVentures} className="partner-logo" alt="logo" />
          <img src={QuickSwap} className="partner-logo" alt="logo" />
          <img src={GlobalEmpire} className="partner-logo" alt="logo" />
          <img src={RdAuditors} className="partner-logo" alt="logo" />
          <img src={Polygon} className="partner-logo" alt="logo" />
          <img src={SeedThrift} className="partner-logo" alt="logo" />
          <img src={KangarooCapital} className="partner-logo" alt="logo" />
          <img src={MintedLab} className="partner-logo" alt="logo" />
          <img src={DutchCryptoInvestors} className="partner-logo" alt="logo" />
          <img src={BadCryptoPod} className="partner-logo" alt="logo" />
          <img src={HalvingCapital} className="partner-logo" alt="logo" />
          <img src={ScnCryptoNews} className="partner-logo" alt="logo" />
          <img src={OigInvestment} className="partner-logo" alt="logo" />
          <img src={SpaceCapital} className="partner-logo" alt="logo" />
          <img src={MavenCapital} className="partner-logo" alt="logo" />
          <img src={RdVentures} className="partner-logo" alt="logo" />
          <img src={VbcVentures} className="partner-logo" alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default Partners;
