import { Contract, ethers } from "ethers";
// import bigInt from "big-integer";
import Erc20Abi from "./abis/erc20.json";
import { ERC20 } from "../types/@chainlink/contracts/src/v0.4/interfaces/ERC20";

export const approve = async (
  token: string,
  spender: string,
  provider: any,
): Promise<boolean | undefined> => {
  try {
    const erc20Contract = new Contract(
      token,
      Erc20Abi,
      provider?.getSigner(),
    ) as ERC20;

    if (!erc20Contract.allowance(token, spender)) {
      return true; // Doesn't need approval
    }

    const approveAmount = ethers.utils.parseEther("9999999999");

    const tx = await erc20Contract.approve(spender, approveAmount);
    const receipt = await tx.wait();

    console.info("approve receipt", receipt);
  } catch (error: any) {
    console.error(error);
    if (!error.toString().includes("-32601")) {
      throw error;
    }
  }
  return undefined; // Doesn't need approval
};

export const getApproval = async (
  provider: any,
  token: string,
  spender: string,
  account: string,
) => {
  const erc20Contract = new Contract(
    token,
    Erc20Abi,
    provider?.getSigner(),
  ) as ERC20;

  const allowance = await erc20Contract.allowance(account, spender);

  return Number(allowance.toString()) > 0;
};
