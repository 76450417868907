import { Switch, Route } from "react-router";

import "./Staking.scss";

import StakingBoard from "./components/StakingBoard";
import StakingIncome from "./components/StakingIncome";
import RewardsBoard from "./components/RewardsBoard";
import VestingBoard from "./components/VestingBoard";

const Staking = () => {
  return (
    <div className="staking-container">
      <Switch>
        <Route exact path={`/staking/pools`} component={StakingIncome} />
        <Route exact path={`/staking/rewards`} component={RewardsBoard} />
        <Route exact path={`/staking/vesting`} component={VestingBoard} />
        <Route path={`/staking/:address/stake`} component={StakingIncome} />
        <Route path={`/staking`} component={StakingBoard} />
      </Switch>
    </div>
  );
};

export default Staking;
