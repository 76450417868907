import { useState } from "react";
import { ElementWithDetailsProps } from "../../../../api/elements";
import SelectInput from "../../../../components/SelectInput";
import { ELEMENTS } from "../../../../constants/constants";

interface SelectInitialElementDropdownProps {
  petElements: ElementWithDetailsProps[];
  onChange(initialElementType: number): void;
}

const SelectInitialElementDropdown: React.FC<
  SelectInitialElementDropdownProps
> = ({ petElements, onChange }) => {
  const [selectedElement, setSelectedElementType] = useState<number>(0);

  return (
    <div>
      <p className="element-modal-filter-label">Select initial element</p>
      <SelectInput
        placeholder="element type"
        value={selectedElement}
        options={petElements.map((element) => ({
          value: String(element?.typeId) || "0",
          label: ELEMENTS[Number(element?.typeId)]?.name,
        }))}
        onChange={(ev) => {
          onChange(Number(ev.target.value));
          setSelectedElementType(Number(ev.target.value));
        }}
      />
    </div>
  );
};

export default SelectInitialElementDropdown;
