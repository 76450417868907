import styled, { keyframes } from "styled-components/macro";

export const Container = styled.div`
  position: relative;
`;

const animateChart = ({ strokeOffset }) => keyframes`
  from {
    stroke-dashoffset: 658;
  }
  to {
    stroke-dashoffset: ${strokeOffset};
  }
`;

export const StyledSvg = styled.svg`
  transform: rotate(-90deg) ${({ scale }) => (scale ? `scale(${scale})` : "")};

  .circle {
    stroke-dasharray: 658;
  }

  animation: ${({ strokeOffset }) => animateChart({ strokeOffset })} 1s linear
    forwards;
`;

export const ChartLabel = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  top: 30px;

  .chart-logo {
    max-width: 350px;
    position: relative;
    left: -44px;
  }

  .chart-title {
    display: flex;
    flex-direction: column;

    white-space: nowrap;
    position: relative;
    top: 30px;

    &.big-label {
      top: 56px;
      padding: 8px 76px;
      .chart-value {
        font-size: 1.7rem;
      }
    }
  }

  .chart-label {
    color: #e3e3e3;
    font-weight: normal;
  }

  .chart-value {
    color: #ffffff;
    font-weight: bold;
  }
`;
