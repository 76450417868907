interface PlayerOneMessageProps {
  text: string;
}

const PlayerOneMessage: React.FC<PlayerOneMessageProps> = ({ text }) => {
  return (
    <div className="message-container">
      <div className="player-one-message">{text}</div>
    </div>
  );
};

export default PlayerOneMessage;
