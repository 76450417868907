import "./styles.scss";

interface CheckBoxProps {
  checked: boolean;
  icon?: string;
  title: string;
  onChange(checked: boolean): void;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  checked,
  icon,
  title,
  onChange,
}) => {
  return (
    <label className="checkbox-container">
      {icon && <img src={icon} alt="icon" className="checkbox-icon" />}
      {title}
      <input
        type="checkbox"
        checked={checked ? true : undefined}
        onChange={() => {
          onChange(!checked);
        }}
      />
      <span className="checkmark" />
    </label>
  );
};

export default CheckBox;
