import { useMemo } from "react";
import ReactPaginate from "react-paginate";

import Card from "../Card";
import { ContractTypes, useStore } from "../../contexts/StoreContext";

import "./styles.scss";

interface CardListProps {
  pagination?: boolean;
}

const CardList: React.FC<CardListProps> = ({ pagination = true }) => {
  const {
    starchiesTotalCount,
    elementsTotalCount,
    selectedTab,
    listedTokens,
    PAGE_LIMIT,
    isLoadingListedTokens,
    starchiesCurrentPage,
    setStarchiesCurrentPage,
    elementsCurrentPage,
    setElementsCurrentPage,
  } = useStore();

  const list = useMemo(
    () =>
      selectedTab === 0
        ? listedTokens.filter(
            (token) => token.contractType === ContractTypes.Pets,
          )
        : listedTokens.filter(
            (token) => token.contractType === ContractTypes.Elements,
          ),
    [selectedTab, listedTokens],
  );

  const pageCount = useMemo(
    () =>
      selectedTab === 0
        ? Math.ceil(starchiesTotalCount / PAGE_LIMIT)
        : Math.ceil(elementsTotalCount / PAGE_LIMIT),
    [selectedTab, starchiesTotalCount, elementsTotalCount, PAGE_LIMIT],
  );

  const currentPage = useMemo(
    () =>
      selectedTab === 0 ? starchiesCurrentPage - 1 : elementsCurrentPage - 1,
    [selectedTab, starchiesCurrentPage, elementsCurrentPage],
  );

  const handlePageClick = async (data: any) => {
    const newPage = data.selected + 1;

    if (selectedTab === 0) {
      setStarchiesCurrentPage(newPage);
    } else {
      setElementsCurrentPage(newPage);
    }
  };

  return (
    <div className="card-list-container">
      {!isLoadingListedTokens && (
        <div className="card-list-body">
          {list.map((item) => (
            <Card key={item.tokenId} item={item} />
          ))}
        </div>
      )}

      {pagination && list.length && (
        <div
          className={`pagination-container ${
            isLoadingListedTokens && "d-none"
          }`}
        >
          <div className="pagination-box">
            <div className="pagination-box-background" />
            <ReactPaginate
              previousLabel="previous"
              nextLabel="next"
              breakLabel="..."
              forcePage={currentPage}
              pageCount={pageCount <= 0 ? 1 : pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-center"
              pageClassName="page"
              pageLinkClassName="link"
              previousClassName="prev-page"
              previousLinkClassName="prev-link"
              nextClassName="next-page"
              nextLinkClassName="next-link"
              breakClassName="break-item"
              breakLinkClassName="break-link"
              activeClassName="active"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CardList;
