import { useEffect, useState, useCallback } from "react";
import { api } from "../api";

interface GeneralsProps {
  percent24: number;
  price: number;
  totalDistributed: string;
  totalStaked: any;
}
interface UseGeneralsProps {
  generals: GeneralsProps;
  isLoading: boolean;
}

export const useGenerals = (): UseGeneralsProps => {
  const [generals, setGenerals] = useState<GeneralsProps>({} as GeneralsProps);
  const [isLoading, setLoading] = useState(true);

  const generalInfoReturned = useCallback(async () => {
    const generalInfo = await api.get("https://api.starchi.gg/generals");
    const _generals = generalInfo.data;
    console.info("generals >> ", _generals);

    setGenerals(_generals);
    setLoading(false);
  }, []);

  useEffect(() => {
    generalInfoReturned();

    const interval = setInterval(generalInfoReturned, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [generalInfoReturned]);

  return { generals, isLoading };
};
