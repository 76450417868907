import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { BsLightningChargeFill } from "react-icons/bs";
import {
  GiClover,
  GiFist,
  GiMagicPortal,
  GiWingedScepter,
} from "react-icons/gi";
import { RiSwordLine } from "react-icons/ri";
import { ImHeart } from "react-icons/im";
import { FaWind, FaArrowLeft } from "react-icons/fa";

import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import ClipLoader from "react-spinners/ClipLoader";

import StarchiBox from "../../../../components/Starchibox";
import Button from "../../../../components/Button";
import StatValue from "../../../../components/StatValue";
import NameBadge from "../../../../components/StarchiNameBadge";
import Input from "../../../../components/Input";

import {
  BREEDS,
  CARD_LEVELS,
  EFFECTS,
  ELEMENTS,
  PET_TYPES,
} from "../../../../constants/constants";

import { useQuery } from "../../../../hooks/useQuery";
import {
  ContractTypes,
  TokenProps,
  useStore,
} from "../../contexts/StoreContext";

import "./styles.scss";
import { toastError, toastSuccess } from "../../../../utils/errorHandlers";

interface ParamProps {
  id: string;
}

const ItemSell: React.FC = () => {
  const [sellPrice, setSellPrice] = useState("0");
  const [item, setItem] = useState<TokenProps>({} as TokenProps);
  const [isListing, setIsListing] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);

  const history = useHistory();
  const { id } = useParams<ParamProps>();
  const {
    findToken,
    isLoadingListedTokens,
    listToken,
    myAvailableTokensToSell,
  } = useStore();

  const query = useQuery();

  useEffect(() => {
    if (item?.elements?.length) {
      setOpenAlertModal(true);
    }
  }, [item]);

  useEffect(() => {
    const loadItens = async () => {
      const contractType = query?.get("contractType");

      const foundToken = findToken(Number(id), Number(contractType), true);

      if (foundToken) {
        setItem(foundToken);
      }
    };

    if (!isLoadingListedTokens && myAvailableTokensToSell.length) {
      loadItens();
    }
  }, [query, id, findToken, isLoadingListedTokens, myAvailableTokensToSell]);

  const handleListNFT = useCallback(async () => {
    setIsListing(true);
    try {
      await listToken(item, Number(sellPrice));
      toastSuccess("NFT successfully listed on the market");
      history.replace(
        `/store/buy/${item.tokenId}?contractType=${item.contractType}`,
      );
    } catch (err) {
      toastError(
        Number(sellPrice) === 0 ? "Invalid selling price" : "Fail to list NFT",
      );
      console.error("createListing", err);
    }
    setIsListing(false);
  }, [history, listToken, item, sellPrice]);

  return (
    <div className="item-detail-container">
      <StarchiBox styles={{ padding: "30px" }}>
        {(isLoadingListedTokens || !item?.tokenId) && (
          <div className="item-detail-info item-loader">
            <ClipLoader color="#ffffff" loading css="margin: auto" size={120} />
          </div>
        )}
        {!isLoadingListedTokens && item?.tokenId && (
          <>
            <div className="item-detail-info">
              <FaArrowLeft
                className="item-detail-arrow-left"
                onClick={() => {
                  history.push("/store");
                }}
              />

              <div className="item-detail-title-image">
                <div className="item-detail-title">
                  <NameBadge
                    name={item.name}
                    id={item.tokenId}
                    petBreedId={
                      item.contractType === ContractTypes.Pets
                        ? String(item?.breedId)
                        : ""
                    }
                    elementId={
                      item.contractType === ContractTypes.Elements
                        ? String(item.typeId)
                        : ""
                    }
                  />
                </div>
                <div className="item-detail-image-box">
                  <img
                    src={item.image || undefined}
                    alt={
                      item.contractType === ContractTypes.Elements
                        ? "Element Card"
                        : item.contractType === ContractTypes.Pets
                        ? "Starchi"
                        : ""
                    }
                    className="item-detail-image"
                  />
                </div>
              </div>
              <div className="sell-item-divider" />
              <div className="item-detail-right">
                <span className="item-detail-heading">Sell NFT</span>
                {item.contractType === ContractTypes.Elements ? (
                  <>
                    <div className="item-detail-stage-element">
                      <span className="quick-battle-stage-label">Level: </span>
                      <span className="quick-battle-stage-value">
                        {item?.level}
                      </span>
                    </div>

                    <div className="item-detail-stage-element">
                      <span className="item-detail-stage">
                        Element:{" "}
                        <span className="item-detail-stage-value">
                          {ELEMENTS[item.typeId]?.name || ""}
                        </span>
                      </span>
                    </div>
                  </>
                ) : (
                  item.contractType === ContractTypes.Pets && (
                    <>
                      {item?.stage !== "Egg" && (
                        <>
                          <div className="item-detail-stage-element">
                            <span className="item-detail-stage">
                              Pet Type:{" "}
                              <p className="item-detail-stage-value">
                                {PET_TYPES[Number(item.typeId)]?.name}
                              </p>
                            </span>
                          </div>

                          <div className="item-detail-stage-element">
                            <span className="item-detail-stage">
                              Breed:{" "}
                              <span className="item-detail-stage-value">
                                {BREEDS[Number(item?.breedId)]?.name}
                              </span>
                            </span>
                          </div>
                        </>
                      )}

                      <div className="item-detail-stage-element">
                        <span className="quick-battle-stage-label">
                          Stage:{" "}
                        </span>
                        <span className="quick-battle-stage-value">
                          {item?.stage}
                        </span>
                      </div>
                    </>
                  )
                )}

                <span className="item-detail-heading">Stats</span>
                <div className="item-detail-stats">
                  {item.contractType === ContractTypes.Elements ? (
                    <>
                      <StatValue
                        IconComponent={<GiFist />}
                        backgroundColor="#e92222"
                        title="PowerRatio"
                        value={item?.powerRatio || 0}
                      />
                      <StatValue
                        IconComponent={<BsLightningChargeFill />}
                        backgroundColor="#7b21fc"
                        title="Energy"
                        value={item?.energy || 0}
                      />
                      <StatValue
                        IconComponent={
                          item?.damageType === 0 ? (
                            <RiSwordLine />
                          ) : (
                            <GiWingedScepter />
                          )
                        }
                        backgroundColor="#2a22e9"
                        title="DamageType"
                        value={item?.damageType === 0 ? "Physic" : "Magic"}
                      />
                      {item.effect !== 0 && item?.effect && (
                        <StatValue
                          IconComponent={<GiMagicPortal />}
                          backgroundColor="#18545a"
                          title="Effect"
                          value={`${EFFECTS[item.effect]?.name}`}
                        />
                      )}
                    </>
                  ) : (
                    item.contractType === ContractTypes.Pets && (
                      <>
                        <StatValue
                          IconComponent={<ImHeart />}
                          backgroundColor="#e12939"
                          title="HP"
                          currentValue={item?.hp || 0}
                          value={item?.hp || 0}
                        />
                        <StatValue
                          IconComponent={<BsLightningChargeFill />}
                          backgroundColor="#7b21fc"
                          title="Energy"
                          currentValue={item?.energy || 0}
                          value={item?.energy || 0}
                        />
                        <StatValue
                          IconComponent={<GiClover />}
                          backgroundColor="#16b200"
                          title="Luckiness"
                          currentValue={item?.luckiness || 0}
                          value={item?.luckiness || 0}
                        />
                        <StatValue
                          IconComponent={<FaWind />}
                          backgroundColor="#cec505"
                          title="Speed"
                          currentValue={item?.speed || 0}
                          value={item?.speed || 0}
                        />
                      </>
                    )
                  )}
                </div>

                <div className="item-detail-stage-element">
                  <span className="quick-battle-stage-value">
                    Listing period:
                  </span>
                  <span
                    className="quick-battle-stage-label"
                    style={{ marginLeft: "0.5rem" }}
                  >
                    3 months
                  </span>
                </div>

                <p className="list-price-label">List for:</p>

                <div className="list-price-input-container">
                  <Input
                    placeholder="Choose Price"
                    value={
                      sellPrice[sellPrice.length - 1] === "."
                        ? sellPrice
                        : Number(sellPrice)
                    }
                    onChange={(ev) => {
                      const { value } = ev.target;

                      if (value.match(/^[0-9]*\.?[0-9]*$/)) {
                        setSellPrice(value);
                      }
                    }}
                  />
                  <p className="list-price-token">BUIDL</p>
                </div>
                <div className="item-detail-price-buy">
                  <div className="item-detail-buy">
                    <Button
                      text={
                        isListing
                          ? "Listing..."
                          : `List ${
                              item.contractType === ContractTypes.Elements
                                ? "Element"
                                : item.contractType === ContractTypes.Pets &&
                                  "Starchi"
                            } (${Number(sellPrice)} BUIDL)`
                      }
                      disabled={isListing}
                      onClick={handleListNFT}
                    />
                  </div>
                </div>
              </div>
            </div>
            {item.contractType === ContractTypes.Pets && (
              <div className="item-detail-elements-container">
                <p className="item-detail-elements-description">
                  Attached Elements:
                </p>
                <div className="item-detail-elements-wrapper">
                  {item.elements && item.elements?.length > 0 ? (
                    item.elements?.map((element) => (
                      <div className="item-detail-elements-inner-content">
                        <img
                          src={ELEMENTS[element.typeId]?.image || undefined}
                          alt="icon"
                          className="card-image"
                        />
                        <div className="item-detail-elements-inner-content-props">
                          <div className="item-detail-stage-element">
                            <span className="quick-battle-stage-label">
                              Level:{" "}
                            </span>
                            <span className="quick-battle-stage-value">
                              {CARD_LEVELS[Number(element.level) - 1]}
                            </span>
                          </div>

                          <div className="item-detail-elements-inner-content-stats">
                            <StatValue
                              containerStyle={{ marginRight: "0.8rem" }}
                              IconComponent={<GiFist />}
                              backgroundColor="#e92222"
                              title="PowerRatio"
                              value={element.powerRatio}
                            />
                            <StatValue
                              IconComponent={<BsLightningChargeFill />}
                              backgroundColor="#7b21fc"
                              title="Energy"
                              value={element.energy}
                            />
                            <StatValue
                              IconComponent={
                                element?.damageType === 0 ? (
                                  <RiSwordLine />
                                ) : (
                                  <GiWingedScepter />
                                )
                              }
                              backgroundColor="#2a22e9"
                              title="DamageType"
                              value={
                                element?.damageType === 0 ? "Physic" : "Magic"
                              }
                            />
                            {element.effect !== 0 && element?.effect && (
                              <StatValue
                                IconComponent={<GiMagicPortal />}
                                backgroundColor="#18545a"
                                title="Effect"
                                value={`${EFFECTS[element.effect]?.name}`}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="item-detail-element-empty">--</p>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </StarchiBox>

      <Modal
        show={openAlertModal}
        size="lg"
        backdrop="static"
        centered
        contentClassName="quick-battle-modal-content"
        onHide={() => setOpenAlertModal(false)}
      >
        <div className="quick-battle-modal-bg" />
        <ModalHeader bsPrefix="quick-battle-modal-header">
          <CloseIcon
            className="quick-battle-modal-close-icon"
            onClick={() => setOpenAlertModal(false)}
          />
        </ModalHeader>
        <ModalBody bsPrefix="alert-elements-modal">
          <span className="alert-elements-modal-attention">Attention!</span>
          <span className="alert-elements-modal-text">
            All the elements attached to this Starchi will also be sold.
          </span>

          <Button text="OK" onClick={() => setOpenAlertModal(false)} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ItemSell;
