import "./styles.scss";

interface IconButtonProps {
  icon?: string;
  iconComponent?: JSX.Element;
  onClick(): void;
  containerStyles: Record<string, unknown>;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  iconComponent,
  onClick,
  containerStyles,
}) => {
  return (
    <div
      className="icon-button-bg"
      style={{
        backgroundColor: "#FFB100",
        width: "30px",
        height: "30px",
        borderRadius: "8px",
        border: `solid 1px white`,
        ...containerStyles,
      }}
      onClick={onClick}
      aria-hidden="true"
    >
      <div className="icon-button">
        {icon ? (
          <img src={icon} className="icon-button-image" alt="icon" />
        ) : (
          iconComponent
        )}
      </div>
    </div>
  );
};

export default IconButton;
