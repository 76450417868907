import { ElementProps } from "./interfaces/IPet";

import { getElement, getElements } from "./chain/pets";
import { ELEMENTS } from "../constants/constants";

export interface ElementWithDetailsProps extends ElementProps {
  elementId: number;
  image: string | null;
}

export const getElementWithDetails = async (
  provider: any,
  elementId: number,
): Promise<ElementWithDetailsProps> => {
  const elementData = await getElement(provider, elementId);

  return {
    ...elementData,
    energy: elementData.energy,
    powerRatio: elementData.powerRatio,
    damageType: elementData.damageType,
    typeId: elementData.typeId,
    level: elementData.level,
    attachedPetId: elementData.attachedPetId.toNumber(),
    elementId,
    image: ELEMENTS[elementData.typeId]?.image || null,
  };
};

export const listElementsWithDetailsByAccount = async (
  provider: any,
  account: string,
) => {
  const elementIds = await getElements(provider, account);
  const elementsWithDetails = await Promise.all(
    elementIds.map((elementId) =>
      getElementWithDetails(provider, elementId.toNumber()),
    ),
  );
  return elementsWithDetails;
};
