import { useState } from "react";
import "./styles.scss";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useRank } from "../../../hooks/useRank";
import FilterMenu from "../../../components/FilterMenu";
import StarchiBox from "../../../components/Starchibox";

const sortValues = [
  { name: "Top Players", index: "players", value: 0 },
  { name: "Top Starchis", index: "starchis", value: 1 },
  { name: "Top Collectors", index: "collectors", value: 2 },
  { name: "Graveyard", index: "graveyard", value: 3 },
];

const tableHeaders = {
  players: [
    { title: "Rank", value: "id", image: "", width: "25%" },
    { title: "Player", value: "player", image: "", width: "25%" },
    { title: "Games Won", value: "won", image: "", width: "25%" },
    { title: "Total Games", value: "games", image: "", width: "25%" },
  ],
  starchis: [
    { title: "Rank", value: "id", image: "", width: "40px" },
    { title: "Starchi", value: "name", image: "image", width: "30%" },
    { title: "Owner", value: "owner", image: "", width: "30%" },
    { title: "Birth", value: "birth", image: "", width: "30%" },
  ],
  collectors: [
    { title: "Rank", value: "id", image: "", width: "25%" },
    { title: "Player", value: "player", image: "", width: "25%" },
    { title: "Games Won", value: "won", image: "", width: "25%" },
    { title: "Total Games", value: "games", image: "", width: "25%" },
  ],
  graveyard: [
    { title: "Rank", value: "id", image: "", width: "25%" },
    { title: "Player", value: "player", image: "", width: "25%" },
    { title: "Games Won", value: "won", image: "", width: "25%" },
    { title: "Total Games", value: "games", image: "", width: "25%" },
  ],
};

const RankList = () => {
  const { items: ranks, isLoading } = useRank();
  const [selectedTypes, setSelectedTypes] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState("players");

  return (
    <div className="rank-container">
      <div className="rank-title-container">
        <div className="rank-title-count">
          <h1 className="rank-heading">TOP RANK</h1>
          <span className="rank-sub-heading">
            Vested rewards and unlocked deposits can be withdrawn{" "}
            <span className="rank-sub-bold">at any time.</span>
          </span>
        </div>
        <div className="rank-quick-battle">
          <FilterMenu
            items={sortValues}
            value={selectedTypes}
            onChange={(value) => {
              setSelectedTypes(value);
              setSelectedIndex(sortValues[value].index);
            }}
          />
        </div>
      </div>
      {isLoading && (
        <div className="rank-loader">
          <LinearProgress />
        </div>
      )}
      {!isLoading && (
        <div className="ranks-list">
          <StarchiBox horizontal={false} styles={{ padding: "30px 40px" }}>
            <table className="rank-table">
              <tbody>
                <tr className="rank-table-header">
                  {tableHeaders[selectedIndex].map((item) => (
                    <th
                      key={item.title}
                      className="rank-table-th"
                      style={{ width: item.width }}
                    >
                      {item.title}
                    </th>
                  ))}
                </tr>
                {ranks[selectedIndex].map((rank, i) => (
                  <tr key={i} className="rank-table-row">
                    {tableHeaders[selectedIndex].map((item) => (
                      <td key={item.value} className="rank-table-td">
                        {item.image && rank[item.image] && (
                          <img
                            src={rank[item.image]}
                            alt="img"
                            className="rank-table-td-img"
                          />
                        )}
                        {rank[item.value]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </StarchiBox>
        </div>
      )}
    </div>
  );
};

export default RankList;
