import {
  useCallback,
  useEffect,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

import { connectorsByName, injected } from "../connectors";
import { blockchainParams } from "../config";
import { toastError } from "../utils/errorHandlers";

interface AccountContextData {
  account: { address?: null | string };
  connectWallet(name: keyof typeof connectorsByName): Promise<void>;
  disconnectWallet(): void;
  provider: any;
}

const AccountContext = createContext<AccountContextData>(
  {} as AccountContextData,
);

const AccountProvider: React.FC = ({ children }) => {
  const web3Context = useWeb3React();
  const [web3, setWeb3] = useState<Web3 | undefined>(undefined);
  const { library, account, activate, active, chainId, deactivate } =
    web3Context;

  const requestChangeNetworkAndActivate = useCallback(async () => {
    const provider = window?.ethereum;
    if (!provider) {
      toastError("Metamask is not installed, please install!");
    }

    if (blockchainParams.chainId) {
      console.info(chainId, parseInt(blockchainParams.chainId, 16));

      if (chainId !== parseInt(blockchainParams.chainId, 16)) {
        try {
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: blockchainParams.chainId }],
          });

          await activate(injected);
          localStorage.setItem("isWalletConnected", "true");
        } catch (switchError: any) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError?.code === 4902) {
            try {
              await provider.request({
                method: "wallet_addEthereumChain",
                params: [blockchainParams],
              });
              localStorage.setItem("isWalletConnected", "true");
            } catch (addError) {
              console.log(addError);
              toastError(addError);
              localStorage.setItem("isWalletConnected", "false");
            }
          } else if (switchError?.code === 4001) {
            toastError(
              "To connect your wallet you must switch to the right network!",
            );
            localStorage.setItem("isWalletConnected", "false");
          }
        }
      }
    }
  }, [chainId, activate]);

  useEffect(() => {
    const connectToWallet = async () => {
      try {
        const isAuthorized = await injected.isAuthorized();

        if (isAuthorized) {
          console.info("injecting...");
          await requestChangeNetworkAndActivate();
        }
      } catch (err: any) {
        let errorMessage = err;

        if (err?.message.includes("wallet_switchEthereumChain")) {
          errorMessage = "Please switch your wallet network.";
        }

        toastError(errorMessage);
        console.error("WALLET_ERROR", err);
      }
    };

    if (localStorage?.getItem("isWalletConnected") === "true") {
      connectToWallet();
    }
  }, [requestChangeNetworkAndActivate]);

  useEffect(() => {
    if (
      active &&
      blockchainParams?.chainId &&
      chainId === parseInt(blockchainParams?.chainId, 16)
    ) {
      if (account && library) {
        console.info("setting account...");
      }
    } else {
      setWeb3(undefined);
    }
  }, [active, account, library, chainId]);

  useEffect(() => {
    if (active && !account) {
      setWeb3(undefined);
      deactivate();
    }
  }, [active, account, deactivate]);

  useEffect(() => {
    if (library?.provider) {
      console.info("setting web3 provider...");
      setWeb3(new Web3(library.provider));
    } else {
      setWeb3(undefined);
      deactivate();
    }
  }, [library, deactivate]);

  const disconnectWallet = useCallback(() => {
    console.log("Disconnect Wallet");
    try {
      deactivate();
      localStorage.setItem("isWalletConnected", "false");

      setWeb3(undefined);
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  }, [deactivate]);

  const networkChanged = useCallback(
    async (newChainId) => {
      if (
        localStorage?.getItem("isWalletConnected") === "true" &&
        newChainId === blockchainParams?.chainId
      ) {
        const isAuthorized = await injected.isAuthorized();

        if (isAuthorized) {
          console.info("injecting...");
          await activate(injected);
        }
      } else if (
        blockchainParams.chainId &&
        newChainId !== blockchainParams.chainId
      ) {
        deactivate();
        setWeb3(undefined);
      }
    },
    [deactivate, activate],
  );

  useEffect(() => {
    window?.ethereum?.on("chainChanged", networkChanged);

    return () =>
      window?.ethereum?.removeListener("chainChanged", networkChanged);
  }, [networkChanged]);

  const connectWallet = useCallback(
    async (name: keyof typeof connectorsByName) => {
      console.log("Connect Wallet -> ", name);
      try {
        await requestChangeNetworkAndActivate();
      } catch (err) {
        console.log(err);
        toastError(err);
      }
    },
    [requestChangeNetworkAndActivate],
  );

  const contextValue = useMemo(
    () => ({
      account: { address: account?.toLowerCase() },
      provider: library,
      web3,
      connectWallet,
      disconnectWallet,
    }),
    [account, connectWallet, web3, disconnectWallet, library],
  );

  return (
    <AccountContext.Provider value={contextValue}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = (): AccountContextData => {
  const context = useContext(AccountContext);

  return context;
};

export default AccountProvider;
