import StarchiBox from "../../../../components/Starchibox";
import Button from "../../../../components/Button";

import "../styles.scss";
import { ArenaDetailsProps } from "../../../../api/battle";

const ARENA_MODES = [undefined, "1v1", "2v2", "3v3"];

interface ArenaCardProps {
  arena: ArenaDetailsProps;
  onJoin(arenaId: string): Promise<void>;
}

const ArenaCard: React.FC<ArenaCardProps> = ({ arena, onJoin }) => {
  return (
    <StarchiBox horizontal={false} styles={{ padding: "30px 40px" }}>
      <div className="arena-card-container">
        <div className="arena-card-heading">Created by {arena?.host}</div>
        <div className="arena-card-divider" />
        <div className="arena-card-body">
          <div className="arena-card-info">
            <div className="arena-card-text">Arena</div>
            <div className="arena-card-value">{arena.id}</div>
          </div>
          <div className="arena-card-info">
            <div className="arena-card-text">Mode</div>
            <div className="arena-card-value">
              {ARENA_MODES[arena?.mode || 1]}
            </div>
          </div>
          <div className="arena-card-action">
            <Button
              size="md"
              text="JOIN"
              onClick={() => {
                onJoin(String(arena.id));
              }}
            />
          </div>
        </div>
      </div>
    </StarchiBox>
  );
};

export default ArenaCard;
