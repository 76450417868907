import { formatDuration, intervalToDuration } from "date-fns";

const formatSecondsToDuration = (seconds = 0): string => {
  const duration = intervalToDuration({
    start: 0,
    end: seconds * 1000,
  });

  return formatDuration(duration);
};

export default formatSecondsToDuration;
