import { InputHTMLAttributes } from "react";
import "./styles.scss";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
};

const Input: React.FC<InputProps> = ({ label = "", ...props }) => {
  return (
    <div className="input-container">
      <span className="input-label">{label}</span>
      <input className="input-box" {...props} />
    </div>
  );
};

export default Input;
