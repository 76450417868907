import Slider from "rc-slider";

import "./styles.scss";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

interface StatBarProps {
  title: string;
  color: string;
  IconComponent: JSX.Element;
  min: number;
  max: number;
  onChange: (valueOne: number, valueTwo: number) => void;
  onAfterChange: (valueOne: number, valueTwo: number) => void;
}

const StatBar: React.FC<StatBarProps> = ({
  title,
  color,
  IconComponent,
  min,
  max,
  onChange,
  onAfterChange,
}) => {
  return (
    <div className="bar-container">
      {/* <div className="bar-icon">
        <img src={icon} alt="icon" className="bar-image" />
      </div> */}
      <div className="stat-value-icon" style={{ backgroundColor: color }}>
        {IconComponent}
      </div>

      <div className="bar-setting">
        <span className="bar-title">{title}</span>
        <div className="bar-tick-box">
          <span className="bar-start">{min}</span>
          <div className="bar-tick-bar">
            <Range
              trackStyle={[{ backgroundColor: color }]}
              handleStyle={[
                {
                  backgroundColor: "#ffffff",
                  border: "none",
                  width: "8px",
                  height: "8px",
                  marginTop: "-2px",
                },
                {
                  backgroundColor: "#ffffff",
                  border: "none",
                  width: "8px",
                  height: "8px",
                  marginTop: "-2px",
                },
              ]}
              min={0}
              max={100}
              defaultValue={[min, max]}
              value={[min, max]}
              tipFormatter={(value) => `${value}`}
              onChange={(values) => {
                onChange(values[0], values[1]);
              }}
              onAfterChange={(values) => {
                onAfterChange(values[0], values[1]);
              }}
            />
          </div>
          <span className="bar-end">{max}</span>
        </div>
      </div>
    </div>
  );
};

export default StatBar;
