import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

interface IRPC_URL {
  [chainId: number]: string;
}

const RPC_URLS = {
  1337: "http://127.0.0.1:8545",
  1: "https://mainnet.infura.io/v3/1eb884084d77488cb2e87bd298bc7cb8",
  4: "https://rinkeby.infura.io/v3/1eb884084d77488cb2e87bd298bc7cb8",
  56: "https://bsc-dataseed.binance.org",
  97: "https://data-seed-prebsc-2-s3.binance.org:8545",
  137: "https://rpc-mainnet.matic.network",
  250: "https://apis.ankr.com/bbd46eb85b7e4564b4c2424a26a2555e/6fcf448e08d85dc2a0726dea7d38bc8d/fantom/full/main",
  80001: "https://rpc-mumbai.maticvigil.com",
  43114: "https://api.avax.network/ext/bc/C/rpc",
} as IRPC_URL;

export const injected = new InjectedConnector({
  supportedChainIds: [1337, 1, 3, 4, 5, 42, 56, 97, 137, 250, 80001, 43114],
});

export const injectedtw = new InjectedConnector({
  supportedChainIds: [1337, 1, 3, 4, 5, 42, 56, 97, 137, 250, 80001, 43114],
});

// export const network = new NetworkConnector({
//   urls: { 1: RPC_URLS[1], 4: RPC_URLS[4] },
//   defaultChainId: 1,
//   pollingInterval: POLLING_INTERVAL
// });

export const walletconnect = (chainId: number): WalletConnectConnector => {
  return new WalletConnectConnector({
    rpc: {
      [chainId]: RPC_URLS[chainId],
    },
    supportedChainIds: [1337, 1, 3, 4, 5, 56, 97, 137, 250, 80001, 43114],
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    // pollingInterval: POLLING_INTERVAL, (WalletConnectConnector config doesnt support this property)
  });
};

export const WalletLink = (chainId: number): WalletLinkConnector => {
  return new WalletLinkConnector({
    url: RPC_URLS[chainId],
    appName: "Starter.xyz",
    appLogoUrl: "https://starter.xyz/logo_x200.png",
  });
};

export const connectorsByName = {
  MetaMask: injected,
  TrustWallet: injectedtw,
  WalletConnect: walletconnect(137),
  Coinbase: WalletLink(137),
};
