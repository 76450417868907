import { ethers } from "ethers";

export const requestSignature = async (
  message: string,
  provider: any,
): Promise<string> => {
  const signer = provider.getSigner();
  const signature = await signer.signMessage(message);

  return signature;
};

export const recoverAddress = async (
  signature: string,
  message: string,
): Promise<string> => {
  const recover = ethers.utils.verifyMessage(message, signature);

  return recover.toLowerCase();
};
