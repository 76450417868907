import bigInt from "big-integer";
import NeutralIcon from "../img/elements/Neutral.svg";
import EarthIcon from "../img/elements/Earth.svg";
import PoisonIcon from "../img/elements/Poison.svg";
import WaterIcon from "../img/elements/Water.svg";
import FireIcon from "../img/elements/Fire.svg";
import LightningIcon from "../img/elements/Lightning.svg";

export const ELEMENTS = [
  undefined, // element type id 0 is invalid
  {
    id: 1,
    name: "Neutral",
    icon: NeutralIcon,
    color: "#a7a7a7",
    image: "/img/elements-gifs/starchi-nft-neutral.gif",
  },
  {
    id: 2,
    name: "Fire",
    icon: FireIcon,
    color: "#812c14",
    image: "/img/elements-gifs/starchi-nft-fire.gif",
  },
  {
    id: 3,
    name: "Dark",
    icon: PoisonIcon,
    color: "#9621ea",
    image: "/img/elements-gifs/starchi-nft-dark.gif",
  },
  {
    id: 4,
    name: "Earth",
    icon: EarthIcon,
    color: "#743423",
    image: "/img/elements-gifs/starchi-nft-earth.gif",
  },
  {
    id: 5,
    name: "Lightning",
    icon: LightningIcon,
    color: "#b7a53f",
    image: "/img/elements-gifs/starchi-nft-lightning.gif",
  },
  {
    id: 6,
    name: "Water",
    icon: WaterIcon,
    color: "#2a6972",
    image: "/img/elements-gifs/starchi-nft-water.gif",
  },
];

export const NEUTRAL_ELEMENT_INDEX = 1;
export const ATTACK_AND_SLEEP_TIMEOUT = 3000;
export const MAX_ELEMENTS_ATTACHED_TO_PET = 3;
export const MIN_PHASE_TO_BATTLE = 2;

export const BREEDS = [
  undefined, // breed type id 0 is invalid
  { id: 1, name: "Fairy", color: "#edc30f", image: "/img/breeds/1.svg" },
  { id: 2, name: "Esper", color: "#0050cc", image: "/img/breeds/2.svg" },
  { id: 3, name: "Prime", color: "#6c6258", image: "/img/breeds/3.svg" },
  { id: 4, name: "Nayas", color: "#c90000", image: "/img/breeds/4.svg" },
  { id: 5, name: "Wicked", color: "#844d6c", image: "/img/breeds/5.svg" },
];

export const EFFECTS = [
  undefined, // type id 0 is invalid
  {
    id: 1,
    name: "SpeedUp",
  },
  {
    id: 2,
    name: "SpeedDown",
  },
  {
    id: 3,
    name: "MagicDefenseUp",
  },
  {
    id: 4,
    name: "MagicAttackUp",
  },
  {
    id: 5,
    name: "MagicDefenseDown",
  },
  {
    id: 6,
    name: "MagicAttackDown",
  },
  {
    id: 7,
    name: "PhysicDefenseUp",
  },
  {
    id: 8,
    name: "PhysicAttackUp",
  },
  {
    id: 9,
    name: "PhysicDefenseDown",
  },
  {
    id: 10,
    name: "PhysicAttackDown",
  },
  {
    id: 11,
    name: "Heal",
  },
  {
    id: 12,
    name: "Dispel",
  },
  {
    id: 13,
    name: "Shield",
  },
  {
    id: 14,
    name: "Burn",
  },
  {
    id: 15,
    name: "Poison",
  },
  {
    id: 16,
    name: "Freeze",
  },
  {
    id: 17,
    name: "Paralisys",
  },
  {
    id: 18,
    name: "Stun",
  },
];

export const PET_TYPES = [
  undefined, // type id 0 is invalid
  { id: 1, name: "Bat" },
  { id: 2, name: "Bee" },
  { id: 3, name: "Bird" },
  { id: 4, name: "Magma" },
  { id: 5, name: "Ghost" },
  { id: 6, name: "Mushroom" },
  { id: 7, name: "Plant" },
  { id: 8, name: "Spider" },
];

export const PET_STAGES = ["Egg", "Child", "Teen", "Adult"];
export const CARD_LEVELS = ["Bronze", "Silver", "Golden", "Diamond"];
export const DAMAGE_TYPES = [
  { id: 0, name: "Physic" },
  { id: 1, name: "Magic" },
];

// TODO: Retrieve these values from the chain
export const BOOSTs = {
  cannotBattle: 0, // 0% - 29%, Insufficient attributes to battle
  debuff: 30, // 30% - 59%, Uses only 80% of his attributes
  normal: 60, // 60% - 89%, Uses 100% of hist attributes
  buff: 90, // 90% - 100%, Uses 110% to all attributes
};

export const BATTLE_MODES = [
  { id: 1, mode: "1vs1" },
  { id: 2, mode: "2vs2" },
  { id: 3, mode: "3vs3" },
];

export const TOTAL_REWARD_AMOUNT = 25000000;

export const BUY_LINK = {
  "0x6Ccf12B480A99C54b23647c995f4525D544A7E72":
    "https://quickswap.exchange/#/swap?outputCurrency=0x6Ccf12B480A99C54b23647c995f4525D544A7E72",
  "0x7a6830a9E6F964104b52243922a7738dE4cfF84a":
    "https://quickswap.exchange/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0xed0bb69f9f96e8c5fffdf16c468ecec385134ea5",
  "0x8c8F796D49d6720E62fF4aCab35879538E57Ec9F":
    "https://quickswap.exchange/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x6ccf12b480a99c54b23647c995f4525d544a7e72",
  "0xED0bb69f9F96E8C5FffdF16c468eceC385134eA5":
    "https://quickswap.exchange/#/swap?outputCurrency=0xED0bb69f9F96E8C5FffdF16c468eceC385134eA5",
};

export const SHORT_ADDRESS = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(
    address.length - 4,
    address.length,
  )}`;
};

export const FORMAT_DURATION = (timestamp: number): string => {
  if (timestamp < 60) {
    return `${timestamp} seconds`;
  }

  if (timestamp < 3600) {
    return `${(timestamp / 60).toFixed(0)} minutes`;
  }
  if (timestamp < 3600 * 24) {
    return `${(timestamp / 3600).toFixed(0)} hours`;
  }
  return `${(timestamp / (3600 * 24)).toFixed(0)} days`;
};

export const toFixed = (bi: number, decimals = 18, desired = 0) => {
  const trunc = decimals - desired;
  const shift = decimals - trunc;
  return (
    bigInt(bi)
      .divide(10 ** trunc)
      .toJSNumber() /
    10 ** shift
  ).toFixed(desired);
};

export const expandFixed = (value: number, decimals = 18) => {
  return bigInt(value * 10 ** decimals);
};

export const FORMAT_APY = (apy: number): string => {
  if (apy > 1000000000) {
    return `${parseInt(String(apy / 1000000000)).toLocaleString()} B`;
  }
  return parseInt(String(apy)).toLocaleString();
};
