import {
  useCallback,
  useEffect,
  createContext,
  useContext,
  useState,
  useMemo,
} from "react";
import { ElementWithDetailsProps } from "../../../api/elements";
import { getPetWithDetails, PetWithDetailsProps } from "../../../api/pets";
import { useAccount } from "../../../contexts/AccountContext";

import { useElements } from "../../../hooks/useElements";
import { useNurturing } from "../../../hooks/useNurturing";
import { usePets } from "../../../hooks/usePets";
import { toastError, toastSuccess } from "../../../utils/errorHandlers";

interface CrateContextData {
  myElements: ElementWithDetailsProps[];
  myPets: PetWithDetailsProps[];
  isLoadingMyPets: boolean;
  attachNewElementToPet(petId: number, elementId: number): Promise<void>;
  detachPetElement(petId: number, elementId: number): Promise<void>;
  evolvePetById(petId: number): Promise<void>;
  onAwake(petId: number): Promise<boolean>;
  onSleep(petId: number): Promise<boolean>;
  onExercise(petId: number): Promise<boolean>;
  onFeed(petId: number): Promise<boolean>;
  onShower(petId: number): Promise<boolean>;
  watchPetEvolution(petId: number): Promise<void>;
  updateStarchiName(petId: number, newName: string): Promise<void>;
}

const CrateContext = createContext<CrateContextData>({} as CrateContextData);

const CrateProvider: React.FC = ({ children }) => {
  const { provider } = useAccount();

  const { fetchMyElements, attachElementToPet, detachElementFromPet } =
    useElements();
  const { fetchMyPets, evolvePet, updatePetName, watchPetEvolving } = usePets();
  const { awakeMyPet, bedMyPet, exerciseMyPet, feedMyPet, showerMyPet } =
    useNurturing();
  const [myPets, setMyPets] = useState<PetWithDetailsProps[]>([]);
  const [myElements, setMyElements] = useState<ElementWithDetailsProps[]>([]);
  const [isLoadingMyPets, setIsLoadingMyPets] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const [pets, elements] = await Promise.all([
        fetchMyPets(),
        fetchMyElements(),
      ]);

      if (pets) {
        setMyPets(pets);
        setIsLoadingMyPets(false);
      }
      setMyElements(elements);
    };

    fetchData();
  }, [fetchMyElements, fetchMyPets]);

  const updatePetData = useCallback(
    async (petId) => {
      if (!provider) {
        return;
      }

      const updatedPet = await getPetWithDetails(provider, petId);

      setMyPets((prevPets) => {
        const newPetsList = [...prevPets];
        const petToUpdateIndex = prevPets.findIndex(
          (pet) => pet.petId === petId,
        );

        newPetsList[petToUpdateIndex] = updatedPet;

        return newPetsList;
      });
    },
    [provider],
  );

  const attachNewElementToPet = useCallback(
    async (petId, elementId) => {
      const receipt = await attachElementToPet(petId, elementId);

      if (receipt?.status) {
        // Append element to the pet
        setMyPets((prevPets) => {
          const newPetsList = [...prevPets];

          const petToUpdateElements = newPetsList.find(
            (pet) => pet.petId === petId,
          );

          const elementToAppend = myElements.find(
            (element) => element.elementId === elementId,
          );

          if (petToUpdateElements && elementToAppend) {
            petToUpdateElements.elements = [
              ...petToUpdateElements.elements,
              elementToAppend,
            ];
          }

          return newPetsList;
        });

        // Assign element to the pet
        setMyElements((prevElements) => {
          const newElementsList = [...prevElements];

          const elementToUpdate = newElementsList.find(
            (element) => element.elementId === elementId,
          );

          if (elementToUpdate) {
            elementToUpdate.attachedPetId = petId;
          }

          return newElementsList;
        });
        toastSuccess("Successfully attached element.");
      }
    },
    [attachElementToPet, myElements],
  );

  const detachPetElement = useCallback(
    async (petId, elementId) => {
      const receipt = await detachElementFromPet(petId, elementId);

      if (receipt?.status) {
        // Remove element from pet
        setMyPets((prevPets) => {
          const newPetsList = [...prevPets];

          const petToUpdateElements = newPetsList.find(
            (pet) => pet.petId === petId,
          );

          // TODO: Solve performance issue
          if (petToUpdateElements) {
            petToUpdateElements.elements = petToUpdateElements.elements.filter(
              (element) => element.elementId !== elementId,
            );
          }

          return newPetsList;
        });

        // Append removed element to myElements
        setMyElements((prevElements) => {
          const newElementsList = [...prevElements];

          const elementToUpdate = newElementsList.find(
            (element) => element.elementId === elementId,
          );

          if (elementToUpdate) {
            elementToUpdate.attachedPetId = 0;
          }

          return newElementsList;
        });
        toastSuccess("Successfully detached element.");
      }
    },
    [detachElementFromPet],
  );

  const evolvePetById = useCallback(
    async (petId) => {
      await evolvePet(petId);
      await watchPetEvolving(petId);

      await updatePetData(petId);
    },
    [evolvePet, updatePetData, watchPetEvolving],
  );

  const watchPetEvolution = useCallback(
    async (petId) => {
      try {
        await watchPetEvolving(petId);

        await updatePetData(petId);
      } catch (err) {
        console.log(err);
        toastError(err);
      }
    },
    [watchPetEvolving, updatePetData],
  );

  const updateStarchiName = useCallback(
    async (petId, newName) => {
      await updatePetName(petId, newName);
      await updatePetData(petId);
    },
    [updatePetName, updatePetData],
  );

  const onAwake = useCallback(
    async (petId) => {
      const isAwaked = await awakeMyPet(petId);

      if (isAwaked) {
        await updatePetData(petId);
      }

      return isAwaked;
    },
    [awakeMyPet, updatePetData],
  );

  const onSleep = useCallback(
    async (petId) => {
      const isSleeping = await bedMyPet(petId);

      if (isSleeping) {
        await updatePetData(petId);
      }

      return isSleeping;
    },
    [bedMyPet, updatePetData],
  );

  const onExercise = useCallback(
    async (petId) => {
      const isExcercised = await exerciseMyPet(petId);

      if (isExcercised) {
        await updatePetData(petId);
      }

      return isExcercised;
    },
    [exerciseMyPet, updatePetData],
  );

  const onFeed = useCallback(
    async (petId) => {
      const hasEaten = await feedMyPet(petId);

      if (hasEaten) {
        await updatePetData(petId);
      }

      return hasEaten;
    },
    [feedMyPet, updatePetData],
  );

  const onShower = useCallback(
    async (petId) => {
      const tookShower = await showerMyPet(petId);

      if (tookShower) {
        await updatePetData(petId);
      }

      return tookShower;
    },
    [showerMyPet, updatePetData],
  );

  const contextValue = useMemo(
    () => ({
      myElements,
      attachNewElementToPet,
      detachPetElement,
      myPets,
      isLoadingMyPets,
      evolvePetById,
      onAwake,
      onSleep,
      onExercise,
      onFeed,
      onShower,
      watchPetEvolution,
      updateStarchiName,
    }),
    [
      myElements,
      myPets,
      isLoadingMyPets,
      attachNewElementToPet,
      detachPetElement,
      evolvePetById,
      onAwake,
      onSleep,
      onExercise,
      onFeed,
      onShower,
      watchPetEvolution,
      updateStarchiName,
    ],
  );

  return (
    <CrateContext.Provider value={contextValue}>
      {children}
    </CrateContext.Provider>
  );
};

export const useCrate = (): CrateContextData => {
  const context = useContext(CrateContext);

  return context;
};

export default CrateProvider;
