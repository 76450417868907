import "./styles.scss";

import MyCrateBox from "./components/MyCrateBox";
import CrateProvider from "./contexts/CrateContext";

const Crate: React.FC = () => {
  return (
    <div className="crate-container">
      <CrateProvider>
        <MyCrateBox />
      </CrateProvider>
    </div>
  );
};

export default Crate;
