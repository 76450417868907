import "./styles.scss";
import { BREEDS, ELEMENTS } from "../../constants/constants";

interface StarchiNameBadgeProps {
  name: string;
  id: string | number;
  petBreedId?: string;
  elementId?: string;
  className?: string;
}

const NameBadge: React.FC<StarchiNameBadgeProps> = ({
  name,
  id,
  petBreedId,
  elementId,
  className,
}) => {
  return (
    <div className={`badge-container ${className}`}>
      <div
        className="starchi-name"
        style={{
          backgroundColor: petBreedId
            ? petBreedId === "0"
              ? "#130a7a"
              : BREEDS[Number(petBreedId) || 1]?.color
            : elementId && ELEMENTS[Number(elementId) || 1]?.color,
        }}
      >
        {name} #{id}
      </div>
    </div>
  );
};

export default NameBadge;
