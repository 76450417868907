import BatLord from "./img/h1.gif";
import VS from "./img/VS.png";
import Sting from "./img/h2.gif";
import BGAbout from "./img/Great War Bacground.svg";
import Book from "./img/Books Home@2x.png";
import Eggs from "./img/Eggs Home@2x.png";
import BGIntro from "./img/Group 661@2x.png";
import BGLearnMore from "./img/starchi-bg.png";
import Ch1 from "./img/ch1.gif";
import Ch2 from "./img/ch2.gif";
import Ch3 from "./img/ch3.gif";
import Ch4 from "./img/anim1.gif";
import Ch5 from "./img/anim2.gif";
import Ch6 from "./img/anim3.gif";
import Ch7 from "./img/anim4.gif";
import WatchTrailer from "./img/Group 743.svg";

const images = {
  BatLord,
  VS,
  Sting,
  BGAbout,
  Book,
  Eggs,
  BGIntro,
  BGLearnMore,
  Ch1,
  Ch2,
  Ch3,
  Ch4,
  Ch5,
  Ch6,
  Ch7,
  WatchTrailer,
};

export default images;
