import Starchi1 from "../img/starchis/1.png";
import Starchi2 from "../img/starchis/2.png";
import Starchi3 from "../img/starchis/3.png";
import Starchi4 from "../img/starchis/4.png";
import Starchi5 from "../img/starchis/5.png";
import Starchi10 from "../img/starchis/10.png";

export interface RanksDataProps {
  players: Array<{
    id: string;
    player: string;
    won: number;
    games: number;
  }>;
  starchis: Array<{
    id: string;
    name: string;
    image: string;
    owner: string;
    birth: string;
  }>;
  collectors: Array<{
    id: string;
    player: string;
    won: number;
    games: number;
  }>;
  graveyard: Array<any>;
}

export const ranksSampleData = {
  players: [
    {
      id: "#1",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
    {
      id: "#2",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
    {
      id: "#3",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
    {
      id: "#4",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
    {
      id: "#5",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
    {
      id: "#6",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
    {
      id: "#7",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
  ],
  starchis: [
    {
      id: "#1",
      name: "Lui",
      image: Starchi10,
      owner: "iikaioss",
      birth: "November 16, 2021 09:31",
    },
    {
      id: "#2",
      name: "reissa",
      image: Starchi1,
      owner: "Ltziar",
      birth: "November 16, 2021 09:32",
    },
    {
      id: "#3",
      name: "solinqua",
      image: Starchi2,
      owner: "Pierrrra",
      birth: "November 16, 2021 09:33",
    },
    {
      id: "#4",
      name: "sibylla",
      image: Starchi3,
      owner: "sibylla",
      birth: "November 16, 2021 09:34",
    },
    {
      id: "#5",
      name: "vlovvvos",
      image: Starchi4,
      owner: "vlovvvos",
      birth: "November 16, 2021 09:35",
    },
    {
      id: "#6",
      name: "Kailash",
      image: Starchi4,
      owner: "Kailash",
      birth: "November 16, 2021 09:36",
    },
    {
      id: "#7",
      name: "ufuomos",
      image: Starchi5,
      owner: "ufuomos",
      birth: "November 16, 2021 09:37",
    },
  ],
  collectors: [
    {
      id: "#1",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
    {
      id: "#2",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
    {
      id: "#3",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
    {
      id: "#4",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
    {
      id: "#5",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
    {
      id: "#6",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
    {
      id: "#7",
      player: "iikaioss",
      won: 649,
      games: 657,
    },
  ],
  graveyard: [],
} as RanksDataProps;
