import { useCallback, useState } from "react";
import images from "../../../../images";
import "../../styles.scss";

import PopUp from "../Popup";

const Intro: React.FC = () => {
  const [seen, setSeen] = useState(false);

  const togglePop = useCallback(() => {
    setSeen((prevState) => !prevState);
  }, []);

  const toggleClose = useCallback(() => {
    setSeen(false);
  }, []);

  return (
    <div className="intro-section">
      {/* <img alt="" className="intro-bg-stones" src={images.StoneHome} /> */}
      <div className="txt-block">
        <div className="txt-block-parent1">
          <div className="intro-txt txt-block1">A Tamagotchi-inspired</div>
        </div>
        <div className="txt-block-parent">
          <div className="intro-txt txt-block2">story</div>
        </div>
        <div className="txt-block-parent2">
          <div className="intro-txt txt-block3">of hope reborn</div>
        </div>
      </div>
      <div className="characters">
        <img alt="" className="character1" src={images.Ch1} />
        <img alt="" className="character2" src={images.Ch2} />
        <img alt="" className="character3" src={images.Ch3} />
      </div>
      <div className="playbutton">
        <img
          alt=""
          className="watch-trailer"
          src={images.WatchTrailer}
          onClick={togglePop}
          aria-hidden="true"
        />
      </div>

      <div className="trailer" onClick={toggleClose} aria-hidden="true">
        {seen ? <PopUp toggle={togglePop} /> : null}
      </div>
    </div>
  );
};

export default Intro;
