import { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import SliderBar from "../../../components/SliderBar";

import { toFixed, FORMAT_APY } from "../../../constants/constants";

import "./StakingModal.scss";

const StakingModal = ({
  modalOpen,
  onClose,
  pool,
  onStake,
  onApprove,
  isLoading,
}) => {
  const [lockOrLiquid, setLockOrLiquid] = useState(true);
  const [stakeAmount, setStakeAmount] = useState("0");
  const [lockPeriod, setLockPeriod] = useState(8);
  const [lockWeight, setLockWeight] = useState(2);
  const [apyValue, setApyValue] = useState(945);

  useEffect(() => {
    setLockWeight(lockPeriod / 52 + 1);
    if (lockOrLiquid) {
      setApyValue(parseFloat(pool?.apy) / 10);
    } else {
      setApyValue(
        (parseFloat(pool?.apy) / 2) * parseFloat(lockPeriod / 52 + 1),
      );
    }
  }, [lockOrLiquid, lockPeriod, pool?.apy]);

  return (
    <Modal
      show={modalOpen}
      size="md"
      centered
      contentClassName="manage-modal-content"
      onHide={onClose}
    >
      <div className="manage-modal-bg" />
      <ModalHeader bsPrefix="manage-modal-header">
        <div className="staking-modal-heading-container">
          <div className="manage-modal-heading-logo-name">
            <img
              src={`/tokens/${pool?.token?.address}.png`}
              alt="logo"
              className="manage-modal-heading-logo"
            />
            <span className="manage-modal-heading-lg">
              {pool?.token?.symbol}
            </span>
          </div>
        </div>
        <CloseIcon className="manage-modal-close-icon" onClick={onClose} />
      </ModalHeader>
      <ModalBody bsPrefix="staking-modal-body">
        <div className="staking-mode-container">
          <div
            className={`staking-mode-label${lockOrLiquid ? "-selected" : ""}`}
            onClick={() => {
              setLockOrLiquid(true);
            }}
            aria-hidden="true"
          >
            LIQUID
          </div>
          <div className="staking-mode-vertical-line" />
          <div
            className={`staking-mode-label${!lockOrLiquid ? "-selected" : ""}`}
            onClick={() => {
              setLockOrLiquid(false);
            }}
            aria-hidden="true"
          >
            LOCKED
          </div>
        </div>
        <div className="staking-input-container">
          {!lockOrLiquid && (
            <div className="staking-lock-container">
              <div className="staking-lock-label-values">
                <span className="staking-label">
                  Lock for:{" "}
                  <span className="staking-label-bold">{lockPeriod} weeks</span>
                </span>
                <span className="staking-label">
                  Weight:{" "}
                  <span className="staking-label-bold">
                    {lockWeight.toFixed(2)}
                  </span>
                </span>
              </div>
              <div className="staking-lock-slider">
                <SliderBar
                  color="linear-gradient(270deg, #22C8E3 0%, #2226AF 100%)"
                  tipFormatter={(_value) => `${_value} weeks`}
                  value={lockPeriod}
                  onChange={setLockPeriod}
                  min={8}
                  max={52}
                />
              </div>
            </div>
          )}
          <div className="staking-amount-input-box">
            <div className="staking-amount-labels">
              <span className="staking-label">Amount</span>
              <span className="staking-label">
                Balance:{" "}
                <span className="staking-label-bold">
                  {toFixed(pool?.balance, pool?.token?.decimals, 2) || 0}{" "}
                  {pool?.token?.symbol}
                </span>
              </span>
            </div>
            <div className="staking-amount-line">
              <Input
                label=""
                value={stakeAmount}
                onChange={(ev) => {
                  setStakeAmount(ev.target.value);
                }}
              />
              <div
                className="staking-max-button"
                onClick={() => {
                  setStakeAmount(
                    toFixed(pool?.balance, pool?.token?.decimals, 2) || 0,
                  );
                }}
                aria-hidden="true"
              >
                Max
              </div>
            </div>
            <div className="staking-amount-apy">
              <span className="staking-label">
                Est. APY:{" "}
                <span className="staking-label-bold">
                  {FORMAT_APY(apyValue)}%
                </span>
              </span>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div
            className="staking-mode-label-selected"
            onClick={() => {
              setLockOrLiquid(true);
            }}
            aria-hidden="true"
          >
            PLEASE WAIT...
          </div>
        ) : pool?.approved ? (
          <Button
            text="STAKE"
            onClick={() => {
              if (
                stakeAmount > 0 &&
                stakeAmount <=
                  parseFloat(toFixed(pool?.balance, pool?.token?.decimals, 2))
              ) {
                if (lockOrLiquid) {
                  onStake(stakeAmount, 0);
                } else {
                  const currentTimestamp = Date.now() / 1000;
                  onStake(
                    stakeAmount,
                    parseInt(currentTimestamp) + 604800 * lockPeriod,
                  );
                }
              }
            }}
            aria-hidden="true"
          />
        ) : (
          <Button
            text="APPROVE"
            onClick={() => {
              onApprove();
            }}
            aria-hidden="true"
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default StakingModal;
