import { useState, useCallback } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";

import { toastError } from "../../../../utils/errorHandlers";
import { useContractsParams } from "../../../../contexts/ContractsParamsContext";

import { blockchainAddresses } from "../../../../config";

import "../styles.scss";

interface NameChangeModalProps {
  modalOpen: boolean;
  onClose(): void;
  onConfirm(newName: string): void;
}

const NameChangeModal: React.FC<NameChangeModalProps> = ({
  modalOpen,
  onClose,
  onConfirm,
}) => {
  const [newName, setNewName] = useState("");
  const [isApprovingToken, setIsApprovingToken] = useState(false);

  const { changeNamePrice, approvedTokens, approveToken } =
    useContractsParams();

  const handleApproveClick = useCallback(async () => {
    setIsApprovingToken(true);
    try {
      await approveToken(
        blockchainAddresses.buidlToken,
        blockchainAddresses.pets,
      );
    } catch (error) {
      console.error(error);
      toastError(error);
    }
    setIsApprovingToken(false);
  }, [approveToken]);

  return (
    <Modal
      show={modalOpen}
      size="lg"
      centered
      contentClassName="manage-modal-content"
      onHide={onClose}
    >
      <div className="manage-modal-bg" />
      <ModalHeader bsPrefix="manage-modal-header">
        <span className="manage-modal-heading-lg">CHOOSE A NEW NAME</span>
        <CloseIcon className="manage-modal-close-icon" onClick={onClose} />
      </ModalHeader>
      <ModalBody bsPrefix="name-change-modal-body">
        <div className="name-change-modal-section">
          <Input
            placeholder="New name"
            value={newName}
            onChange={(ev) => {
              setNewName(ev.target.value);
            }}
          />
        </div>

        <div className="name-change-modal-confirm">
          {!approvedTokens.petIsApprovedToBuidlTokens ? (
            <Button
              text={
                isApprovingToken
                  ? "Approving..."
                  : "Approve BUIDL for Changing Pet Name"
              }
              size="md"
              disabled={isApprovingToken}
              onClick={handleApproveClick}
            />
          ) : (
            <Button
              text={`Change Name (${changeNamePrice} BUIDL)`}
              onClick={() => {
                if (newName) {
                  onConfirm(newName);
                }
              }}
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NameChangeModal;
