import "./Rank.scss";

import RankList from "./components/RankList";

const RankPage = () => {
  return (
    <div className="total-rank-container">
      <RankList />
    </div>
  );
};

export default RankPage;
