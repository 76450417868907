import { useEffect, useState } from "react";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { ImBlocked } from "react-icons/im";

import Tooltip from "@material-ui/core/Tooltip";

import "./styles.scss";
import { BOOSTs } from "../../constants/constants";

interface StatValueProps {
  title: string;
  IconComponent?: JSX.Element;
  iconSrc?: string;
  backgroundColor: string;
  value: number | string;
  inBattleAttribute?: number;
  currentValue?: number;
  showIconIndicators?: boolean;
  containerStyle?: Record<string, unknown>;
}

const StatValue: React.FC<StatValueProps> = ({
  title,
  IconComponent,
  iconSrc,
  backgroundColor,
  value,
  inBattleAttribute,
  currentValue,
  showIconIndicators = false,
  containerStyle = {},
}) => {
  const [currentBattleBuff, setCurrentBattleBuff] = useState(BOOSTs.normal);

  useEffect(() => {
    if (currentValue && typeof value === "number") {
      setCurrentBattleBuff((currentValue / value) * 100);
    }
  }, [currentValue, value]);

  return (
    <div className="stat-value-container" style={containerStyle}>
      {iconSrc ? (
        <div className="icon-img-container">
          <img className="icon-img" src={iconSrc} alt={String(value)} />
        </div>
      ) : (
        <div className="stat-value-icon" style={{ backgroundColor }}>
          {IconComponent}
        </div>
      )}
      <div className="stat-value-setting">
        <span className="stat-value-title">{title}</span>
        <div className="stat-value-tick-box">
          {inBattleAttribute ? <p>{inBattleAttribute}</p> : <p>{value}</p>}
          {showIconIndicators && (
            <>
              {currentBattleBuff < BOOSTs.debuff && (
                <Tooltip
                  title={
                    <p
                      style={{
                        fontFamily: "Josefin Sans",
                        fontSize: "0.9rem",
                        margin: "0.2rem 0",
                      }}
                    >
                      attribute too low, cannot battle
                    </p>
                  }
                  placement="right"
                  arrow
                >
                  <div className="tooltip-inner-content">
                    <ImBlocked size={15} color="#e30c1e" />
                  </div>
                </Tooltip>
              )}
              {currentBattleBuff >= BOOSTs.debuff &&
                currentBattleBuff < BOOSTs.normal && (
                  <Tooltip
                    title={
                      <p
                        style={{
                          fontFamily: "Josefin Sans",
                          fontSize: "0.9rem",
                          margin: "0.2rem 0",
                        }}
                      >
                        -20% battle debuff
                      </p>
                    }
                    placement="right"
                    arrow
                  >
                    <div className="tooltip-inner-content">
                      <FaArrowDown size={15} color="#e30c1e" />
                    </div>
                  </Tooltip>
                )}
              {currentBattleBuff >= BOOSTs.buff && (
                <Tooltip
                  title={
                    <p
                      style={{
                        fontFamily: "Josefin Sans",
                        fontSize: "0.9rem",
                        margin: "0.2rem 0",
                      }}
                    >
                      10% battle bonus
                    </p>
                  }
                  placement="right"
                  arrow
                >
                  <div className="tooltip-inner-content">
                    <FaArrowUp size={15} color="#16b200" />
                  </div>
                </Tooltip>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatValue;
