import "./styles.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BsShareFill } from "react-icons/bs";
import { Unity, useUnityContext } from "react-unity-webgl";
import { connect as ioConnect, Socket } from "socket.io-client";

import {
  socketServerUrl,
  unityBattleContextPath,
  whitelistAccounts,
} from "../../config";
import { JoinBattleRoomDto } from "./dtos/request/join-battleroom.dto";
import { ArenaStatus, BattleStatus } from "./dtos/response/arena-stats.dto";
import { AttackEventDto } from "./dtos/response/attack-event.dto";
import BattleResult from "./components/BattleResult";
import { useBattle } from "../../contexts/BattleContext";
import { useAccount } from "../../contexts/AccountContext";
import { useArena } from "../../hooks/useArena";
import { toastError, toastSuccess } from "../../utils/errorHandlers";
import { timeout } from "../../utils/time";
import { ATTACK_AND_SLEEP_TIMEOUT } from "../../constants/constants";
import NotInWhitelist from "../../components/NotInWhitelist";
import Button from "../../components/Button";
import Chat from "../../components/Chat";
import ArenaSelectionModal from "../Arena/components/ArenaSelectionModal";
import { ChatEventDto } from "./dtos/response/chat-event.dto";

interface UnityAttackSequenceProps {
  fromPet: number;
  elementAttack: number;
  toPet: number;
  toTeamId: number;
}

interface UnityAttackProps {
  teamId: number;
  attackSequence: UnityAttackSequenceProps[];
}

const Game: React.FC = () => {
  const history = useHistory();
  const { account } = useAccount();
  const { search } = useLocation();

  const {
    unityProvider,
    isLoaded,
    addEventListener,
    sendMessage,
    removeEventListener,
    unload,
    loadingProgression,
  } = useUnityContext({
    productName: "Starchi Battle",
    companyName: "Starchi",
    loaderUrl: `${unityBattleContextPath}/battle.loader.js`,
    dataUrl: `${unityBattleContextPath}/battle.data`,
    frameworkUrl: `${unityBattleContextPath}/battle.framework.js`,
    codeUrl: `${unityBattleContextPath}/battle.wasm`,
    streamingAssetsUrl: `${unityBattleContextPath}/StreamingAssets`,
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  const {
    joinConfig,
    setJoinConfig,
    authSignature,
    updateAuthSignature,
    isOffChain,
  } = useBattle();
  const { watchBattleFinished, submitCancelBattle, myArena, fetchMyArena } =
    useArena();

  const [isBattleResult, showBattleResult] = useState<boolean>(false);
  const [showCancelBattleModal, setShowSelectionArenaModal] =
    useState<boolean>(false);
  const [isBattleFinished, setIsBattleFinished] = useState<boolean>(false);
  const [isWinner, setIsWinner] = useState(false);
  const [showCancelAndInviteButtons, setShowCancelAndInviteButtons] =
    useState(false);

  const [player, setPlayer] = useState<JoinBattleRoomDto | undefined>();
  const [messages, setMessages] = useState<ChatEventDto[]>([]);

  const socket = useRef<Socket | null>(null);

  useEffect(() => {
    if (!isLoaded) return;

    return () => {
      unload();
    };
  }, [unload, isLoaded]);

  useEffect(() => {
    const loadMyArena = async () => {
      if (!isOffChain) {
        await fetchMyArena(isOffChain);
      } else if (isOffChain && isLoaded && player) {
        await timeout(3000);

        await fetchMyArena(isOffChain);
      }
    };

    if (account.address) {
      loadMyArena();
    }
  }, [fetchMyArena, isOffChain, account, player, isLoaded]);

  useEffect(() => {
    if (myArena && myArena.startedAt === "0") {
      setShowCancelAndInviteButtons(true);
    }
  }, [myArena]);

  // Manages Unity Loading
  useEffect(() => {
    addEventListener("canvas", (canvas: HTMLCanvasElement) => {
      canvas.setAttribute("role", "unityCanvas");
    });

    return () => {
      removeEventListener("canvas", (canvas: HTMLCanvasElement) => {
        canvas.setAttribute("role", "unityCanvas");
      });
    };
  }, [addEventListener, removeEventListener]);

  useEffect(() => {
    if (joinConfig?.arenaId || !account.address) {
      return;
    }

    const queryParams = new URLSearchParams(search);

    const arenaId = queryParams.get("arenaId");
    let pets: string | number[] | null = queryParams.get("pets");

    if (pets && arenaId) {
      pets = pets.split(",").map((pet) => Number(pet));

      const queryConfig = {
        arenaId,
        pets,
        petsInitialElementType: [],
      };

      setJoinConfig(queryConfig);

      const localStorageSignature: string | null =
        localStorage.getItem("arenaAuthSignature");

      const load = async () => {
        await updateAuthSignature(arenaId);
      };

      if (!authSignature && !localStorageSignature && account.address) {
        load();
      }
    }

    if (!joinConfig?.arenaId && !arenaId && !pets) {
      setTimeout(() => {
        toastError("You don't have an active battle");
        history.replace("arena");
      }, 4000);
    }
  }, [
    search,
    joinConfig,
    history,
    setJoinConfig,
    account.address,
    authSignature,
    updateAuthSignature,
  ]);

  // Setup player data
  useEffect(() => {
    if (!player && joinConfig && authSignature && account.address) {
      const { arenaId, pets, petsInitialElementType } = joinConfig;

      const newPlayer = {
        arenaId: parseInt(arenaId),
        pets,
        petsInitialElementType,
        offChain: isOffChain,
        signature: authSignature,
        address: account.address,
      } as JoinBattleRoomDto;

      setPlayer(newPlayer);
      console.log("======player=====", newPlayer);
    }
  }, [
    isLoaded,
    joinConfig,
    isOffChain,
    authSignature,
    account.address,
    player,
    updateAuthSignature,
  ]);

  const handleOnUnityAttack = useCallback(
    (data: string) => {
      const attackSequenceData = JSON.parse(data) as UnityAttackProps;

      console.info("handling unity attack...", player);
      if (!player) {
        console.error("invalid player");
        return;
      }

      const attacks = attackSequenceData.attackSequence.map((attack) => ({
        teamId: attackSequenceData.teamId,
        toTeamId: attack.toTeamId,
        from: attack.fromPet,
        element: attack.elementAttack,
        to: attack.toPet,
      }));

      const attackDto = {
        attacks,
        arenaId: player.arenaId,
        signature: player.signature,
      };

      console.log("attackDto", attackDto);

      socket.current?.emit("attack", attackDto);
    },
    [socket, player],
  );

  const handleOnFinishState = useCallback(
    (battleStatus: string) => {
      console.log("battle status changed", battleStatus);
      if (!player) {
        console.error("invalid player");
        return;
      }

      socket.current?.emit("battleStatusChange", {
        battleStatus: Number(battleStatus),
        arenaId: player.arenaId,
      });
    },
    [socket, player],
  );

  // Manages battle server socket connection
  useEffect(() => {
    if (socket.current || !isLoaded || !player) return () => undefined;
    console.info("connecting to game server socket...");

    socket.current = ioConnect(socketServerUrl);

    socket.current.on("arenastatus", async (arenaStatus: ArenaStatus) => {
      console.log("arenaStatus", arenaStatus);

      sendMessage(
        "BattleInterface",
        "ArenaStatus",
        JSON.stringify(arenaStatus),
      );

      if (arenaStatus.battleStatus === BattleStatus.Strategy) {
        setShowCancelAndInviteButtons(false);
      }

      // Means the battle was canceled
      if (arenaStatus.battleStatus === BattleStatus.Canceled) {
        toastError("This battle was canceled");

        setTimeout(() => {
          history.replace("arena");
        }, 3000);
      }

      // Means that we have a winner
      if (arenaStatus.winner !== -1) {
        const winnerAddress = arenaStatus.teams[arenaStatus.winner].address;
        setIsWinner(winnerAddress === player?.address);

        setTimeout(() => {
          showBattleResult(true);
        }, arenaStatus.attacks.length * ATTACK_AND_SLEEP_TIMEOUT + 1500);

        try {
          if (!isOffChain && joinConfig?.arenaId) {
            await watchBattleFinished(joinConfig.arenaId);
          }
        } catch (err) {
          console.log(err);
        }

        setIsBattleFinished(true);
      }
    });

    socket.current.on("attackevent", (attackeventDto: AttackEventDto) => {
      sendMessage(
        "BattleInterface",
        "AttackStatus",
        JSON.stringify(attackeventDto),
      );
    });

    socket.current.on("message", (data: string) => {
      const messageBody = JSON.parse(data);

      if (messageBody.event === "error") {
        const { message } = messageBody.data;

        toastError(`Server Error: ${message}`);
        console.error(`Server Error: ${message}`);

        if (
          message.includes(
            "This address does not correspond to the joined arena",
          )
        ) {
          setTimeout(() => {
            history.replace("arena");
          }, 4000);
        }
      }
    });

    socket.current.on("error", (errorMessage: string) => {
      toastError(`Server Error: ${errorMessage}`);
      console.error(`Server Error: ${errorMessage}`);

      if (
        errorMessage.includes(
          "This address does not correspond to the joined arena",
        )
      ) {
        setTimeout(() => {
          history.replace("arena");
        }, 4000);
      }
    });

    socket.current.on("chatevent", (messageListDto: ChatEventDto[]) => {
      console.log("messageListDto", messageListDto);
      setMessages(messageListDto);
    });

    addEventListener("OnFinishState", handleOnFinishState);
    addEventListener("Attack", handleOnUnityAttack);

    sendMessage("BattleInterface", "UpdateAddress", player.address);

    let redirectToArenaTimeout: NodeJS.Timeout;

    socket.current.on("disconnect", async () => {
      redirectToArenaTimeout = setTimeout(() => {
        history.replace("arena");
        toastError("The battle server connection was terminated");
      }, 60000);
    });

    socket.current.emit("joinArena", player);

    return () => {
      removeEventListener("Attack", handleOnUnityAttack);
      removeEventListener("OnFinishState", handleOnFinishState);
      socket.current?.off("arenastatus");
      socket.current?.off("attackevent");
      socket.current?.off("disconnect");
      socket.current?.off("chatevent");
      clearTimeout(redirectToArenaTimeout);
    };
  }, [
    player,
    isLoaded,
    handleOnUnityAttack,
    handleOnFinishState,
    watchBattleFinished,
    joinConfig,
    isOffChain,
    history,
    sendMessage,
    addEventListener,
    removeEventListener,
  ]);

  const handleCloseBattleAndRedirect = useCallback(() => {
    showBattleResult(false);

    history.replace("arena");
  }, [history]);

  const handleCancelBattleClick = useCallback(async () => {
    try {
      setShowSelectionArenaModal(true);
      await submitCancelBattle(isOffChain);
      await timeout(5000);
      history.replace("arena");
      toastSuccess(`Your arena was canceled!`);
    } catch (error) {
      console.error(error);
      toastError(error);
    }
    setShowSelectionArenaModal(false);
  }, [submitCancelBattle, isOffChain, history]);

  const handleInvitationLink = useCallback(() => {
    const { origin } = window.location;
    navigator.clipboard.writeText(
      `${origin}/arena?arenaId=${joinConfig?.arenaId}&invite=true`,
    );
    toastSuccess("Copied to clipboard!");
  }, [joinConfig]);

  const handleSendMessage = useCallback(
    (message) => {
      socket.current?.emit("message", {
        arenaId: Number(joinConfig?.arenaId),
        message,
      });
    },
    [socket, joinConfig],
  );

  if (account.address && !whitelistAccounts.includes(account.address)) {
    return <NotInWhitelist address={account.address} />;
  }

  return (
    <div className="wrapper">
      <div className="arena-header">
        <div className="d-flex justify-content-between w-100 align-items-center">
          <h1 className="arena-heading">Arena: {joinConfig?.arenaId}</h1>

          {showCancelAndInviteButtons && (
            <div style={{ display: "flex" }}>
              <Button
                size="md"
                onClick={handleCancelBattleClick}
                text="Cancel"
                style={{ marginRight: "0.8rem" }}
              />
              <div
                className="arena-outline-btn align-items-center"
                onClick={handleInvitationLink}
                aria-hidden="true"
              >
                Invitation link <BsShareFill style={{ marginLeft: 8 }} />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="unity-container">
        {!isLoaded && (
          <div className="loading-overlay">
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: `${loadingProgression * 100}%` }}
              />
            </div>
          </div>
        )}
        <Unity className="unity-canvas" unityProvider={unityProvider} />

        <Chat onSend={handleSendMessage} messages={messages} />
      </div>
      <BattleResult
        modalOpen={isBattleResult}
        result={isWinner}
        isFinishingBattle={!isBattleFinished}
        onClose={handleCloseBattleAndRedirect}
      />

      <ArenaSelectionModal
        modalOpen={showCancelBattleModal}
        loadingMessage="Cancelling empty arena... Please wait..."
        onClose={() => {
          setShowSelectionArenaModal(false);
        }}
      />
    </div>
  );
};

export default Game;
