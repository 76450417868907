import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import Messages from "./components/Messages";
import Input from "./components/Input";

import "./styles.scss";
import Header from "./components/Header";
import { ChatEventDto } from "../../pages/Game/dtos/response/chat-event.dto";

interface ChatProps {
  onSend: (text: string) => void;
  messages: ChatEventDto[];
}

const Chat: React.FC<ChatProps> = ({ onSend, messages }) => {
  return (
    <Accordion>
      <AccordionSummary>
        <Header />
      </AccordionSummary>
      <AccordionDetails>
        <div className="chat">
          <Messages messages={messages} />
          <Input onSend={onSend} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Chat;
