import { useState } from "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Button from "../../../../components/Button";
import SelectInput from "../../../../components/SelectInput";
import "../styles.scss";
import { BATTLE_MODES } from "../../../../constants/constants";
import { useContractsParams } from "../../../../contexts/ContractsParamsContext";

const override = css`
  display: block;
  margin: 0 auto;
`;

interface ArenaSelectionModalProps {
  modalOpen: boolean;
  loadingMessage: string;
  onClose(): void;
  onConfirm?: (arenaType: string) => Promise<void>;
}

const ArenaSelectionModal: React.FC<ArenaSelectionModalProps> = ({
  modalOpen,
  loadingMessage,
  onClose,
  onConfirm,
}) => {
  const [arenType, setArenaType] = useState(1);

  const { hostingArenaCost } = useContractsParams();

  return (
    <Modal
      show={modalOpen}
      size="lg"
      backdrop="static"
      centered
      contentClassName="manage-modal-content"
      onHide={onClose}
    >
      <div className="manage-modal-bg" />
      <ModalHeader bsPrefix="manage-modal-header">
        <span className="manage-modal-heading-lg">
          <div>{loadingMessage || "CHOOSE ARENA TYPE"}</div>
        </span>
        {!loadingMessage && (
          <CloseIcon className="manage-modal-close-icon" onClick={onClose} />
        )}
      </ModalHeader>
      <ModalBody bsPrefix="name-change-modal-body">
        {loadingMessage ? (
          <ClipLoader
            color="#ffffff"
            loading={!!loadingMessage}
            css={override}
            size={100}
          />
        ) : (
          <div>
            <div className="name-change-modal-section">
              <SelectInput
                placeholder="element type"
                value={arenType}
                options={BATTLE_MODES.slice(0).map((battleMode) => ({
                  value: `${battleMode.id}`,
                  label: battleMode.mode,
                }))}
                onChange={(ev) => {
                  setArenaType(Number(ev.target.value));
                }}
              />
            </div>
            <div className="name-change-modal-confirm">
              <Button
                text={`Host Arena (${hostingArenaCost} BUIDL)`}
                onClick={() => {
                  if (arenType >= 1) {
                    if (onConfirm) {
                      onConfirm(String(arenType));
                    }
                  }
                }}
              />
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ArenaSelectionModal;
