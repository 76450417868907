import "./styles.css";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { Toaster } from "react-hot-toast";

import ContractsParamsProvider from "../../contexts/ContractsParamsContext";
import AccountProvider from "../../contexts/AccountContext";
import BattleProvider from "../../contexts/BattleContext";
import { defineInterceptor } from "../../api";

const App: React.FC = ({ children }) => {
  const getLibrary = (provider: any) => {
    const library = new Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
  };

  defineInterceptor();

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <AccountProvider>
        <ContractsParamsProvider>
          <BattleProvider>
            <div className="App">{children}</div>
            <Toaster />
          </BattleProvider>
        </ContractsParamsProvider>
      </AccountProvider>
    </Web3ReactProvider>
  );
};

export default App;
