import { Contract } from "ethers";
import elementNftsAbi from "./abis/ElementNfts.json";
import starchiNftsAbi from "./abis/StarchiNfts.json";
import { blockchainAddresses } from "../../config";

import { ElementNfts, StarchiNfts } from "../types/contracts";

export const approvePetsToElementNFTs = async (
  provider: any,
  address: string,
): Promise<boolean | undefined> => {
  try {
    const elementNftsContract = new Contract(
      blockchainAddresses.elementNfts,
      elementNftsAbi,
      provider?.getSigner(),
    ) as ElementNfts;

    const isApprovedForAll = await elementNftsContract.isApprovedForAll(
      address,
      blockchainAddresses.pets,
    );

    if (isApprovedForAll) {
      return true; // Doesn't need approval
    }

    const tx = await elementNftsContract.setApprovalForAll(
      blockchainAddresses.pets,
      true,
    );

    const receipt = await tx.wait();

    console.info("approve receipt", receipt);
  } catch (error: any) {
    console.error(error);
    if (!error.toString().includes("-32601")) {
      throw error;
    }
  }
  return undefined; // Doesn't need approval
};

export const getPetsToElementNFTsApproval = async (
  provider: any,
  address: string,
) => {
  const elementNftsContract = new Contract(
    blockchainAddresses.elementNfts,
    elementNftsAbi,
    provider?.getSigner(),
  ) as ElementNfts;

  const isApprovedForAll = await elementNftsContract.isApprovedForAll(
    address,
    blockchainAddresses.pets,
  );

  return isApprovedForAll;
};

export const approveElementMarket = async (
  tokenId: number,
  provider: any,
): Promise<boolean | undefined> => {
  try {
    const elementNftsContract = new Contract(
      blockchainAddresses.elementNfts,
      elementNftsAbi,
      provider?.getSigner(),
    ) as ElementNfts;

    // const isApproved = await elementNftsContract.getApproved(
    //   blockchainAddresses.elementMarket,
    // );

    // if (isApproved) {
    //   return true; // Doesn't need approval
    // }

    const tx = await elementNftsContract.approve(
      blockchainAddresses.elementMarket,
      tokenId,
    );

    const receipt = await tx.wait();

    console.info("approve receipt", receipt);
  } catch (error: any) {
    console.error(error);
    if (!error.toString().includes("-32601")) {
      throw error;
    }
  }
  return undefined; // Doesn't need approval
};

export const approveStarchiMarket = async (
  tokenId: number,
  provider: any,
): Promise<boolean | undefined> => {
  try {
    const starchiNftsContract = new Contract(
      blockchainAddresses.starchiNfts,
      starchiNftsAbi,
      provider?.getSigner(),
    ) as StarchiNfts;

    // todo get approved
    // const isApproved = await starchiNftsContract.getApproved(
    //   blockchainAddresses.starchiMarket,
    // );

    // if (isApproved) {
    //   return true; // Doesn't need approval
    // }

    const tx = await starchiNftsContract.approve(
      blockchainAddresses.starchiMarket,
      tokenId,
    );

    const receipt = await tx.wait();

    console.info("approve receipt", receipt);
  } catch (error: any) {
    console.error(error);
    if (!error.toString().includes("-32601")) {
      throw error;
    }
  }
  return undefined; // Doesn't need approval
};
