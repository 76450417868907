import { useCallback, useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import Tooltip from "@material-ui/core/Tooltip";

import { GiClover } from "react-icons/gi";
import { FaWind } from "react-icons/fa";
import { ImHeart } from "react-icons/im";
import { BsLightningChargeFill } from "react-icons/bs";

import Button from "../../../../components/Button";
import StatValue from "../../../../components/StatValue";

import "../styles.scss";
import { useContractsParams } from "../../../../contexts/ContractsParamsContext";
import {
  PetsInitialElementTypeProps,
  useBattle,
} from "../../../../contexts/BattleContext";
import SelectInitialElementDropdown from "../SelectInitialElementDropdown";
import NameBadge from "../../../../components/StarchiNameBadge";
import { PetWithDetailsProps } from "../../../../api/pets";
import { findInBattleAttributes } from "../../../../api/restapi";

const override = css`
  display: block;
  margin: auto;
`;

interface SelectPetsForBattleModalProps {
  modalOpen: boolean;
  myPets: PetWithDetailsProps[];
  mode: number;
  onClose(): void;
  onJoin(
    pets: number[],
    updateSignature: boolean,
    petsInitialElementType: PetsInitialElementTypeProps[],
  ): Promise<void>;
}

interface AvailablePetsToBattleProps extends PetWithDetailsProps {
  canBattle: boolean;
  cannotBattleMessage: string | undefined;
  energy?: number;
  hp?: number;
  speed?: number;
  luckiness?: number;
}

const SelectPetsForBattleModal: React.FC<SelectPetsForBattleModalProps> = ({
  modalOpen,
  myPets,
  mode,
  onClose,
  onJoin,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [availablePets, setAvailablePets] = useState<
    AvailablePetsToBattleProps[]
  >([]);
  const [selectedPets, setSelectedPets] = useState<number[]>([]);
  const [selectedInitialElements, setSelectedInitialElements] = useState<
    PetsInitialElementTypeProps[]
  >([]);
  const {
    percentageDenominator,
    minEnergyPercentage,
    minHungerPercentage,
    minHygienePercentage,
    minExercisePercentage,
  } = useContractsParams();
  const { isOffChain } = useBattle();

  useEffect(() => {
    const clearSelectionTimeout = setTimeout(() => {
      setSelectedPets([]);
    }, 200);
    return () => {
      clearTimeout(clearSelectionTimeout);
    };
  }, [modalOpen]);

  useEffect(() => {
    const loadAvailablePets = async () => {
      if (
        percentageDenominator !== undefined &&
        minEnergyPercentage !== undefined &&
        minHungerPercentage !== undefined &&
        minHygienePercentage !== undefined &&
        minExercisePercentage !== undefined
      ) {
        let availablePetsToBattle: AvailablePetsToBattleProps[] = myPets.map(
          (pet) => {
            const validationOne =
              pet.elements.length > 0 &&
              pet.stage !== "Egg" &&
              pet.stage !== "Child";
            let validationTwo = true;
            const validationThree = !pet.sleeping;

            if (!isOffChain && pet.attributes) {
              const petEnergyRequirement =
                (pet.nurturingStats.energy.points * percentageDenominator) /
                pet.attributes.energyPoints;
              const petHungerRequirement =
                (pet.nurturingStats.hunger.points * percentageDenominator) /
                pet.attributes.hungerPoints;
              const petHygieneRequirement =
                (pet.nurturingStats.hygiene.points * percentageDenominator) /
                pet.attributes.hygienePoints;
              const petExerciseRequirement =
                (pet.nurturingStats.exercise.points * percentageDenominator) /
                pet.attributes.exercisePoints;

              validationTwo =
                petEnergyRequirement > minEnergyPercentage &&
                petHungerRequirement > minHungerPercentage &&
                petHygieneRequirement > minHygienePercentage &&
                petExerciseRequirement > minExercisePercentage;
            }

            const canBattle = validationOne && validationTwo && validationThree;

            let cannotBattleMessage;

            if (!canBattle) {
              cannotBattleMessage = !validationOne
                ? `${pet.stage} starchies cannot battle`
                : "This starchi is penalized, so it cannot battle";
            }

            return { ...pet, canBattle, cannotBattleMessage };
          },
        );

        const availablePetIds = availablePetsToBattle.map((pet) => pet.petId);

        const battleAttributesReponse = availablePetIds.length
          ? await findInBattleAttributes(availablePetIds.join(","))
          : [];

        availablePetsToBattle = availablePetsToBattle.map((availablePet) => {
          const petInBattleAttributes = battleAttributesReponse.find(
            (pet) => pet.petId === availablePet.petId,
          );

          return { ...availablePet, ...petInBattleAttributes };
        });

        setAvailablePets(availablePetsToBattle);
        setIsLoading(false);
      }
    };

    loadAvailablePets();
  }, [
    myPets,
    isOffChain,
    percentageDenominator,
    minEnergyPercentage,
    minHungerPercentage,
    minHygienePercentage,
    minExercisePercentage,
  ]);

  const addPetsToBattle = useCallback(
    (petId: number) => {
      if (selectedPets.length > mode) {
        return;
      }
      if (selectedPets.indexOf(petId) === -1) {
        setSelectedPets([...selectedPets, petId]);
      }
    },
    [selectedPets, mode],
  );

  const removePetsFromBattle = useCallback(
    (petId) => {
      const _tempPets = selectedPets;
      const _idx = _tempPets.indexOf(petId);
      if (_idx > -1) {
        _tempPets.splice(_idx, 1);
      }
      setSelectedPets([..._tempPets]);
    },
    [selectedPets],
  );

  const addInitialElementToPet = useCallback(
    (petId: number, elementTypeId: number) => {
      setSelectedInitialElements((prevState) => {
        const updatedInitialElements = [...prevState];

        const foundPet = updatedInitialElements.find(
          (pet) => pet.petId === petId,
        );

        if (foundPet) {
          foundPet.elementTypeId = elementTypeId;

          return updatedInitialElements;
        }

        return [...updatedInitialElements, { petId, elementTypeId }];
      });
    },
    [],
  );

  const removeInitialElementToPet = useCallback((petId: number) => {
    setSelectedInitialElements((prevState) =>
      prevState.filter((initialElement) => initialElement.petId !== petId),
    );
  }, []);

  return (
    <Modal
      show={modalOpen}
      size="lg"
      backdrop="static"
      centered
      contentClassName="quick-battle-modal-content"
      onHide={onClose}
    >
      <div className="quick-battle-modal-bg" />
      <ModalHeader bsPrefix="quick-battle-modal-header">
        <span className="quick-battle-modal-heading-left">
          CHOOSE YOUR STARCHI
        </span>
        <div className="quick-battle-modal-header-container">
          <span className="quick-battle-modal-description">
            Only awake{" "}
            <span className="quick-battle-modal-description-bold">
              teenagers or adults
            </span>{" "}
            Starchis, that have at least{" "}
            <span className="quick-battle-modal-description-bold">
              two elements attached
            </span>{" "}
            with at least{" "}
            <span className="quick-battle-modal-description-bold">
              30% of it's attributes{" "}
            </span>
            to join a battle.
          </span>

          {selectedPets.length === parseInt(String(mode)) && (
            <Button
              size="md"
              text="Join"
              onClick={() => {
                console.log({ selectedPets, selectedInitialElements });
                console.log("onJoin", onJoin);

                onJoin(selectedPets, true, selectedInitialElements);
              }}
            />
          )}
        </div>

        <CloseIcon
          className="quick-battle-modal-close-icon"
          onClick={onClose} // TODO: Either cancel the guest on this battle or update the player's arenas list
        />
      </ModalHeader>
      <ModalBody bsPrefix="quick-battle-modal-body">
        {isLoading ? (
          <ClipLoader
            color="#ffffff"
            loading={isLoading}
            css={override}
            size={100}
          />
        ) : availablePets.length === 0 ? (
          <span className="quick-battle-no-element-alert">
            No Starchis available
          </span>
        ) : (
          availablePets.map((pet) => (
            <div key={pet.petId} className="quick-battle-starchi-item">
              <div className="quick-battle-starchi-item-bg" />
              <div className="quick-battle-starchi-item-inner">
                <div className="d-flex w-100">
                  <NameBadge
                    className="w-50 quick-battle-starchi-badge"
                    petBreedId={String(pet.breedId)}
                    name={pet.name}
                    id={String(pet.petId)}
                  />
                  <div className="quick-battle-stage w-50">
                    <span className="quick-battle-stage-label">Stage: </span>
                    <span className="quick-battle-stage-value">
                      {pet.stage}
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-center w-100">
                  <div className="quick-battle-starchi-left">
                    <div className="quick-battle-image-container">
                      {pet.canBattle ? (
                        <img
                          src={pet.image || undefined}
                          alt="Starchi"
                          className="crate-card-image"
                        />
                      ) : (
                        <Tooltip
                          title={
                            <p
                              style={{
                                fontFamily: "Josefin Sans",
                                fontSize: "1.1rem",
                                margin: "0.3rem 0",
                              }}
                            >
                              {pet.cannotBattleMessage}
                            </p>
                          }
                          placement="bottom"
                          arrow
                        >
                          <img
                            src={pet.image || undefined}
                            alt="Starchi"
                            className={`crate-card-image ${
                              !pet.canBattle && "gray-scale"
                            }`}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div className="quick-battle-starchi-right">
                    <div className="quick-battle-top-info">
                      <div className="quick-battle-stats-container">
                        <StatValue
                          IconComponent={<ImHeart />}
                          showIconIndicators={
                            pet.stage !== "Egg" && pet.stage !== "Child"
                          }
                          backgroundColor="#e12939"
                          title="HP"
                          currentValue={pet.nurturingStats.hunger.points || 0}
                          value={pet.attributes?.hungerPoints || 0}
                          inBattleAttribute={pet.hp}
                        />
                        <StatValue
                          IconComponent={<BsLightningChargeFill />}
                          showIconIndicators={
                            pet.stage !== "Egg" && pet.stage !== "Child"
                          }
                          backgroundColor="#7b21fc"
                          title="Energy"
                          currentValue={pet.nurturingStats.energy.points || 0}
                          value={pet.attributes?.energyPoints || 0}
                          inBattleAttribute={pet.energy}
                        />
                        <StatValue
                          IconComponent={<GiClover />}
                          showIconIndicators={
                            pet.stage !== "Egg" && pet.stage !== "Child"
                          }
                          backgroundColor="#16b200"
                          title="Luckiness"
                          currentValue={pet.nurturingStats.hygiene.points || 0}
                          value={pet.attributes?.hygienePoints || 0}
                          inBattleAttribute={pet.luckiness}
                        />
                        <StatValue
                          IconComponent={<FaWind />}
                          showIconIndicators={
                            pet.stage !== "Egg" && pet.stage !== "Child"
                          }
                          backgroundColor="#cec505"
                          title="Speed"
                          currentValue={pet.nurturingStats.exercise.points || 0}
                          value={pet.attributes?.exercisePoints || 0}
                          inBattleAttribute={pet.speed}
                        />
                      </div>
                    </div>
                    {pet.elements.length > 0 && (
                      <div className="quick-battle-button">
                        {selectedPets.length < parseInt(String(mode)) &&
                          pet.canBattle &&
                          selectedPets.indexOf(parseInt(String(pet.petId))) ===
                            -1 && (
                            <Button
                              size="lg"
                              text="CHOOSE STARCHI"
                              onClick={() => {
                                addPetsToBattle(parseInt(String(pet.petId)));
                              }}
                            />
                          )}
                        {selectedPets.indexOf(parseInt(String(pet.petId))) !==
                          -1 && (
                          <>
                            <SelectInitialElementDropdown
                              petElements={pet.elements}
                              onChange={(elementTypeId) => {
                                addInitialElementToPet(
                                  Number(pet.petId),
                                  elementTypeId,
                                );
                              }}
                            />
                            <Button
                              size="lg"
                              text="SELECTED"
                              onClick={() => {
                                removePetsFromBattle(
                                  parseInt(String(pet.petId)),
                                );
                                removeInitialElementToPet(Number(pet.petId));
                              }}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </ModalBody>
    </Modal>
  );
};

export default SelectPetsForBattleModal;
