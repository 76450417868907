import { api } from ".";
import { socketServerUrl } from "../config";
import { ArenaDetailsProps } from "./battle";
import { ContractTypes, FindAllDto } from "./interfaces/IFindAll";

interface GetArenaStatusDto {
  signature: string;
  address: string;
  arenaId: number;
}

export const _joinedPetsToArenaRoom = async (
  payload: GetArenaStatusDto,
): Promise<number[] | undefined> => {
  try {
    const response = await api.post(
      `${socketServerUrl}/battle/offchain/joinedPets`,
      payload,
    );
    return response.data;
  } catch (err) {
    console.error("joinedPetsToArenaRoom", err);
  }
  return undefined;
};

export const listPendingOffChainArenas = async (): Promise<
  ArenaDetailsProps[]
> => {
  try {
    const response = await api.get(`${socketServerUrl}/battle/offchain`);

    if (response.data) {
      return response.data;
    }
  } catch (err) {
    console.error("listPendingOffChainArenas", err);
  }
  return [];
};

export const getMyOffChainArena = async (
  address: string,
): Promise<ArenaDetailsProps | undefined> => {
  try {
    const response = await api.post(
      `${socketServerUrl}/battle/offchain/myarena`,
      { address },
    );
    return response.data;
  } catch (err) {
    console.error("OffChainArena", err);
  }
  return undefined;
};

export const cancelOffChainBattle = async (address: string): Promise<void> => {
  try {
    await api.delete(
      `${socketServerUrl}/battle/offchain/cancelarena/${address}`,
    );
  } catch (err) {
    console.error("cancelOffChainBattle", err);
  }
};

export const findAll = async (payload: FindAllDto): Promise<any> => {
  try {
    const response = await api.post(
      `${socketServerUrl}/market/findAll`,
      payload,
    );
    return response.data;
  } catch (err) {
    console.error("findAll", err);
  }
  return undefined;
};

export const findListingItem = async (
  tokenId: string | number,
): Promise<any> => {
  try {
    const response = await api.get(`${socketServerUrl}/market/${tokenId}`);
    return response.data;
  } catch (err) {
    console.error("findListingItem", err);
  }
  return undefined;
};

export const createListing = async (payload: any): Promise<any> => {
  const response = await api.post(
    `${socketServerUrl}/market/createListing`,
    payload,
  );

  return response.data;
};

export const executeSell = async (payload: any): Promise<void> => {
  await api.post(`${socketServerUrl}/market/executeSell`, payload);
};

export const cancelListing = async (payload: any): Promise<void> => {
  await api.post(`${socketServerUrl}/market/cancelListing`, payload);
};

export const findListedToken = async (payload: any): Promise<any> => {
  const response = await api.post(
    `${socketServerUrl}/market/findToken`,
    payload,
  );
  return response.data;
};

export const findUserTokens = async (
  address: string,
  contractType: ContractTypes,
): Promise<any[]> => {
  const response = await api.get(
    `${socketServerUrl}/market/findUserTokens/${address}/${contractType}`,
  );
  return response.data;
};

interface BattleAttributesReponse {
  petId: number;
  energy?: number;
  hp?: number;
  speed?: number;
  luckiness?: number;
}

export const findInBattleAttributes = async (
  pets: string,
): Promise<BattleAttributesReponse[]> => {
  const response = await api.get<BattleAttributesReponse[]>(
    `${socketServerUrl}/battle/battle-attributes/${pets}`,
  );

  return response.data;
};
