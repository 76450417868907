import { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

import IconButton from "../IconButton";
import "./styles.scss";
import { SortProps } from "../../pages/Store/contexts/StoreContext";

interface FilterMenuProps {
  items: any[];
  value: number;
  sort?: SortProps;
  onChange(sort: SortProps): void;
  showSortingIcons: boolean;
  toogleSortingDirection(): void;
}

const FilterMenu: React.FC<FilterMenuProps> = ({
  items,
  value,
  sort,
  onChange,
  showSortingIcons = false,
  toogleSortingDirection,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="filter-button"
      >
        {items[value].name}
        <ArrowDropDownIcon style={{ color: "white" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items.map(
          (item) =>
            item.value !== 0 && (
              <MenuItem
                key={item.value}
                onClick={() => {
                  if (sort) {
                    onChange({ ...sort, sortBy: item.value });
                  }
                  handleClose();
                }}
              >
                {item.name}
              </MenuItem>
            ),
        )}
      </Menu>

      {showSortingIcons ? (
        sort?.sortingDirection ? (
          <IconButton
            iconComponent={<FaArrowUp size={15} color="#fff" />}
            onClick={toogleSortingDirection}
            containerStyles={{
              backgroundColor: "#7e5a9b",
              marginLeft: "0.5rem",
            }}
          />
        ) : (
          <IconButton
            iconComponent={<FaArrowDown size={15} color="#fff" />}
            onClick={toogleSortingDirection}
            containerStyles={{
              backgroundColor: "#b7312f",
              marginLeft: "0.5rem",
            }}
          />
        )
      ) : (
        <div />
      )}
    </>
  );
};

export default FilterMenu;
