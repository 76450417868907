import { useEffect, useState } from "react";
import "./styles.scss";
import { NavLink } from "react-router-dom";
import StarchiLogo from "../../img/starchi_smaller_I.png";
import PLayNow from "../../img/Play Now Flag.svg";

const Navbar: React.FC = () => {
  const [isModalWidth, setIsModalWidth] = useState(window.innerWidth <= 1200);
  const [showModalMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsModalWidth(window.innerWidth <= 1200);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toogleMobileMenu = () => setShowMobileMenu(!showModalMenu);

  return (
    <div className="navbar-container">
      <button
        type="button"
        className={showModalMenu ? "main-container" : ""}
        onClick={() => setShowMobileMenu(false)}
      />
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <img alt="" className="Logo" src={StarchiLogo} />
          </NavLink>

          <ul className={showModalMenu ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/crate"
                activeClassName="active"
                className="nav-links"
                onClick={showModalMenu ? toogleMobileMenu : undefined}
              >
                CRATE
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/store"
                activeClassName="active"
                className="nav-links"
                onClick={showModalMenu ? toogleMobileMenu : undefined}
              >
                STORE
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/staking"
                activeClassName="active"
                className="nav-links"
                onClick={showModalMenu ? toogleMobileMenu : undefined}
              >
                STAKING
              </NavLink>
              {/* <Link
                activeClassName="active"
                className="nav-links"
                onClick={() => {
                  window.open("https://starchi.gg/Story.pdf", "_self");
                }}
              >
                STORY
              </Link> */}
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/rank"
                activeClassName="active"
                className="nav-links"
                onClick={showModalMenu ? toogleMobileMenu : undefined}
              >
                RANKING
              </NavLink>
            </li>
            <li className="nav-item no-hover">
              <NavLink
                exact
                to="/arena"
                activeClassName="active"
                className="nav-links"
                onClick={showModalMenu ? toogleMobileMenu : undefined}
              >
                {isModalWidth ? (
                  "PLAY NOW"
                ) : (
                  <img alt="" className="PlayButton" src={PLayNow} />
                )}
              </NavLink>
            </li>
          </ul>

          <button type="button" className="nav-icon" onClick={toogleMobileMenu}>
            <i className={showModalMenu ? "fa fa-times" : "fa fa-bars"} />
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
