import { arenas, arenasByPlayer, getPendingArenas } from "./chain/battle";

export interface ArenaDetailsProps {
  mode: number;
  host: string;
  guest: string;
  startedAt: string | number;
  id: string | number;
}

export const getArenaDetails = async (
  provider: any,
  id: string,
): Promise<ArenaDetailsProps> => {
  const arenaData = await arenas(provider, id);
  return { ...arenaData, startedAt: arenaData.startedAt.toNumber(), id };
};

export const listPendingArenasWithDetails = async (provider: any) => {
  let ids = await getPendingArenas(provider);

  ids = ids.filter((id) => id.toString() !== "0");

  const arenasWithDetails = await Promise.all(
    ids.map((id) => getArenaDetails(provider, id.toString())),
  );
  return arenasWithDetails;
};

export const getMyArena = async (provider: any, account: string) => {
  const id = await arenasByPlayer(provider, account);
  if (id.toString() === "0") {
    return undefined;
  }
  return getArenaDetails(provider, id.toString());
};
