import { Switch, Route } from "react-router";

import "./styles.scss";

import ArenaList from "./components/ArenaList";
// import ArenaDetail from "./components/ArenaDetail";

const Arena: React.FC = () => {
  return (
    <div className="arena-container">
      <Switch>
        {/* <Route path="/arena/:address" component={ArenaDetail} /> */}
        <Route path="/arena" component={ArenaList} />
      </Switch>
    </div>
  );
};

export default Arena;
