import { Contract } from "ethers";
import petsAbi from "./abis/Pets.json";
import { blockchainAddresses } from "../../config";
import promiseWithTimeout from "../../utils/promiseWithTimeout";
import parseReceiptEvents from "../../utils/parseReceiptEvents";
import { Pets } from "../types/contracts";

const TOLERANCE_BLOCKS_TO_LISTEN_PET_EVOLVED_EVENT = 20;

export const getPet = async (provider: any, petId: number) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;
  return petsContract.getPet(petId);
};

export const getPets = async (provider: any, account: string) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  return petsContract.getPets(account);
};

export const getMintEggPrice = async (provider: any) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;
  const mintEggPrice = await petsContract.mintEggPrice();
  return mintEggPrice;
};

export const getMintElementPrice = async (provider: any) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;
  const mintElementPrice = await petsContract.mintElementPrice();
  return mintElementPrice;
};

export const getDetachElementPrice = async (provider: any) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;
  const detachElementPrice = await petsContract.detachElementPrice();
  return detachElementPrice;
};

export const getChangeNamePrice = async (provider: any) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;
  const changeNamePrice = await petsContract.changeNamePrice();
  return changeNamePrice;
};

export const getElements = async (provider: any, account: string) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  return petsContract.getElements(account);
};

export const getAllowedElementsByBreedId = async (
  provider: any,
  breedId: string,
) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  return petsContract.getAllowedElementsByBreedId(breedId);
};

export const getElement = async (provider: any, elementId: number) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  return petsContract.elements(elementId);
};

export const getRequestedPetEvolution = async (
  provider: any,
  petId: number,
) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  return petsContract.requestedPetEvolution(petId);
};

export const getPetElements = async (provider: any, petId: number) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  return petsContract.getPetElements(petId);
};

export const changePetName = async (
  petId: number,
  newName: string,
  provider: any,
) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  const tx = await petsContract.changePetName(petId, newName);
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  return receipt;
};

export const requestMintEgg = async (_petName: string, provider: any) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  const tx = await petsContract.requestMintEgg(_petName);
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  const {
    MintEggRequested: { requestId },
  } = parseReceiptEvents(petsContract.interface, receipt);

  console.info(`requestMintEgg Request ID: ${requestId}`);
};

export const mintEggFinished = async (
  provider: any,
  account: string,
  petName: string,
) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  const startBlockNumber = await provider.getBlockNumber();

  let transactionHash;

  const eventWatcher = new Promise((resolve) => {
    petsContract.on("MintPetNFT", (owner, _id, pet, _event) => {
      if (_event.blockNumber <= startBlockNumber) return;

      transactionHash = _event.transactionHash;

      if (owner.toLowerCase() === account && pet.name === petName) {
        console.log(`Minted pet ${petName}`);

        resolve(pet);
      }
    });
  });
  await promiseWithTimeout(
    150000,
    eventWatcher,
    "Mint Egg timeout, please go to CRATE to check if your Starchi is already there!",
  );

  petsContract.off("MintPetNFT", (owner, _id, pet, _event) => {
    if (_event.blockNumber <= startBlockNumber) return;

    transactionHash = _event.transactionHash;

    if (owner.toLowerCase() === account && pet.name === petName) {
      console.log(`Minted pet ${petName}`);
    }
  });

  const transaction = await provider.getTransaction(transactionHash);
  await transaction.wait();
};

export const requestEvolution = async (petId: number, provider: any) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider.getSigner(),
  ) as Pets;

  const tx = await petsContract.requestEvolution(petId);
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  const {
    PetEvolutionRequested: { requestId },
  } = parseReceiptEvents(petsContract.interface, receipt);

  console.info(`requestEvolution Request ID: ${requestId}`);
};

export const evolvePetFinished = async (provider: any, petId: number) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  const startBlockNumber =
    (await provider.getBlockNumber()) -
    TOLERANCE_BLOCKS_TO_LISTEN_PET_EVOLVED_EVENT;

  let transactionHash;

  const eventWatcher = new Promise((resolve) => {
    petsContract.on("PetEvolved", (_id, pet, _event) => {
      if (_event.blockNumber <= startBlockNumber) return;

      transactionHash = _event.transactionHash;

      if (_id.toNumber() === petId) {
        console.log(`Evolved ${petId}`);

        resolve(pet);
      }
    });
  });

  await promiseWithTimeout(
    100000,
    eventWatcher,
    "Evolve timeout, please refresh the page to check if your Starchi was evolved",
  );
  petsContract.off("PetEvolved", (_id, pet, _event) => {
    if (_event.blockNumber <= startBlockNumber) return;

    transactionHash = _event.transactionHash;

    if (_id.toNumber() === petId) {
      console.log(`Evolved ${petId}`);
    }
  });

  const transaction = await provider.getTransaction(transactionHash);
  await transaction.wait();
};

export const requestMintElement = async (
  elementTypeId: string,
  provider: any,
) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  const tx = await petsContract.requestMintElement(elementTypeId);
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  const {
    MintElementRequested: { requestId },
  } = parseReceiptEvents(petsContract.interface, receipt);

  console.info(`requestMintElement Request ID: ${requestId}`);
};

export const mintElementFinished = async (
  provider: any,
  account: string,
  elementType: string,
) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  const startBlockNumber = await provider.getBlockNumber();

  let transactionHash;

  const eventWatcher = new Promise((resolve) => {
    petsContract.on("MintElementNFT", (owner, _id, element, _event) => {
      if (_event.blockNumber <= startBlockNumber) return;

      transactionHash = _event.transactionHash;

      if (owner.toLowerCase() === account && element.typeId === elementType) {
        console.log(`Minted element ${elementType}`);

        resolve(element);
      }
    });
  });

  await promiseWithTimeout(
    150000,
    eventWatcher,
    "Mint Element timeout, please go to CRATE to check if your element is already there!",
  );
  petsContract.off("MintElementNFT", (owner, _id, element, _event) => {
    if (_event.blockNumber <= startBlockNumber) return;

    transactionHash = _event.transactionHash;

    if (owner.toLowerCase() === account && element.typeId === elementType) {
      console.log(`Minted element ${elementType}`);
    }
  });

  const transaction = await provider.getTransaction(transactionHash);
  await transaction.wait();
};

export const attachElement = async (
  petId: number,
  elementId: number,
  provider: any,
) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  const tx = await petsContract.attachElement(petId, elementId);
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  return receipt;
};

export const detachElement = async (
  petId: number,
  elementId: number,
  provider: any,
) => {
  const petsContract = new Contract(
    blockchainAddresses.pets,
    petsAbi,
    provider?.getSigner(),
  ) as Pets;

  const tx = await petsContract.detachElement(petId, elementId);
  const receipt = await tx.wait();

  console.info("receipt >>>", receipt);

  return receipt;
};
