import ProgressBar from "react-bootstrap/ProgressBar";

import Tooltip from "@material-ui/core/Tooltip";

import "./styles.scss";

interface TamagochiStatsProps {
  title: string;
  IconComponent: JSX.Element;
  open: boolean;
  backgroundColor: string;
  currentValue: number;
  maxValue: number;
}

const TamagochiStats: React.FC<TamagochiStatsProps> = ({
  title,
  IconComponent,
  open,
  backgroundColor,
  currentValue,
  maxValue,
}) => {
  return (
    <div
      className="tamagochi-stats-container"
      style={{ display: open ? "flex" : "none" }}
    >
      <div className="stat-value-icon" style={{ backgroundColor }}>
        {IconComponent}
      </div>

      <div className="stat-value-setting">
        <span className="stat-value-title">{title}</span>
        <Tooltip
          title={
            <p
              style={{
                fontFamily: "Josefin Sans",
                fontSize: "0.9rem",
                margin: "0.2rem 0",
              }}
            >
              {Math.round((currentValue / maxValue) * 100)}% ({currentValue} /{" "}
              {maxValue})
            </p>
          }
          placement="bottom"
          arrow
        >
          <ProgressBar
            variant="success"
            now={(currentValue / maxValue) * 100}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default TamagochiStats;
